import { performRequest, performRequestThird } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';

export const getCaseDetails = (obj) => {
  let { tenantId, tenantSk, data, status, tag, practice, caseMatter, searchValue, limit, pagination_token, pagination_direction, next } =
    obj;
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.getCase}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_status=${status}${tag ? `&tags=${tag}` : ''}${
      practice ? `&case_practice_area=${practice}` : ''
    }${caseMatter ? `&case_matter=${caseMatter}` : ''}${
      searchValue ? `&keyword=${searchValue}` : ''
    }&next=${next}&limit=${limit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}
    `,
    data
  );
};

export const getCaseList = (obj) => {
  let {
    data = {},
    status = '',
    tag = '',
    practice = '',
    caseMatter = '',
    searchValue = '',
    limit = 10,
    pagination_token = '',
    pagination_direction = 'forward',
    next,
    excludeFromLoading = false,
  } = obj;

  const [tenantId, tenantSk] = getTenantDetails();

  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.getCase}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_status=${status}${tag ? `&tags=${tag}` : ''}${
      practice ? `&case_practice_area=${practice}` : ''
    }${caseMatter ? `&case_matter=${caseMatter}` : ''}${
      searchValue ? `&keyword=${searchValue}` : ''
    }&next=${next}&limit=${limit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}
    `,
    data,
    '',
    true,
    excludeFromLoading
  );
};

export const listCasesForUser = (obj) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let { target_user_type, target_user_id, target_user_name, is_lead = true, is_events = false } = obj;
  let url = `${apiEndPoints.getUserCases}?tenant_sk=${tenantSk}&tenant_id=${tenantId}&target_user_type=${target_user_type}&target_user_id=${target_user_id}&target_user_name=${target_user_name}&is_lead=${is_lead}&is_events=${is_events}`;

  return performRequest(apiEndPoints.methodType.GET, url);
};

export const getClientUserList = ({
  caseId,
  is_contact_list = false,
  is_team_member_list = false,
  all_contacts = false,
  contact_id = false,
  is_lead = false,
  is_mandatory_lawft_access = true,
  is_active = false,
  is_include_groups = false,
  active_groups = false,
}) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.calendar.userList}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&is_mandatory_lawft_access=${is_mandatory_lawft_access}`;
  if (caseId) url = url + '&case_id=' + caseId;
  if (is_contact_list) url = url + '&is_contact_list=' + is_contact_list;
  if (is_team_member_list) url = url + '&is_team_member_list=' + is_team_member_list;
  if (all_contacts) url = url + '&all_contacts=' + all_contacts;
  if (contact_id) url = url + '&contact_id=' + contact_id;
  if (is_lead) url = url + '&is_lead=' + is_lead;
  if (is_include_groups) url = url + '&is_include_groups=' + is_include_groups;
  if (active_groups) url = url + '&active_groups=' + active_groups;
  if (is_active) url = url + '&is_active=' + is_active;
  return performRequest(apiEndPoints.methodType.GET, url);
};

export const updateTaskStage = ({ pk, sk, data, is_lead = false }) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let skNew = encodeURIComponent(sk);
  let pkNew = encodeURIComponent(pk);
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.updateTaskStage}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pkNew}&sk=${skNew}${
      is_lead ? `&is_lead=${is_lead}` : ''
    }`,
    data
  );
};

export const deleteTaskStage = ({ pk, sk, is_lead = false }) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let skNew = encodeURIComponent(sk);
  let pkNew = encodeURIComponent(pk);
  return performRequest(
    apiEndPoints.methodType.DELETE,
    `${apiEndPoints.deleteTaskStage}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pkNew}&sk=${skNew}${
      is_lead ? `&is_lead=${is_lead}` : ''
    }`
  );
};

export const getPracticeAreas = ({ pageLimit, pagination_token = '', pagination_direction = 'forward', status }) => {
  const [tenantId, tenantSk] = getTenantDetails();

  return performRequest(
    apiEndPoints.methodType.GET,
    `${
      apiEndPoints.practiceArea
    }?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}${
      status ? `&status=${status}` : ''
    }`
  );
};

export const getCaseTag = (isLead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.getCaseTag}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&is_lead=${isLead}`
  );
};

export const getCaseStage = ({ practice_area = false, pageLimit = 10, pagination_token = '', pagination_direction = 'forward' }) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.GET,
    `${
      apiEndPoints.getCaseStage
    }?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}${
      practice_area ? `&practice_area=${practice_area}` : ''
    }`
  );
};
export const viewCaseDetails = ({ pk, sk, timeEntryData = {}, case_id = '' }) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.getCaseDetails}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pk ? pk : ''}&sk=${sk ? sk : ''}
    ${case_id ? `&case_id=${case_id}` : ''}`,
    {},
    timeEntryData
  );
};

export const updateCase = ({ data, is_lead = false, timeEntryData = '' }) => {
  const [tenantId, tenantSk] = getTenantDetails();

  return performRequest(
    apiEndPoints.methodType.PUT,
    `${apiEndPoints.updateCase}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`,
    data,
    timeEntryData
  );
};

export const getCompanionCases = ({ case_id }) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.getCompanionCase}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${case_id ? `&case_id=${case_id}` : ''}`
  );
};

export const createCompanionCases = ({ companion_pk, group_id }, data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createCompanionCase}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${group_id ? `&group_id=${group_id}` : ''}${
      companion_pk ? `&companion_pk=${companion_pk}` : ''
    }`,
    data
  );
};

export const updateCompanionCases = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();

  return performRequestThird(
    apiEndPoints.methodType.PUT,
    `${apiEndPoints.updateCompanionCase}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};
