import { useState, useCallback, useRef } from 'react';
import {
  S3Client,
  CreateMultipartUploadCommand,
  UploadPartCommand,
  CompleteMultipartUploadCommand,
  AbortMultipartUploadCommand,
} from '@aws-sdk/client-s3';
import { Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { confirmDialog } from 'primereact/confirmdialog';

import { getTenantDetails } from 'utils/utils';
import { useToast } from 'context/ToastContext';
import { FILEUPLOAD_S3 } from 'config/amplify_config';
import { validateUpload } from 'services/fileManager/fileManagerServices';
import { toastConstant } from 'constants/toastmessage';

const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB
const BASE_CONCURRENT_UPLOADS = 5;
const MAX_RETRIES = 3;

const useMultipartUpload = () => {
  const [uploadingFiles, setUploadingFiles] = useState({});
  const [overallProgress, setOverallProgress] = useState(0);
  const uploadManagerRef = useRef({});
  const { addToast } = useToast();
  const [concurrentUploads, setConcurrentUploads] = useState(BASE_CONCURRENT_UPLOADS);
  const speedMeasurements = useRef([]);

  const adjustConcurrency = () => {
    const averageSpeedMbps = speedMeasurements.current.reduce((a, b) => a + b, 0) / speedMeasurements.current.length;
    let newConcurrentUploads = BASE_CONCURRENT_UPLOADS;
    if (averageSpeedMbps > 5) newConcurrentUploads = 7;
    if (averageSpeedMbps > 10) newConcurrentUploads = 10;
    if (averageSpeedMbps > 25) newConcurrentUploads = 20;

    setConcurrentUploads(newConcurrentUploads);
  };

  const initiateMultipartUpload = async (s3Client, Bucket, Key, file) => {
    const ContentType = file.type;
    const command = new CreateMultipartUploadCommand({ Bucket, Key, ContentType });
    const response = await s3Client.send(command);
    return response.UploadId;
  };

  const uploadChunk = async (s3Client, Bucket, Key, UploadId, PartNumber, chunk) => {
    let attempts = 0;
    let success = false;
    let eTag;

    while (!success && attempts < MAX_RETRIES) {
      try {
        const start = Date.now();
        const command = new UploadPartCommand({
          Bucket,
          Key,
          UploadId,
          PartNumber,
          Body: chunk,
        });
        const response = await s3Client.send(command);
        const duration = (Date.now() - start) / 1000; // seconds
        const speedMbps = (chunk.size * 8) / (duration * 1000000); // Megabits per second

        // Only measure speed for every 5th chunk to reduce overhead
        if (PartNumber % 5 === 0) {
          speedMeasurements.current.push(speedMbps);
          if (speedMeasurements.current.length > 5) {
            speedMeasurements.current.shift();
          }
          adjustConcurrency();
        }

        eTag = response.ETag;
        success = true; // Mark success if no exception was thrown
      } catch (error) {
        attempts += 1;
        console.error(`Error uploading part ${PartNumber}, attempt ${attempts}:`, error);
        const delay = Math.min(1000 * 2 ** attempts, 30000); // Exponential backoff
        await new Promise((resolve) => setTimeout(resolve, delay));

        if (attempts >= MAX_RETRIES) {
          console.error(`Failed to upload part ${PartNumber} after ${MAX_RETRIES} attempts`);
          throw new Error(`Failed to upload part ${PartNumber} after ${MAX_RETRIES} attempts`);
        }
      }
    }

    return eTag;
  };

  const completeMultipartUpload = async (s3Client, Bucket, Key, UploadId, Parts) => {
    const command = new CompleteMultipartUploadCommand({
      Bucket,
      Key,
      UploadId,
      MultipartUpload: { Parts },
    });
    return await s3Client.send(command);
  };

  const abortMultipartUpload = async (s3Client, Bucket, Key, UploadId) => {
    const command = new AbortMultipartUploadCommand({
      Bucket,
      Key,
      UploadId,
    });
    await s3Client.send(command);
  };

  const uploadFile = useCallback(
    async (s3Client, file, key, bucket) => {
      let uploadId;
      try {
        uploadId = await initiateMultipartUpload(s3Client, bucket, key, file);
        const parts = [];
        let partNumber = 1;
        const chunkQueue = [];
        let uploadedBytes = 0;

        for (let start = 0; start < file.size; start += CHUNK_SIZE) {
          if (uploadManagerRef.current[key].canceled) break;

          const chunk = file.slice(start, start + CHUNK_SIZE);
          chunkQueue.push({ partNumber, chunk });
          partNumber += 1;
        }

        const worker = async (queue) => {
          while (queue.length > 0) {
            if (uploadManagerRef.current[key].canceled) return;

            const { partNumber, chunk } = queue.shift();
            try {
              const eTag = await uploadChunk(s3Client, bucket, key, uploadId, partNumber, chunk);
              parts.push({ ETag: eTag, PartNumber: partNumber });
              uploadedBytes += chunk.size;

              // Update individual file progress
              const fileProgress = (uploadedBytes / file.size) * 100;
              setUploadingFiles((prev) => {
                if (!prev[key]) return prev;
                return {
                  ...prev,
                  [key]: { ...prev[key], progress: fileProgress.toFixed(2) },
                };
              });

              // Update overall progress
              const totalUploadedBytes =
                Object.values(uploadingFiles).reduce((acc, file) => acc + (file.uploadedBytes || 0), 0) + uploadedBytes;
              const totalSize = Object.values(uploadingFiles).reduce((acc, file) => acc + file.file.size, 0);
              const overallProgress = (totalUploadedBytes / totalSize) * 100;
              setOverallProgress(overallProgress.toFixed(2));
            } catch (error) {
              console.error(`Error uploading part ${partNumber}:`, error);
              queue.push({ partNumber, chunk });
            }
          }
        };

        const workers = Array.from({ length: concurrentUploads }, () => worker(chunkQueue));
        await Promise.all(workers);

        parts.sort((a, b) => a.PartNumber - b.PartNumber);

        let completeResponse;
        if (!uploadManagerRef.current[key].canceled) {
          completeResponse = await completeMultipartUpload(s3Client, bucket, key, uploadId, parts);
          setUploadingFiles((prev) => ({ ...prev, [key]: { ...prev[key], completed: true } }));
        } else {
          await abortMultipartUpload(s3Client, bucket, key, uploadId);
          setUploadingFiles((prev) => {
            const { [key]: _, ...rest } = prev;
            return rest;
          });
        }
        return { ...completeResponse, file };
      } catch (error) {
        if (uploadId) {
          await abortMultipartUpload(s3Client, bucket, key, uploadId);
        }
        console.error('Error uploading file:', error);
        addToast(true, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, 'File upload failed');
      }
    },
    [concurrentUploads, uploadingFiles]
  );

  const startUpload = async (files = [], bucket = FILEUPLOAD_S3, onComplete = () => {}) => {
    try {
      const totalSize = files.reduce((acc, f) => acc + f.size, 0);
      await validateUpload(totalSize);

      const credentials = await Auth.currentCredentials();
      const s3Client = new S3Client({
        region: process.env.REACT_APP_REGION,
        credentials,
        useAccelerateEndpoint: true,
      });
      const user = await Auth.currentAuthenticatedUser();
      const userId = user.attributes.sub;
      const [tenantId] = getTenantDetails();

      const uploadTasks = files.map((file) => {
        const key = `${uuidv4()}#$#${encodeURIComponent(file.name)}`;
        const file_location = `public/file_manager/${tenantId}/${userId}/${key}`;

        uploadManagerRef.current[file_location] = { s3Client, file, key: file_location, bucket, canceled: false };
        setUploadingFiles((prev) => ({
          ...prev,
          [file_location]: { file, uploading: true, completed: false, progress: 0 },
        }));
        return uploadFile(s3Client, file, file_location, bucket).catch((error) => {
          addToast(true, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, `Failed to upload file ${file.name}`);
          console.error(`Error uploading file ${file.name}:`, error);
        });
      });

      const responses = await Promise.all(uploadTasks);
      onComplete(responses.filter((response) => response?.Key)); // Filter out any undefined results from failed uploads
      responses?.filter((response) => !response?.Key) && setUploadingFiles({});
    } catch (error) {
      if (error?.response?.data === 'File storage limit for your subscription exceeded') {
        addToast(true, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, error?.response?.data);
      }
      console.error('Error starting upload:', error);
      addToast(true, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, 'Failed to start file upload');
    }
  };

  const checkUploadStatus = (key) => {
    const ongoingUploads = Object.values(uploadingFiles).some((file) => file.uploading && !file.completed);
    if (ongoingUploads) {
      confirmDialog({
        message: 'Are you sure you want to cancel all uploads?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: cancelAllUploads,
        reject: () => null,
        closable: false,
      });
    } else {
      setUploadingFiles({});
    }
  };

  const cancelAllUploads = useCallback(async () => {
    try {
      await Promise.all(
        Object.keys(uploadManagerRef.current).map(async (key) => {
          const { s3Client, bucket, uploadId } = uploadManagerRef.current[key];
          if (uploadId) {
            await abortMultipartUpload(s3Client, bucket, key, uploadId);
          }
          uploadManagerRef.current[key].canceled = true;
          setUploadingFiles((prev) => {
            const { [key]: _, ...rest } = prev;
            return rest;
          });
        })
      );
    } catch (error) {
      console.error('Error canceling all uploads:', error);
      addToast(true, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, 'Failed to cancel all uploads');
    }
  }, [addToast]);

  return {
    startUpload,
    uploadingFiles,
    overallProgress,
    cancelAllUploads: checkUploadStatus,
  };
};

export default useMultipartUpload;
