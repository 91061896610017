import React from 'react';

import { Controller } from 'react-hook-form';

import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import LabelName from 'components/UI/LabelName/LabelName';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

function EventTitle({ control, errors }) {
  return (
    <div className="d-flex align-items-center py-2 flex-wrap">
      <div className="col-md-4 col-12">
        <LabelName required={true} htmlFor="title">
          Event Title
        </LabelName>
      </div>
      <div className="col-md-8 col-12">
        <Controller
          name="title"
          control={control}
          rules={{ required: true, minLength: 2 }}
          defaultValue=""
          render={({ field }) => (
            <TextSnippetWrapper
              ref={field.ref}
              type="text"
              placeholder="Title"
              className={'w-100 input-shadow'}
              id={field.name}
              {...field}
            />
          )}
        />
      </div>
      <div className="offset-md-4">
        {errors.title?.type === 'required' ? (
          <InputErrorMessage>Please enter title</InputErrorMessage>
        ) : errors.title?.type === 'minLength' ? (
          <InputErrorMessage>Title should be at least 2 characters</InputErrorMessage>
        ) : null}
      </div>
    </div>
  );
}

export default EventTitle;
