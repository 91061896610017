import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { loadIcon } from 'utils/filesUtils';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';

export const TitleTemplate = ({ rowData, event }) => {
  const {
    handleBreadCrumbs,
    setSelectedItem,
    isClosed,
    modals,
    onFilterChange,
    files,
    setPreviewList,
    setSelectedPreviewItem,
    isClientFileManager,
  } = useFileManagerContext();

  const { setShowPreview, setShowAddTag, setTagModalType, setShowUploadedVersionsModal } = modals;
  const sanitizedPk = rowData?.pk.replace(/[^a-zA-Z0-9_-]/g, '_');
  const tagArray = rowData?.tags || [];
  const displayedTags = tagArray.slice(0, 1); // Show only the first 2 tags
  const remainingTags = tagArray.slice(1);

  const selectFile = (e) => {
    if (e.entry_type === 'folder') {
      handleBreadCrumbs(e);
      // let obj = {
      //   case_id: isGlobalFileManager || isClientFileManager ? '' : caseDetails.case_id,
      //   selectedFolder: e.object_id,
      //   selectedType: '',
      //   tag: '',
      //   query: '',
      //   folder_id: '',
      // };
      // getFiles(obj, currentView, pageLimit);
      let data = {
        target: {
          name: 'selectedFolder',
        },
        value: e.object_id,
      };
      onFilterChange(data);
    } else {
      setShowPreview(true);
      setPreviewList(files);
      setSelectedPreviewItem(e);
    }
  };

  function handleTitleClick() {
    if (!rowData?.case_id || rowData?.case_id === 'undefined') {
      // setNotLinkedToCase(true);
      // setSelectedCase("");
    } else {
      // getCaseObject(rowData?.case_id);
    }

    selectFile(rowData);
  }

  function handleTagClick(type) {
    setShowAddTag(true);
    setTagModalType(type);
    setSelectedItem(rowData);
  }

  function handleVersionControlClick() {
    setSelectedItem(rowData);
    setShowUploadedVersionsModal(true);
  }
  {
    console.log(rowData);
  }

  return (
    <>
      <div className="d-flex text-break flex-sm-row w-100">
        <span className="p-column-title text-break">Name</span>
        <div className="text-truncate">
          <span className="d-flex pointer align-items-center text-truncate">
            <span className="d-flex" onClick={handleTitleClick}>
              {rowData?.entry_type === 'folder' ? (
                <i className="icon-folder fa-lg"></i>
              ) : (
                loadIcon(rowData?.display_name?.split('.')?.reverse()[0])
              )}
            </span>
            <span className="ms-2 text-truncate">
              <span
                onClick={handleTitleClick}
                title={rowData.display_name}
                className={
                  rowData.is_read === true || rowData?.is_read === undefined
                    ? 'pointer text-break text-primary-dark'
                    : 'pointer text-bold text-primary-dark'
                }
              >
                {rowData.display_name}
              </span>
            </span>
            {rowData?.entry_type !== 'folder' && rowData?.versions?.length > 0 ? (
              <i onClick={handleVersionControlClick} className="fas fa-history ms-3 pointer">
                <Tooltip content="File Versions" position="top" target=".fa-history" showDelay={500} />
              </i>
            ) : null}
          </span>

          {!isClientFileManager && (
            <div className="tag-wrp d-flex flex-wrap pt-2">
              {rowData && rowData.tags && rowData.tags.length ? (
                // <>
                //   {rowData.tags.map((val, index) => (
                //     <span
                //       key={index}
                //       className={'tag m-1 ms-0 pointer ' + (isClosed ? 'pe-none opacity-25' : '')}
                //       onClick={() => handleTagClick('edit')}
                //     >{`#${val.replaceAll('#', '')}  `}</span>
                //   ))}
                //   <i onClick={() => handleTagClick('edit')} className="tag icon-add m-1 ms-0 pointer d-flex align-items-center"></i>
                // </>
                <>
                  {displayedTags.map((val, index) => (
                    <span className="text-break file-manager-ellipsis-text" title={val} key={index}>
                      <Tag value={val} className="case-d-tag me-1 mb-1 tag-ellipsis-text" />
                    </span>
                  ))}

                  {/* Show "+more" if there are more than 2 tags */}
                  {remainingTags.length > 0 && (
                    <>
                      <Tag
                        className={`p-mr-2 me-1 mb-1 tag case-d-tag more-tag more-tag-${sanitizedPk}`}
                        rounded
                        value={`+${remainingTags.length} more`}
                      />
                      <Tooltip target={`.more-tag-${sanitizedPk}`} position="top">
                        {remainingTags.map((tag, index) => (
                          <Tag key={index} value={tag} className="case-d-tag me-1 mb-1" />
                        ))}
                      </Tooltip>
                    </>
                  )}
                  <i onClick={() => handleTagClick('edit')} className="tag icon-add m-1 ms-0 pointer d-flex align-items-center"></i>
                </>
              ) : (
                <span
                  className={'F-size12 tag m-1 ms-0 pointer' + (isClosed ? 'pe-none opacity-25' : '')}
                  onClick={(e) => handleTagClick('add')}
                >
                  <i className="icon-add"></i>Add Tag
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
