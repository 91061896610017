import React, { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { MultiSelect } from 'primereact/multiselect';

import LabelName from 'components/UI/LabelName/LabelName';
import { useLeadContext } from 'modules/lead/Context/LeadContext';
import { handleFilterUpdateInUserContext } from 'common/pagination';
import { UserDetailsContext } from 'context/userDetailsContext';
import { totalCountEvent } from 'services/generalServices';
import { listLeadStages } from 'services/Settings';

function LeadSettings({ setLeadStages }) {
  const { control, handleSubmit, reset } = useForm();
  const { showLeadSettings, setShowLeadSettings, allPracticeArea } = useLeadContext();
  const userContext = useContext(UserDetailsContext);

  const hideSideBar = () => {
    setShowLeadSettings(false);
  };

  useEffect(() => {
    //PrimeReactMultiSelect: Empty label chips showing.
    const dropdownClasses = document?.querySelectorAll('.p-multiselect-token');
    for (const dropdownElement of dropdownClasses) {
      const dropLabelElement = dropdownElement?.querySelector('.p-multiselect-token-label')?.innerHTML;

      if (dropLabelElement?.trim()?.length === 0) {
        dropdownElement.style.display = 'none';
      }
    }
  }, [allPracticeArea]);

  useEffect(() => {
    reset({
      practice_area: userContext?.userDetails?.user_preferences?.filters?.leads?.practice_area,
      show_unassigned_pa_column: userContext?.userDetails?.user_preferences?.filters?.leads?.show_unassigned_pa_column,
    });
  }, [userContext]);

  const assignToItemTemplate = (option) => {
    if (option?.status === 'ARCHIVED') {
      return <>{`${option?.practice_area_name} (Inactive)`}</>;
    }
    return <>{option?.practice_area_name}</>;
  };

  const handleSettingsSubmit = (data) => {
    handleFilterUpdateInUserContext(userContext, 'filters', 'leads', null, 'practice_area', data?.practice_area, false);
    handleFilterUpdateInUserContext(
      userContext,
      'filters',
      'leads',
      null,
      'show_unassigned_pa_column',
      data?.show_unassigned_pa_column,
      false
    );
    totalCountEvent('lead-stage')
      .then((response) => {
        listLeadStages(response.data, '', '', 'ACTIVE', data)
          .then((res) => {
            setLeadStages(res.data?.lead_stage || []);
            hideSideBar();
          })
          .catch((error) => console.error(error));
      })

      .catch((err) => console.log(err));
  };

  return (
    <Sidebar
      visible={true}
      position="right"
      onHide={showLeadSettings}
      showCloseIcon={false}
      className="p-sidebar-md filter-slidebar eventsetting-slider"
    >
      <div className="row">
        <div className="col-12 d-flex justify-content-between salign-items-center py-2 mb-4">
          <div className="d-flex align-items-center">
            <div>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  hideSideBar();
                }}
              >
                <div className="icon-box me-2">
                  <i className="pi pi-angle-left"></i>
                </div>
              </a>
            </div>
            <h5 className="sub-title mb-0"> Lead Settings</h5>
          </div>
          <Button type="submit" className="p-button p-button-secondary" onClick={handleSubmit(handleSettingsSubmit)} label="Save Changes" />
        </div>
      </div>
      <div className="row px-5">
        <div className="col-12 px-0">
          <h5 className="caption-bold">Lead Stage Display Settings</h5>
        </div>
      </div>
      <div className="row ps-5 pe-3">
        <div className="col-12 px-0 d-flex align-items-center justify-content-between mb-3">
          <LabelName required={false}>Practice Area</LabelName>
          <div className="w-75">
            <Controller
              name="practice_area"
              control={control}
              // rules={{ required: 'Practice Area is required' }} // Uncomment to add validation
              render={({ field, fieldState }) => {
                const filteredPracticeAreaList = allPracticeArea.filter(
                  (option) => field.value?.includes(option.sk) || option.status === 'ACTIVE'
                );

                return (
                  <>
                    <MultiSelect
                      id={field.name}
                      onChange={(e) => field.onChange(e.value)} // Update the value in the form state
                      value={field.value} // Bind the field's value
                      options={filteredPracticeAreaList} // Use the filtered list here
                      optionLabel="practice_area_name" // Display field in the dropdown
                      optionValue="sk" // Underlying value for the selection
                      className={`col-sm-12 input-shadow w-100 ${
                        fieldState.error ? 'p-invalid' : '' // Add error styling if validation fails
                      }`}
                      style={{ height: '40px' }}
                      placeholder="Select Practice Area"
                      itemTemplate={assignToItemTemplate} // Custom item rendering
                      filter
                      display="chip" // Show selected items as chips
                    />
                    {fieldState.error && ( // Display an error message if validation fails
                      <small className="p-error">{fieldState.error.message}</small>
                    )}
                  </>
                );
              }}
            />
          </div>
        </div>
        <div className="col-12 px-0 d-flex align-items-center justify-content-between mb-3">
          <LabelName>Show columns not assigned to Practice Area</LabelName>
          <Controller
            name="show_unassigned_pa_column"
            control={control}
            defaultValue={true}
            render={({ field }) => (
              <InputSwitch
                id={field.name}
                value={field.value}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                className="input-swich"
              />
            )}
          />
        </div>
      </div>
    </Sidebar>
  );
}

export default LeadSettings;
