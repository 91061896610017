import { useState } from 'react';
import { listEventTypes } from 'services/Calender/calendarServices';

import { totalCountEvent } from 'services/generalServices';

export default function useGetEventTypes() {
  const [typeList, setTypeList] = useState([]);

  const getEventTypes = () => {
    totalCountEvent('event-type')
      .then((response) => {
        let obj = {
          pageLimit: response.data,
          pagination_token: '',
          pagination_direction: '',
        };
        listEventTypes(obj)
          .then((res) => {
            let data = res?.data['event-type'];
            let tempArray = [];

            data.forEach((item) => {
              tempArray.push({
                event_type: item.event_type,
                event_type_icon: item.event_type_icon,
              });
            });

            setTypeList(tempArray);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    getEventTypes,
    typeList,
  };
}
