import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import { InputSwitch } from 'primereact/inputswitch';
import { RadioButton } from 'primereact/radiobutton';

import ImageComponent from 'shared/ImageComponent';

import { getTenantIdFromLS } from 'utils/localStorageUtils';
import { handleRedirection } from 'utils/utils';

function SelectedGroups(props) {
  const { selectedGroups, control, history, watch, updateReminder, setValue } = props;
  const tenantId = getTenantIdFromLS();
  const groups = watch('groups') || selectedGroups;

  const sendReminderValues = groups?.map((_, id) => watch(`groups.${id}.send_reminder`, false));
  const isReminderRequired = sendReminderValues?.some((value) => value === true);

  useEffect(() => {
    updateReminder('groups', isReminderRequired);
  }, [isReminderRequired, groups]);

  const handleSendReminderChange = (id, value) => {
    const updatedGroups = [...groups];
    updatedGroups[id].send_reminder = value;
    setValue('groups', updatedGroups);
  };

  return (
    <div className="d-flex align-items-center py-2">
      <div className="col-12">
        <div className="">
          <table className="table attendees-table">
            <thead>
              <tr>
                <th className="attendee-names">Groups</th>
                <th className="text-center">
                  <label className="text-muted">Required</label>
                </th>
                <th className="text-center">
                  <label className="text-muted">Optional</label>
                </th>
                <th className="text-center">
                  <label className="text-muted">Send Reminder</label>
                </th>
              </tr>
            </thead>
            {selectedGroups.map((item, id) => {
              return (
                <tbody key={id}>
                  <td className="attendee-names">
                    <div
                      className="d-flex text-primary-dark text-decoration-none text-capitalize cursor-pointer"
                      onClick={() => {
                        let url = '/settings/groups';
                        handleRedirection(history, url);
                      }}
                    >
                      <ImageComponent
                        filePath={`${tenantId}/${item?.client_cognito_username}/profile_image`}
                        fileName={item.group_name}
                        fileSize="small.jpg"
                      />
                      <div className="mx-2 align-items-center d-flex">{item.group_name}</div>
                    </div>
                  </td>

                  <Controller
                    name={`groups.${id}.is_attendance_required`}
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <td className="text-center">
                          <div>
                            <RadioButton
                              inputId="required"
                              name={field.name}
                              value="required"
                              checked={field.value === 'required'}
                              onChange={(e) => field.onChange(e.value)}
                            />
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="p-field-radiobutton">
                            <RadioButton
                              inputId="optional"
                              name="gender"
                              value="optional"
                              checked={field.value === 'optional'}
                              onChange={(e) => field.onChange(e.value)}
                            />
                          </div>
                        </td>
                      </>
                    )}
                  />

                  <Controller
                    name={`groups.${id}.send_reminder`}
                    control={control}
                    render={({ field }) => (
                      <td className="text-center">
                        <InputSwitch
                          id={field.name}
                          value={field.value}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                            handleSendReminderChange(id, e.value);
                          }}
                          placeholder="Meeting URL"
                        />
                      </td>
                    )}
                  />
                </tbody>
              );
            })}
          </table>
        </div>
      </div>
    </div>
  );
}

export default SelectedGroups;
