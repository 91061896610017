import React, { useContext, useEffect } from 'react';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

import CustomHeader from './CustomHeader';
import { WebSocketConnectionContext } from 'context/WebSocketConnectionContext';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';

function FileSummarizeModal() {
  const { modals, summaryData, selectedItem, setSummaryData } = useFileManagerContext();
  const { showSummarizeModal, setShowSummarizeModal } = modals;
  const websocketContext = useContext(WebSocketConnectionContext);
  const { addToast } = useToast();

  useEffect(() => {
    if (selectedItem?.version_id === websocketContext?.aiFileSummary?.body?.summary?.summary_file_version_id) {
      setSummaryData(websocketContext?.aiFileSummary?.body?.summary);
    }
  }, [websocketContext?.aiFileSummary]);

  const copyHandler = () => {
    const data =
      selectedItem?.version_id === websocketContext?.aiFileSummary?.body?.summary?.summary_file_version_id
        ? websocketContext?.aiFileSummary?.body?.summary?.summary
        : summaryData?.summary;
    if (data) {
      navigator.clipboard
        .writeText(data)
        .then(() => {
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.SUMMARY_COPY_SUCCCESS);
        })
        .catch(() => {
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.SUMMARY_COPY_FAIL);
        });
    } else {
      addToast(false, toastConstant.toasterType.ERROR, toastConstant.message.SUMMARY_COPY_FAIL);
    }
  };

  const SummaryFooter = (
    <div className="footer-btn">
      <Button
        label="Close"
        onClick={() => {
          setShowSummarizeModal(false);
          setSummaryData(null);
        }}
        className="p-button p-button-secondary outline mx-2"
      />
      <Button
        disabled={Boolean(summaryData === 'Summary is being generated') || summaryData?.isError}
        label="Copy"
        onClick={copyHandler}
        className="p-button p-button-secondary"
      />
    </div>
  );

  return (
    <Dialog
      className="new-task-popup"
      header={
        <CustomHeader
          onHide={() => {
            setShowSummarizeModal(false);
            setSummaryData(null);
          }}
        />
      }
      footer={SummaryFooter}
      visible={showSummarizeModal}
      modal
      onHide={() => {
        setShowSummarizeModal(false);
        setSummaryData(null);
      }}
      closable={false}
    >
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="modal-heading F-size16 text-bold">{`Summary of ${selectedItem?.file_name}`}</div>
      </div>
      <div className="d-flex mt-2 align-items-center mb-3">
        <span className="d-flex p-0" style={{ color: 'rgba(0, 0, 0, 0.6)', lineHeight: '18px' }}>
          <i className="pi pi-info-circle me-2"></i>
          Summary can be generated only for files with text and not scanned files.
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-center" style={{ textAlign: 'justify' }}>
        {summaryData?.summary ? summaryData?.summary : summaryData}
      </div>
    </Dialog>
  );
}

export default FileSummarizeModal;
