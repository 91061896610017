/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import {
  createContact,
  // listContacts,
  updateContactDetails,
} from 'services/contact';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import _ from 'lodash';
import moment from 'moment';
import { getCustomFieldList } from 'redux/actions/CustomFieldAction';
import { useSelector, useDispatch } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectButton } from 'primereact/selectbutton';
import { OverlayPanel } from 'primereact/overlaypanel';
import { stateList } from 'constants/stateList';
import { Checkbox } from 'primereact/checkbox';
import { AddContact as AddContactPopup } from 'modules/contact/addContactPopup';
import { Dialog } from 'primereact/dialog';
import { getRelationRolesAction } from 'redux/actions/RelationRoleAction';
import { getBusinessRoleAction } from 'redux/actions/BusinessRoleAction';
import NumberFormat from 'react-number-format';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import { totalCountEvent } from 'services/generalServices';
import { phoneNumberDuplicationCheckFunc } from 'utils/utility_functions/commonValidations';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { ModalHeader } from 'shared/ModalHeader';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { businessTypes } from 'constants/dropdownOptions';
import { useExperts } from 'hooks/useExperts';

export const AddContact = (props) => {
  const customFieldData = useSelector((state) => state.customField);
  const { checkSubPermission } = useUserDetailsContext();
  const isFromLeadScreen = props?.isFromAddLead ?? false;
  const handleClose = () => {
    props.setShow(false);
    setClientRole('');
    setContactFieldsValue([]);
    setFormErrors({});
  };
  const [radioValue, setRadioValue] = useState('person');
  const [phoneMatch, setPhoneMatch] = useState();
  const [dob, setDob] = useState();
  const [contactFields, setContactFields] = useState([]);
  const [contactFieldsValue, setContactFieldsValue] = useState([]);
  const [addCustomField, setAddCustomField] = useState(false);
  const [isCourtLocation, setIsCourtLocation] = useState(false);
  const [enableLawftAccess, setEnableLawftAccess] = useState(false);
  const [minimize, setMinimize] = useState(false);
  const dispatch = useDispatch();
  const [clientRole, setClientRole] = useState('');
  const [clientRoleError, setClientRoleError] = useState(false);
  let [formErrors, setFormErrors] = useState({
    phone_number: '',
  });
  const businessRoleList = useSelector((state) => state.businessRole?.businessRoleList.business_role);
  const { expertList } = useExperts();

  useEffect(() => {
    totalCountEvent('business_role').then((response) => {
      dispatch(getBusinessRoleAction(response.data));
    });
    totalCountEvent('relation_role').then((response) => {
      dispatch(getRelationRolesAction(response.data));
    });
  }, []);
  const roleList = useSelector((state) => state.roleList.roleListData.relation_role);
  let [initialValues, setInitialValues] = useState({
    first_name: '',
    last_name: '',
    middle_name: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    title: '',
    role: '',
    is_primary_contact: '',
    is_billing_contact: '',
    is_client_opt_in: true,
    fax_number: '',
  });
  const radios = [
    { name: 'Person', value: 'person' },
    { name: 'Business', value: 'business' },
  ];
  const types = [
    { label: 'Person', value: 'person' },
    { label: 'Business', value: 'business' },
  ];
  const titles = [
    { label: 'Mr.', value: 'Mr.' },
    { label: 'Ms.', value: 'Ms.' },
    { label: 'Mrs.', value: 'Mrs.' },
    { label: 'Miss.', value: 'Miss.' },
  ];

  const businessRoles = [];
  if (businessRoleList?.length > 0) {
    businessRoleList.forEach((role) => {
      businessRoles.push({ label: role.name, value: role.name.toLowerCase() });
    });
  }

  let [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState();
  const [selectedCountry, setSelectedCountry] = useState('United States');
  const [title, setTitle] = useState(titles[0].value);
  const [relations, setRelations] = useState([]);
  const personSchema = Yup.object().shape({
    first_name: Yup.string()
      .max(50, 'Maximum 50 characters required')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed')
      .required('Required'),
    middle_name: Yup.string()
      .max(50, 'Maximum 50 characters required')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed'),
    last_name: Yup.string()
      .max(50, 'Maximum 50 characters required')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed')
      .required('Required'),
    role: Yup.string().required('Required'),

    email: Yup.string().email('Invalid email'),
    phone: Yup.string().min(10, 'Invalid phone number').max(14, 'Invalid phone number'),
    street: Yup.string().min(2, 'Minimum 2 characters required'),

    state: Yup.string().min(2, 'Minimum 2 characters required'),
    city: Yup.string().min(2, 'Minimum 2 characters required'),

    zip: Yup.string()
      .matches(/^[0-9]+$/, 'Only digits are allowed')
      .min(5, 'Invalid')
      .max(5, 'Invalid'),
    country: Yup.string().min(2, 'Minimum 2 characters required'),
  });
  const businessSchema = Yup.object().shape({
    expert_type: Yup.string().required('Required'),
    title: Yup.string().min(2, 'Minimum 2 characters required').required('Required'),
    role: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email'),
    phone: Yup.string()
      // .matches(
      //   /^(?!000-000-0000)[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}-[0-9]{4}$/i,
      //   "Invalid phone number"
      // )
      .min(10, 'Invalid phone number')
      .max(14, 'Invalid phone number'),
    // .required("Required"),
    street: Yup.string().min(2, 'Minimum 2 characters required'),

    state: Yup.string().min(2, 'Minimum 2 characters required'),

    city: Yup.string().min(2, 'Minimum 2 characters required'),

    zip: Yup.string()
      .matches(/^[0-9]+$/, 'Only digits are allowed')
      .min(5, 'Invalid')
      .max(5, 'Invalid'),

    country: Yup.string().min(2, 'Minimum 2 characters required'),
  });

  const [contactSchema, setContactSchema] = useState(personSchema);

  const { addToast } = useToast();
  const [show, setShow] = useState(false);
  // const [contactList, setContactList] = useState([]);
  const [telephone, setTelephone] = useState('');
  const overlayRef = useRef(null);
  const contactOverLay = useRef(null);
  const [addContact, setAddContact] = useState(false);
  let [emptySuggestions, setEmptySuggestions] = useState({});
  // const [selectedIndex, setSelectedIndex] = useState();

  const handleClickOutside = (event) => {
    if (contactOverLay.current && !contactOverLay.current.contains(event.target)) {
      try {
        let className = event?.target?.className;
        if (className !== 'p-dropdown-item') {
          setAddContact(false);
          setEmptySuggestions({});
        }
      } catch (err) {}
    }
  };

  useEffect(() => {
    totalCountEvent('custom-field').then((response) => {
      dispatch(getCustomFieldList('', response.data));
    });
    setMinimize(false);
  }, []);

  useEffect(() => {
    if (customFieldData && customFieldData.customFieldList['custom-field'] && customFieldData.customFieldList['custom-field'].length) {
      let data = customFieldData.customFieldList['custom-field'].filter((val) => val.module === 'contact');
      setContactFields(data);
    } else {
      setContactFields([]);
    }
  }, [customFieldData]);

  useEffect(() => {
    if (!addCustomField) {
      setContactFieldsValue([]);
    }
  }, [addCustomField]);

  useEffect(() => {
    setInitialValues({
      first_name: '',
      last_name: '',
      middle_name: '',
      email: '',
      phone: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      title: '',
      education: '',
      role: '',
      is_primary_contact: '',
      is_billing_contact: '',
      is_client_opt_in: true,
    });
    setPhoneMatch();
    setRelations([]);
    setDob();
    setTelephone();
    setRadioValue('person');
    if (!props.contactDetails) {
      onChangeCounty({ target: { value: 'United States' } });
    }

    setSelectedState();
    setIsCourtLocation(false);

    if (props.show) {
      setMinimize(false);
    }
  }, [props.show]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
  }, [contactOverLay]);

  const onAddContact = async (values, { resetForm }) => {
    if (!validateRelations() && validate()) {
      values.contact_type = radioValue;
      values.custom_fields = contactFieldsValue;
      values.country = selectedCountry ? selectedCountry : '';
      values.state = selectedState ? selectedState.value : '';
      values.is_create_client_portal = enableLawftAccess;
      // values.role = clientRole;
      values.country_code = '+1';

      if (radioValue === 'person') {
        if (dob) values.dob = moment(dob).format('MM/DD/yyyy');
        values.title = title;
      } else {
        values.first_name = '';
        values.middle_name = '';
        values.last_name = '';
      }
      if (radioValue === 'location') {
        values.is_court_location = isCourtLocation;
      }
      if (isFromLeadScreen) {
        if (radioValue === 'person') {
          values.relations = [];
          relations.forEach((index) => {
            let obj = {};
            if (index.fields && index.fields.length > 0) {
              index.fields.forEach((field) => {
                obj[field] = index[field];
              });
            }
            delete index.fields;
            obj = { ...obj, ...index };
            values.relations.push(obj);
          });
        } else {
          values.relations = [];
        }

        createContact(values)
          .then((response) => {
            if (response.data) {
              props?.setShow(false);
              props?.handleSubmitData({ ...values, ...response?.data });

              addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.CONTACT_CREATE_SUCCESS);
              // let data = {
              //   ...response.data,
              //   ...values,
              // };
              handleClose();
              return;
            }
          })
          .catch((err) => {
            console.log('err', err);

            addToast(
              false,
              toastConstant.toasterType.ERROR,
              toastConstant.api.FAILED,
              // toastConstant.message.CONTACT_CREATE_FAILED
              toastConstant.message.DUPLICATE_CONTACT_EXITS
            );
            return;
          });
      }
      if (!isFromLeadScreen) {
        let msg = props.isEdit ? toastConstant.message.CONTACT_UPDATE_SUCCESS : toastConstant.message.CONTACT_CREATE_SUCCESS;
        let error = props.isEdit ? toastConstant.message.CONTACT_UPDATE_FAILED : toastConstant.message.CONTACT_CREATE_FAILED;
        try {
          let response = '';
          if (props.isEdit && props.contactDetails) {
            values.sk = props.contactDetails.sk;
            values.pk = props.contactDetails.pk;
            response = await updateContactDetails(values);
          } else {
            if (radioValue === 'person') {
              values.relations = [];
              relations.forEach((index) => {
                let obj = {};
                if (index.fields && index.fields.length > 0) {
                  index.fields.forEach((field) => {
                    obj[field] = index[field];
                  });
                }
                delete index.fields;
                obj = { ...obj, ...index };
                values.relations.push(obj);
              });
            } else {
              values.relations = [];
            }

            response = await createContact(values);
          }
          if (response.data) {
            if (isFromLeadScreen) {
              props?.setShow(false);
              props?.handleSubmitData(values);
            }

            addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, msg);

            let data = {
              ...response.data,
              ...values,
              isFromAddContactModal: true,
            };

            props?.onSubmit(data);
            handleClose();
          }
        } catch (err) {
          let errorMsg = err?.response?.data;
          error = errorMsg ? errorMsg : error;
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, error);
        }
      }
    } else {
      if (clientRole) {
        setClientRoleError(false);
      } else {
        setClientRoleError(true);
      }
    }
  };

  const loadContactData = (data) => {
    let values = {
      first_name: data.first_name,
      last_name: data.last_name,
      middle_name: data.middle_name,
      email: data.email,
      phone: data.phone,
      street: data.street,
      city: data.city,
      state: data.state,
      zip: data.zip,
      country: data.country,
      title: data.title,
      is_client_opt_in: data.is_client_opt_in,
    };
    setEnableLawftAccess(data.is_create_client_portal);
    setTelephone(data.phone);
    setInitialValues({ ...values });
    setRadioValue(data.contact_type);
    setTitle(data.title);
    setShow(false);
    if (data.contact_type === 'person') {
      setContactSchema(personSchema);
    } else {
      setContactSchema(businessSchema);
    }
    if (data.contact_type === 'location') setIsCourtLocation(data.is_court_location);

    if (data.dob) setDob(new Date(data.dob));
    if (data.custom_fields && data.custom_fields.length > 0) {
      setAddCustomField(true);
      setContactFieldsValue(data.custom_fields);
    }

    if (data.country) setSelectedCountry(data.country);
    if (data.country === 'United States') {
      let state_list = stateList.map((index) => {
        return {
          label: index.abbreviation + ' - ' + index.name,
          value: index.abbreviation + ' - ' + index.name,
        };
      });

      setStates([...state_list]);
    } else {
      setStates([]);
    }
    if (data.state) {
      let _states = states.filter((index) => index.value === data.state);
      if (_states && _states.length === 0) {
        states.push({ label: data.state, value: data.state });
        setStates([...states]);
      }
      setSelectedState({ label: data.state, value: data.state });
    }
  };

  useEffect(() => {
    if (props.isEdit && props.contactDetails) {
      loadContactData(props.contactDetails);
    }
  }, [props.isEdit]);

  const setContactDetails = (e) => {
    initialValues[e.target.name] = e.target.value;
    setInitialValues({ ...initialValues });
  };

  // const cancelRelation = (index) => {
  //   relations.splice(index, 1);
  //   setRelations([...relations]);
  // };

  // const onChangeRelation = (e, id) => {
  //   if (e.target.name === "phone") {
  //     let obj = relations[id];
  //     let phoneNumber = obj.phone ? obj.phone : "";
  //     if (
  //       e.target.value === "(" ||
  //       (phoneNumber && phoneNumber.charAt(0) === "(")
  //     ) {
  //       let phone = e.target.value.replace(
  //         /(\d{3})(\d{3})(\d{4})/,
  //         "$1) $2-$3"
  //       );

  //       obj.phone = phone;
  //       relations[id] = { ...obj };
  //     } else {
  //       let phone = e.target.value.replace(/(\d{3})(\d{3})(\d+)/, "$1-$2-$3");
  //       obj.phone = phone;
  //       relations[id] = { ...obj };
  //     }
  //   } else {
  //     relations[id][e.target.name] = e.target.value;
  //   }

  //   setRelations([...relations]);
  // };

  // const searchContact = async (e, id) => {
  //   let relation_names = relations.map((index) => index.name);
  //   let response = await listContacts("", "", "", e.query);
  //   if (response && response.data && response.data.contacts)
  //     response.data.contacts.forEach(
  //       (index) =>
  //         (index.name =
  //           index.first_name + " " + index.middle_name + " " + index.last_name)
  //     );

  //   response.data.contacts = response.data.contacts.filter(
  //     (index) => !relation_names.includes(index.name)
  //   );

  //   if (response.data.contacts.length > 0) {
  //     emptySuggestions[id] = false;
  //     setEmptySuggestions({ ...emptySuggestions });
  //     setContactList([...response.data.contacts]);
  //   } else {
  //     setContactList([]);
  //     emptySuggestions[id] = true;
  //     setEmptySuggestions({ ...emptySuggestions });
  //   }
  // };

  // const onAddField = (id) => {
  //   let obj = relations[id];
  //   let field = obj.field;
  //   if ((obj && field) || (obj.sk && obj.hasOwnProperty(field) && obj[field])) {
  //     if (obj.fields && !obj.fields.includes(field)) {
  //       obj.fields.push(field);
  //     } else if (!obj.fields) {
  //       obj.fields = [];
  //       obj.fields.push(field);
  //     }
  //     relations[id] = obj;

  //     setRelations([...relations]);
  //   }
  // };
  // const onAddContactSuccess = (data) => {
  //   relations[selectedIndex] = {
  //     ...data,
  //     ...{ role: "spouse", fields: ["email"] },
  //   };
  //   setRelations([...relations]);
  //   setEmptySuggestions({});
  // };

  const validate = () => {
    let isValid = true;
    if (!!formErrors.phone_number) {
      isValid = false;
    }
    return isValid;
  };

  const validateRelations = () => {
    let error = false;

    if (relations && relations.length > 0) {
      relations.forEach((index) => {
        index.first_name = index?.first_name?.trim();
        index.error = false;
        if (!index.first_name) {
          index.error = 'name';
          error = true;
        }
        if (index.email && !index.email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
          index.error = 'email';
          error = true;
        }
        if (index.phone && !index.phone.match(/^(?!000-000-0000)[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}-[0-9]{4}$/i)) {
          index.error = 'phone';
          error = true;
        }
      });
      setRelations([...relations]);
    }
    if (!isFromLeadScreen && radioValue !== 'business') {
      if (!clientRole) {
        setClientRoleError(true);
        error = true;
      }
    }

    return error;
  };

  const handleCustomFieldChange = (e) => {
    try {
      let prevdata = [...contactFieldsValue];
      let indexVal = prevdata.findIndex((val) => {
        return Object.keys(val)[0] === e.target.name;
      });

      if (indexVal === -1) {
        setContactFieldsValue([...contactFieldsValue, { [e.target.name]: e.target.value }]);
      } else {
        let dat = [...contactFieldsValue];
        dat[indexVal][e.target.name] = e.target.value;
        setContactFieldsValue(dat);
      }
    } catch (e) {}
  };

  const onChangeCounty = (e) => {
    setSelectedCountry(e.target.value);
    if (e.target.value === 'United States') {
      let state_list = stateList.map((index) => {
        return {
          label: index.abbreviation + ' - ' + index.name,
          value: index.abbreviation + ' - ' + index.name,
        };
      });

      setStates([...state_list]);
    } else {
      setStates([]);
      setSelectedState();
    }
  };
  // const onChangeRelations = (e, id) => {
  //   if (e.target.name === "phone") {
  //     let obj = relations[id];
  //     let phoneNumber = obj.phone ? obj.phone : "";
  //     if (
  //       e.target.value === "(" ||
  //       (phoneNumber && phoneNumber.charAt(0) === "(")
  //     ) {
  //       let phone = e.target.value.replace(
  //         /(\d{3})(\d{3})(\d{4})/,
  //         "$1) $2-$3"
  //       );

  //       obj.phone = phone;
  //       relations[id] = { ...obj };
  //     } else {
  //       let phone = e.target.value.replace(/(\d{3})(\d{3})(\d+)/, "$1-$2-$3");
  //       obj.phone = phone;
  //       relations[id] = { ...obj };
  //     }
  //   } else {
  //     let obj = relations[id];
  //     obj.error = false;
  //     obj[e.target.name] = e.target.value;
  //     relations[id] = obj;
  //   }

  //   setRelations([...relations]);
  // };

  // const removeField = (id, fieldId) => {
  //   let obj = relations[id];
  //   if (obj.fields && obj.fields.length > 0) {
  //     obj.fields.splice(fieldId, 1);
  //     relations[id] = obj;
  //     setRelations([...relations]);
  //   }
  // };
  // const changeClientPortal = (e, id) => {
  //   let obj = relations[id];
  //   obj.is_create_client_portal = e.checked;
  //   relations[id] = { ...obj };
  //   setRelations([...relations]);
  // };

  const iconsDialog = () => {
    return (
      <div className="popup-minimize px-2">
        {minimize && (
          <i
            className="far fa-window-maximize pt-2"
            onClick={(e) => {
              setMinimize(false);
            }}
          ></i>
        )}
        {!minimize && (
          <i
            className="far fa-window-minimize"
            onClick={(e) => {
              setMinimize(true);
            }}
          ></i>
        )}
      </div>
    );
  };

  const filterCustomFiled = (type) => {
    if (type === 'person') {
      let filteredCustomField = customFieldData?.customFieldList['custom-field']?.filter((item, index) => {
        return item.module === 'contact';
      });
      setContactFields(filteredCustomField);
    }
    if (type === 'business') {
      let filteredCustomField = customFieldData?.customFieldList['custom-field']?.filter((item, index) => {
        return item.module === 'companies';
      });
      setContactFields(filteredCustomField);
    }
  };

  return (
    <Dialog
      visible={props.show}
      onHide={handleClose}
      showCloseIcon={false}
      header={ModalHeader}
      footer={<></>}
      // header={isFromLeadScreen ? "Add New Relation" : "Add New Contact"}
      resizable={false}
      className={minimize ? 'new-task-popup dialog-minimize addnewcontact' : 'new-task-popup add-contact-dialog addnewcontact'}
      icons={iconsDialog}
      modal={false}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onAddContact}
        validationSchema={contactSchema}
        children={AddContactPopup}
      >
        {({ errors, touched, resetForm, values }) => (
          <Form>
            <div className="row">
              <div className="col-12 col-lg-12 d-flex justify-content-between pb-3">
                <div className="d-flex align-items-center justify-content-start">
                  {' '}
                  <a
                    href="/"
                    aria-label={isFromLeadScreen ? 'Add New Relation' : 'Add New Contact'}
                    onClick={(e) => {
                      e?.preventDefault();
                      handleClose();
                    }}
                  ></a>
                  <h5 className="sub-title mb-0">{isFromLeadScreen ? 'Add New Relation' : 'Add New Contact'}</h5>
                </div>
              </div>
            </div>
            <OverlayPanel ref={overlayRef} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '450px' }}>
              <>
                {phoneMatch &&
                  phoneMatch.length > 0 &&
                  phoneMatch.map((index, _index) => {
                    return (
                      <div key={index.phone + ' ' + _index} className="row p-4 justify-content-md-center">
                        <div className="col-12">
                          <div className="border p-2 rounded shadow-sm">
                            <div>
                              <label>{index.first_name + ' ' + index.middle_name + ' ' + index.last_name}</label>
                            </div>
                            <div>
                              <label className="text-muted">{index.phone}</label>
                            </div>
                            <div>
                              <label className="text-muted">{index.email}</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 d-flex mt-3">
                          <button
                            className="btn btn-secondary btn-sm mx-1"
                            onClick={() => {
                              loadContactData(index);
                            }}
                          >
                            Apply data from this contact
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </>
            </OverlayPanel>
            <div className="filter-wraper">
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-12 col-md-4">
                  <label className="p-medium">Contact Type</label>
                </div>
                <div className="col-12 col-md-8">
                  {!props.isEdit && (
                    <SelectButton
                      className="btn-switch"
                      value={radioValue}
                      options={radios}
                      onChange={(e) => {
                        setRadioValue(e.value);
                        resetForm({});
                        setFormErrors({});
                        filterCustomFiled(e.value);
                        setInitialValues({
                          first_name: '',
                          last_name: '',
                          middle_name: '',
                          email: '',
                          phone: '',
                          street: '',
                          city: '',
                          state: '',
                          zip: '',
                          country: '',
                          title: '',
                          education: '',
                          role: '',
                          is_primary_contact: '',
                          is_billing_contact: '',
                          is_client_opt_in: true,
                        });

                        if (e.value === 'person') {
                          setContactSchema(personSchema);
                        } else {
                          setContactSchema(businessSchema);
                        }
                      }}
                      optionLabel="name"
                      unselectable={false}
                    />
                  )}
                  {props.isEdit && (
                    <Dropdown
                      value={radioValue}
                      options={types}
                      onChange={(e) => setTitle(e.value)}
                      disabled={true}
                      placeholder="Title"
                      filter
                      ////showClear={(radioValue ==="" )? false : true}
                    />
                  )}
                </div>
              </div>
              {radioValue === 'person' && (
                <>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-12 col-md-4">
                      <label className="p-medium">Title</label>
                    </div>
                    <div className="col-12 col-md-8">
                      <Dropdown
                        className="w-100"
                        value={title}
                        options={titles}
                        onChange={(e) => setTitle(e.value)}
                        placeholder="Title"
                        filter
                        //showClear={(title ==="" )? false : true}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-12 col-md-4">
                      <label className="p-medium">
                        First Name<span style={{ color: 'red' }}>*</span>
                      </label>
                    </div>
                    <div className="col-12 col-md-8">
                      <Field
                        id="first_name"
                        name="first_name"
                        value={initialValues.first_name}
                        className="input-shadow w-100 border-0 p-2"
                        onChange={setContactDetails}
                      />
                      {errors.first_name && touched.first_name ? <div className="text-danger small">{errors.first_name}</div> : null}
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-12 col-md-4">
                      <label className="p-medium">Middle Name</label>
                    </div>
                    <div className="col-12 col-md-8">
                      <Field
                        id="middle_name"
                        name="middle_name"
                        className="input-shadow w-100 border-0 p-2"
                        value={initialValues.middle_name}
                        onChange={setContactDetails}
                      />
                      {errors.middle_name && touched.middle_name ? <div className="text-danger small">{errors.middle_name}</div> : null}
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-12 col-md-4">
                      <label className="p-medium">
                        Last Name<span style={{ color: 'red' }}>*</span>
                      </label>
                    </div>
                    <div className="col-12 col-md-8">
                      <Field
                        id="last_name"
                        name="last_name"
                        className="input-shadow w-100 border-0 p-2"
                        value={initialValues.last_name}
                        onChange={setContactDetails}
                      />
                      {errors.last_name && touched.last_name ? <div className="text-danger small">{errors.last_name}</div> : null}
                    </div>
                  </div>
                  <div className="row p-2 d-none"></div>
                </>
              )}
              {radioValue !== 'person' && (
                <>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-12 col-lg-4 mt-2">
                      <label className="p-medium">Business Type</label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <Dropdown
                        className="input-shadow w-100 border-0"
                        value={initialValues?.business_type}
                        name="business_type"
                        options={businessTypes}
                        onChange={(e) => {
                          setContactDetails(e);
                        }}
                        filter
                        //showClear={(initialValues?.role ==="" )? false : true}
                        placeholder="Select"
                        showClear
                      />
                      {/* {errors.business_type && touched.business_type && !initialValues.business_type ? (
                        <div className="text-danger small">{errors.business_type}</div>
                      ) : null} */}
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-12 col-lg-4">
                      <label className="p-medium">
                        Business Name<span style={{ color: 'red' }}>*</span>
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <Field
                        id="title"
                        name="title"
                        className="input-shadow w-100 border-0 p-2"
                        onChange={setContactDetails}
                        value={initialValues.title}
                      />
                      {errors.title && touched.title ? <div className="text-danger small">{errors.title}</div> : null}
                    </div>
                  </div>
                </>
              )}
              {initialValues?.business_type === 'expert' && (
                <div className="d-flex align-items-center py-2 flex-wrap">
                  <div className="col-12 col-lg-4">
                    <label className="p-medium">
                      Expert Type<span style={{ color: 'red' }}>*</span>
                    </label>
                  </div>
                  <div className="col-12 col-lg-8 col-sm-offset-1">
                    <Dropdown
                      className="input-shadow w-100 border-0"
                      value={initialValues?.expert_type}
                      name="expert_type"
                      options={expertList}
                      optionLabel="title"
                      optionValue="title"
                      placeholder="Select"
                      onChange={(e) => {
                        setContactDetails(e);
                      }}
                      filter
                      //showClear={(initialValues?.role ==="" )? false : true}
                      // placeholder="Role
                    />
                    {errors.expert_type && touched.expert_type ? <div className="text-danger small">{errors.expert_type}</div> : null}
                  </div>
                </div>
              )}

              {radioValue === 'location' && (
                <div className="d-flex align-items-center py-2 flex-wrap">
                  <div className="col-12 col-sm-4 mt-2"></div>
                  <div className="col-12 col-sm-7 col-sm-offset-1">
                    <Checkbox
                      checked={isCourtLocation}
                      onChange={(e) => {
                        setIsCourtLocation(e.checked);
                      }}
                    />
                    <span> is court location</span>
                  </div>
                </div>
              )}
              <div className="row p-2 d-none">
                <div className="col-12 col-sm-7 col-sm-offset-1"></div>
              </div>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-12 col-md-4">
                  <label className="p-medium">
                    Email
                    {radioValue === 'person' && enableLawftAccess && <span style={{ color: 'red' }}>*</span>}
                  </label>
                </div>
                <div className="col-12 col-md-8">
                  <Field
                    id="email"
                    name="email"
                    className="input-shadow w-100 border-0 p-2"
                    onChange={setContactDetails}
                    value={initialValues.email}
                  />
                  {errors.email && touched.email ? <div className="text-danger small">{errors.email}</div> : null}
                  {initialValues.email && radioValue === 'person' ? (
                    <div className="d-flex align-items-center py-2">
                      <Checkbox
                        checked={enableLawftAccess}
                        onChange={(e) => {
                          setEnableLawftAccess(e.checked);
                        }}
                      />{' '}
                      <span className="ms-2"> Enable Lawft Access</span>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-12 col-md-4">
                  <label className="p-medium">
                    Phone Number
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </label>
                </div>
                <div className="col-12 col-md-8">
                  <NumberFormat
                    className="input-shadow w-100 p-2 border-0 number-format"
                    format="(###) ###-####"
                    mask="_"
                    onValueChange={(e) => {
                      Object.defineProperty(e, 'target', {
                        value: {
                          name: 'phone',
                          value: e.value,
                        },
                      });

                      setContactDetails(e);
                    }}
                    name="phone"
                    value={initialValues?.phone}
                    onBlur={async (e) => {
                      phoneNumberDuplicationCheckFunc(e?.target?.value, setFormErrors, 'phone_number');
                    }}
                  />
                  {formErrors?.phone_number && <div className="text-danger">{formErrors?.phone_number}</div>}
                  {errors.phone && touched.phone ? <div className="text-danger small">{errors.phone}</div> : null}
                </div>
              </div>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-12 col-md-4">
                  <label className="p-medium">Fax Number</label>
                </div>
                <div className="col-12 col-md-8">
                  <Field
                    id="fax_number"
                    name="fax_number"
                    value={initialValues.fax_number}
                    className="input-shadow w-100 border-0 p-2"
                    onChange={setContactDetails}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-12 col-lg-4 p-medium"></div>
                <div className="col-12 col-lg-8">
                  <div className="d-flex">
                    <InputSwitch
                      className="input-swich"
                      checked={initialValues?.is_client_opt_in}
                      name={'is_client_opt_in'}
                      onChange={setContactDetails}
                    />
                    <div className="F-size12 ms-2 d-flex align-items-center black-600">Client has opted in to recieve text messages</div>
                  </div>
                </div>
              </div>

              {radioValue === 'business' && (
                <div className="d-flex align-items-center py-2 flex-wrap">
                  <div className="col-12 col-lg-4 mt-2">
                    <label className="p-medium">
                      Business Role<span style={{ color: 'red' }}>*</span>
                    </label>
                  </div>
                  <div className="col-12 col-lg-8 col-sm-offset-1">
                    <Dropdown
                      className="w-100"
                      value={initialValues?.role}
                      name="role"
                      options={businessRoles}
                      onChange={(e) => {
                        setContactDetails(e);
                      }}
                      filter
                      //showClear={(initialValues?.role ==="" )? false : true}
                      // placeholder="Role
                    />
                    {errors.role && touched.role && !initialValues.role ? <div className="text-danger small">{errors.role}</div> : null}
                  </div>
                </div>
              )}

              {radioValue === 'person' && (
                <div className="d-flex align-items-center py-2 flex-wrap">
                  <div className="col-12 col-md-4">
                    <label className="p-medium">
                      Role
                      {radioValue === 'person' && <span style={{ color: 'red' }}>*</span>}
                    </label>
                  </div>
                  <div className="col-12 col-md-8">
                    <Dropdown
                      // value={clientRole}
                      value={initialValues?.role}
                      onChange={(e) => {
                        setContactDetails(e);
                        setClientRole(e.value);
                        setClientRoleError(false);
                      }}
                      name="role"
                      //options={!isFromLeadScreen ? roleList : leadRoles}
                      options={roleList}
                      optionLabel="relation_role"
                      optionValue="relation_role"
                      // placeholder="Select"
                      className="input-shadow input-height w-100 border-0"
                      filter
                      //showClear={(initialValues?.role ==="" )? false : true}
                    />
                    {/* {clientRoleError ? (
                          <div className="text-danger small">{"Required"}</div>
                        ) : null} */}

                    {errors.role && touched.role && !initialValues.role ? <div className="text-danger small">{errors.role}</div> : null}
                  </div>
                </div>
              )}

              {!isFromLeadScreen ? (
                <>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-12 col-md-4">
                      <label className="p-medium">Primary Contact</label>
                    </div>
                    <div className="col-12 col-md-8">
                      <InputSwitch
                        name="is_primary_contact"
                        checked={initialValues?.is_primary_contact}
                        onChange={(e) => {
                          // Object.defineProperty(e, "target", {
                          //   value: {
                          //     name: "is_primary_contact",
                          //     value: e.checked,
                          //   },
                          // });
                          setContactDetails(e);
                        }}
                        className="input-shadow input-height w-100 border-0 input-swich"
                      ></InputSwitch>
                    </div>
                  </div>

                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-12 col-md-4">
                      <label className="p-medium">Billable Contact</label>
                    </div>
                    <div className="col-12 col-md-8">
                      <InputSwitch
                        name="is_billing_contact"
                        checked={initialValues?.is_billing_contact}
                        onChange={(e) => {
                          setContactDetails(e);
                        }}
                        className="input-shadow input-height w-100 border-0 input-swich"
                      />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            {checkSubPermission('custom_fields') && (
              <>
                {(!props.isEdit || contactFieldsValue.length === 0) && (
                  <div className="row py-3">
                    <div className="col-12 col-sm-7 addfield">
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          setAddCustomField(!addCustomField);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <i className="icon-add"></i> <span>Add Custom Fields</span>
                      </span>
                    </div>
                  </div>
                )}
                {addCustomField && contactFields && contactFields.length
                  ? contactFields.map((val, index) => (
                      <div className="">
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-12 col-md-4 p-medium">{val.name}</div>
                          <div className="col-12 col-md-8">
                            {val.type === 'Text(short)' && (
                              <TextSnippetWrapper
                                type="text"
                                className="w-100 border-0 p-2 input-shadow"
                                name={val.name}
                                onChange={(e) => handleCustomFieldChange(e)}
                                value={
                                  contactFieldsValue.filter((index) => index.hasOwnProperty(val.name)).length
                                    ? contactFieldsValue.filter((index) => index.hasOwnProperty(val.name))[0][val.name]
                                    : ''
                                }
                              />
                            )}

                            {val.type === 'Text(long)' && (
                              <TextSnippetWrapper
                                type="textarea"
                                className="w-100 border-0 input-shadow p-2"
                                rows={5}
                                cols={30}
                                name={val.name}
                                onChange={(e) => handleCustomFieldChange(e)}
                                value={
                                  contactFieldsValue.filter((index) => index.hasOwnProperty(val.name)).length
                                    ? contactFieldsValue.filter((index) => index.hasOwnProperty(val.name))[0][val.name]
                                    : ''
                                }
                              />
                            )}

                            {val.type === 'Number' && (
                              <InputText
                                className="w-100 border-0 input-shadow input-height p-2"
                                name={val.name}
                                onChange={(e) => handleCustomFieldChange(e)}
                                // type="number"
                                keyfilter={'pnum'}
                                value={
                                  contactFieldsValue.filter((index) => index.hasOwnProperty(val.name)).length
                                    ? contactFieldsValue.filter((index) => index.hasOwnProperty(val.name))[0][val.name]
                                    : ''
                                }
                              />
                            )}

                            {val.type === 'Currency' && (
                              <InputText
                                className="w-100 input-shadow input-height border p-2"
                                name={val.name}
                                onChange={(e) => handleCustomFieldChange(e)}
                                // type="number"
                                keyfilter={'pnum'}
                                value={
                                  contactFieldsValue.filter((index) => index.hasOwnProperty(val.name)).length
                                    ? contactFieldsValue.filter((index) => index.hasOwnProperty(val.name))[0][val.name]
                                    : ''
                                }
                              />
                            )}

                            {/* {val.type === "Date" && (
                            <input
                              className="w-100 rounded border p-2"
                              name={val.name}
                              onChange={(e) => handleCustomFieldChange(e)}
                              type="date"
                              value={
                                contactFieldsValue.filter((index) =>
                                  index.hasOwnProperty(val.name)
                                ).length
                                  ? contactFieldsValue.filter((index) =>
                                      index.hasOwnProperty(val.name)
                                    )[0][val.name]
                                  : ""
                              }
                            />
                          )} */}

                            {val.type === 'Date' && (
                              <Calendar
                                monthNavigator
                                yearNavigator
                                yearRange="1990:2050"
                                showButtonBar
                                className="w-100 input-shadow input-height"
                                name={val.name}
                                onChange={(e) => handleCustomFieldChange(e)}
                                value={
                                  contactFieldsValue.filter((index) => index.hasOwnProperty(val.name)).length
                                    ? contactFieldsValue.filter((index) => index.hasOwnProperty(val.name))[0][val.name]
                                    : ''
                                }
                              />
                            )}

                            {val.type === 'List' && (
                              <TextSnippetWrapper
                                type="text"
                                className="w-100 border-0 input-shadow input-height p-2"
                                name={val.name}
                                onChange={(e) => handleCustomFieldChange(e)}
                                value={
                                  contactFieldsValue.filter((index) => index.hasOwnProperty(val.name)).length
                                    ? contactFieldsValue.filter((index) => index.hasOwnProperty(val.name))[0][val.name]
                                    : ''
                                }
                              />
                            )}
                            {val.type === 'Boolean' && (
                              <InputSwitch
                                className="input-shadow input-height w-100 border-0 input-swich"
                                name={val.name}
                                onChange={(e) => handleCustomFieldChange(e)}
                                checked={
                                  contactFieldsValue.filter((index) => index.hasOwnProperty(val.name)).length
                                    ? contactFieldsValue.filter((index) => index.hasOwnProperty(val.name))[0][val.name]
                                    : ''
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </>
            )}

            <div className="footer-btn">
              <Button label="Cancel" type="button" className="p-button-secondary outline" onClick={() => handleClose()} />{' '}
              <Button type="submit" label="Save" className="p-button p-component p-button p-button-secondary ms-2" />
            </div>

            {/* <div className="w-100">
                <div className="border-top pt-3">
                  <Button type="submit" className="p-button p-button-secondary">
                    {" "}
                    Save
                  </Button>
                </div>
              </div> */}
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
