import React from 'react';

import Dropzone from 'react-dropzone';
import { Controller } from 'react-hook-form';

import LabelName from 'components/UI/LabelName/LabelName';

import { downloadAttachments } from 'services/calendarServices';

function CourtEventAttachments({ control, setValue, getValues, handleUploadAttachements, uploadedAttachments }) {
  const removeFile = (index) => {
    const updatedFiles = [...getValues('attachments')];
    updatedFiles.splice(index, 1);
    setValue('attachments', updatedFiles);
  };

  function deleteUploadedFile(index) {
    let file = uploadedAttachments[index];
    const updatedItems = uploadedAttachments.filter((item) => item !== file);
    handleUploadAttachements(updatedItems);
  }

  const onClickAttachments = (e, fileInfo) => {
    e.preventDefault();
    downloadAttachments(fileInfo)
      .then((data) => {
        let link = document.createElement('a');
        link.download = fileInfo.display_name;
        let url = window.URL.createObjectURL(data.Body);
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="d-flex py-2 flex-wrap">
        <div className="col-md-4 col-12 mt-2">
          <LabelName required={false} htmlFor="attachments">
            Attachments
          </LabelName>
        </div>
        <div className="col-md-8 col-12">
          <Controller
            name="attachments"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <div>
                <Dropzone
                  multiple={true}
                  onDrop={(acceptedFiles) => {
                    const newFiles = [...field.value, ...acceptedFiles];
                    setValue('attachments', newFiles);
                  }}
                  accept="application/pdf, application/msword, .docx, .doc, audio/*, video/*,image/*"
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className="text-center font-bold attachment-input input-shadow mb-0">
                          Click or drag files to this area to upload
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
                <ul className="ps-0">
                  {field.value &&
                    field.value.length > 0 &&
                    field.value.map((file, index) => (
                      <div key={index} className="my-2 d-flex">
                        <i className="fas fa-paperclip me-2 mt-1"></i>
                        <span className="link-dark me-2">{file.name}</span>
                        <i onClick={() => removeFile(index)} className="icon-delete ms-2 mt-1 pointer" style={{ cursor: 'pointer' }} />
                      </div>
                    ))}
                  {uploadedAttachments &&
                    uploadedAttachments.length > 0 &&
                    uploadedAttachments.map((file, index) => (
                      <div key={index} className="my-2 d-flex text-break">
                        <div className=" d-flex">
                          <i className="fas fa-paperclip me-2 mt-1"></i>
                          <span
                            onClick={(e) => {
                              onClickAttachments(e, file);
                            }}
                            className="link-primary pointer me-2"
                          >
                            {file.file_name}
                          </span>{' '}
                        </div>
                        <i
                          className="icon-delete icon-red pointer mt-1"
                          onClick={() => {
                            deleteUploadedFile(index);
                          }}
                        ></i>
                      </div>
                    ))}
                </ul>
              </div>
            )}
          />
        </div>
      </div>
    </>
  );
}

export default CourtEventAttachments;
