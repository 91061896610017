import React, { useState, useEffect, useRef } from 'react';
import { Avatar } from 'primereact/avatar';
import { useHistory } from 'react-router-dom';
import { Storage } from 'aws-amplify';

import MessageBox from './MessageBox';
import CaseLeadContainer from '../CaseLeadContainer/CaseLeadContainer';
import ImageComponent from 'shared/ImageComponent';
import { listCasesForUser } from 'services/CaseServices';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { toastConstant } from 'constants/toastmessage';
import { useToast } from 'context/ToastContext';
import { handleRedirection } from 'utils/utils';
import { FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL, SetS3Config } from 'config/amplify_config';
import { uuidv4 } from 'common/uuidGenerator';
import { listMessageHistory, messageMarkRead, sendTextMessage } from 'services/messages/textMessageService';
import { useWebSocketContext } from 'context/WebSocketConnectionContext';
import { generateFullName } from 'utils/generateFullNameUtils';
import Cookies from 'universal-cookie';
import { useNavbarContext } from 'context/NavbarContext';
const cookie = new Cookies();

const tenantId = cookie.get('tenantId') || null;

const fileUpload = async (file, setUploadindStatus) => {
  const file_location = `text_messaging/${tenantId}/${uuidv4()}#$#${encodeURIComponent(file?.name)}`;
  SetS3Config(FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL);

  setUploadindStatus(true);
  let response = await Storage.put(file_location, file, {
    contentType: file?.type,
    useAccelerateEndpoint: true,
  }).catch(() => setUploadindStatus(false));
  setUploadindStatus(false);

  if (response.key) {
    return response.key;
  } else {
    return false;
  }
};

export default function MessageContainer(props) {
  const {
    isClosed,
    handleSelectThread,
    isFromCase,
    caseDetails,
    selectedThread,
    newThreadActive,
    navigateToLatest,
    setMessageThreads,
    onMountUnMount,
    onOperationBreak,
    fetchTextMessages,
    selectedContact,
    messageThreads,
    isFromLead,
  } = props;
  const history = useHistory();
  const { addToast } = useToast();
  const websocket = useWebSocketContext();
  const userContext = useUserDetailsContext();
  const listRef = useRef(null);

  const [caseLeadList, setCaseLeadList] = useState(null);
  const [file, setFile] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextToken, setNextToken] = useState(null);
  const [isClientOptIn, setIsClientOptIn] = useState(null);
  const [uploadindStatus, setUploadindStatus] = useState(false);
  const [isNewThread, setIsNewThread] = useState(false);

  const { notificationData } = useNavbarContext();

  useEffect(() => {
    if (websocket?.textMessageData?.body?.from) {
      if (
        selectedThread?.from_ === websocket?.textMessageData?.body?.from ||
        selectedThread?.to === websocket?.textMessageData.body?.from
      ) {
        const messageData = {
          from: websocket?.textMessageData?.body?.from,
          to: selectedThread?.to,
          body: websocket?.textMessageData?.body?.message_body,
          media_url: websocket?.textMessageData?.body?.media_url ?? websocket?.textMessageData?.body?.mms_path,
          MediaContentType0: websocket?.textMessageData?.body?.media_content_type ?? '',
          mms_path: websocket?.textMessageData?.body?.mms_path ?? '',
        };
        setMessages((preVal) => [...preVal, messageData]);
        setTimeout(() => {
          if (listRef.current) {
            listRef.current.scrollToItem(messages?.length, 'smart'); // Scroll to the bottom when new messages are loaded
          }
        }, 150);
        websocket.setTextMessageData();
      }
    }
    // delivery status Update Logic
    if (websocket?.textMessageData?.body?.SmsSid) {
      setMessages((preVal) =>
        preVal.map((message) => {
          if (message?.message_id === websocket?.textMessageData?.body?.SmsSid) {
            return { ...message, last_message_status: websocket?.textMessageData?.body?.SmsStatus };
          } else return message;
        })
      );
    }
  }, [websocket]);

  const fetchMessageHistory = (contactId, date, phoneNumber, _nextToken, unreadReplyCount, isRead) => {
    setLoading(true);

    if (unreadReplyCount > 0 || isRead === true) {
      messageMarkRead({ contact_id: contactId, phoneNumber })
        .then(() => {
          setMessageThreads((preval) =>
            preval.map((val) => {
              if (val.contact_id === contactId) {
                return { ...val, unread_reply_count: 0 };
              } else return val;
            })
          );

          //Fetching notification counts
          notificationData?.loadNotificationCount();
        })
        .catch((err) => console.log(err));
    }

    listMessageHistory({ contact_id: contactId, message_date: date, phoneNumber, limit: 10, nextToken: _nextToken })
      .then((response) => {
        setIsClientOptIn(response?.data?.is_client_opt_in);
        if (response?.data?.text_message_history?.length) {
          setNextToken(response?.data?.next_token);
          let reverseData = JSON.parse(JSON.stringify(response.data.text_message_history));
          let finalData = reverseData.reverse();
          setMessages((preVal) => [...finalData, ...preVal]);
          setTimeout(() => {
            if (listRef.current) {
              listRef.current.scrollToItem(finalData?.length - 1, 'smart'); // Scroll to the bottom when new messages are loaded
            }
          }, 150);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const sendMessage = async ({ message }) => {
    isFromCase && typeof onMountUnMount === 'function' && onMountUnMount('text-message', 'mounted');

    let sendData = {
      message_body: message,
      case_id: caseLeadList?.map((v) => v.case_id),
    };

    let file_path = '';
    let media_content_type = '';
    let display_filename = '';
    let size = 0;
    if (file) {
      let fileResponse = await fileUpload(file, setUploadindStatus);

      if (fileResponse) {
        file_path = `public/${fileResponse}`;
        media_content_type = file.type;
      }

      display_filename = file?.name || '';
      size = file?.size || 0;
    }

    if (isFromCase) {
      sendData.text_message_case_id = caseDetails?.case_id;
    }
    let timeout;

    await sendTextMessage({
      contact_id: selectedThread?.contact_id,
      text_message_thread_sk: isNewThread ? '' : selectedThread?.sk ? selectedThread?.sk?.replaceAll('#', '%23') : '',
      file_path,
      media_content_type,
      display_filename,
      size: size?.toString(),
      data: sendData,
      is_lead: isFromLead,
    })
      .then((response) => {
        if (response?.data?.status === 'Failed') {
          addToast(
            false,
            toastConstant.toasterType.ERROR,
            toastConstant.api.FAILED,
            'Failed to send the message. Please provide a valid phone number.'
          );
          isFromCase && typeof onOperationBreak === 'function' && onOperationBreak();
        } else {
          timeout = setTimeout(() => {
            isFromCase && typeof onMountUnMount === 'function' && onMountUnMount('text-message', 'unmounted');
          }, 3000);
          // add last message in list
          setMessages((preVal) => [...preVal, response?.data]);
          //update message in thread list
          if (isNewThread) {
            const newThread = {
              ...selectedThread,
              ...response?.data,
              last_message: response?.data?.body,
              last_updated: response?.data?.time,
              last_author: generateFullName(userContext?.profileData),
              is_active: true,
            };
            setMessageThreads((pre) => [newThread, ...pre]);
            setIsNewThread(false);
            handleSelectThread(newThread);
          } else {
            setMessageThreads((pre) => {
              return pre.map((item) => {
                if (item.sk === selectedThread?.sk) {
                  return {
                    ...item,
                    last_message: response?.data?.body,
                    last_updated: response?.data?.time,
                    last_message_status: 'queued',
                    message_id: response?.data?.message_id,
                    last_author: generateFullName(userContext?.profileData),
                  }; // Create a new object with updated name
                }
                return item; // Return the original item if ID doesn't match
              });
            });
          }
          messageThreads?.length === 0 && fetchTextMessages(null, selectedContact?.contact_id, false, true);
          notificationData?.loadNotificationCount();
          setTimeout(() => {
            if (listRef.current) {
              listRef.current.scrollToItem(messages?.length); // Scroll to the bottom when new messages are loaded
            }
          }, 50);
          setFile(null);
        }
      })
      .catch((error) => {
        clearTimeout(timeout);
        isFromCase && typeof onOperationBreak === 'function' && onOperationBreak();
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, 'Failed to send the message. ' + error?.response?.data);
      });
  };

  const loadCaseLeadList = (targetUserType, targetUserId, targetUserName) => {
    listCasesForUser(targetUserType, targetUserId, targetUserName, true, true)
      .then((response) => {
        setCaseLeadList(response.data);
      })
      .catch((err) => console.error(err));
  };

  const fileOnChange = async (e) => {
    const file = e?.target?.files?.[0] || e?.dataTransfer?.files?.[0];

    const fiveMb = 1048576 * 5;
    if (file?.size < fiveMb) {
      setFile(file);
    } else {
      addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, 'FIle size must be less than 5MB');
    }
  };

  useEffect(() => {
    if (selectedThread) {
      // console.log('selectedThread', selectedThread);
      setMessages([]);
      setFile(null);
      fetchMessageHistory(
        selectedThread?.contact_id,
        false,
        selectedThread?.to?.replace('+1', '')?.replace('+91', ''),
        '',
        selectedThread?.unread_reply_count,
        selectedThread?.is_read
      );
      loadCaseLeadList('contact', selectedThread?.contact_id, generateFullName(selectedThread?.client_name));
    }
  }, [selectedThread]);

  const removeFile = () => setFile(null);

  const createNewThread = (newNumber) => {
    setMessages([]);
    setIsNewThread(true);
    handleSelectThread({ ...selectedThread, is_active: true, to: newNumber });
  };

  return (
    <div className="border shadow-middle p-3 my-3 text-message-height">
      <div className="border-bottom">
        <div className="col-12">
          {Boolean(selectedThread) ? (
            <div
              className="d-flex align-items-center mb-2"
              onClick={() => {
                const route = selectedThread.contact_id ? `/contacts/${selectedThread.contact_id}` : '/contacts';
                handleRedirection(history, route);
              }}
            >
              <ImageComponent
                filePath={`${tenantId}/${selectedThread?.contact_id}/profile_image`}
                fileName={selectedThread?.client_name?.first_name?.charAt(0) ?? 'C'}
                style={{ backgroundColor: '#2196F3', color: '#ffffff' }}
                fileSize="medium.jpg"
              />
              <div className="flex-column ms-3">
                <h5 className="caption-bold mb-0 redirection-link">
                  {generateFullName(selectedThread?.client_name)} (
                  {userContext?.firmPhoneNumber === selectedThread.from_ ? selectedThread?.to : selectedThread?.from_})
                </h5>
                <div className="F-size12">Client</div>
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center mb-2">
              <Avatar size="large" />
            </div>
          )}
        </div>
      </div>

      <div className="row mt-2">
        <MessageBox
          {...{
            nextToken,
            fileOnChange,
            file,
            removeFile,
            uploadindStatus,
            isClosed,
            listRef,
            messages,
            loading,
            fetchMessageHistory,
            createNewThread,
            isNewThread,
            sendMessage,
            navigateToLatest,
            selectedThread,
            newThreadActive,
            caseLeadList,
            isClientOptIn,
          }}
        />
        <div className="col-sm-4 col-12 msg-case_details_sidebar">
          <CaseLeadContainer {...{ caseLeadList }} />
        </div>
      </div>
    </div>
  );
}
