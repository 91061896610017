import { createContext, useContext, useMemo, useState, useEffect } from 'react';

import { useToast } from 'context/ToastContext';
import { totalCountEvent } from 'services/generalServices';
import { listUsers } from 'services/teamManagement.service';
import {
  deleteLeadStage,
  getOfficeLocations,
  getVenueList,
  listBusinessRole,
  listLeadStages,
  listRelationRoles,
  updateLeadStage,
} from 'services/Settings';
import { listPaymentStructure } from 'services/billingServices';
import { getCaseMatter, updateLeadDetails } from 'services/lead/leadServices';
import { listContacts } from 'services/lead/leadServices';
import { listLeadReferrals, listLeadSource } from 'services/leadServices';
import { toastConstant } from 'constants/toastmessage';
import { UserDetailsContext } from 'context/userDetailsContext';
import { usePracticeAreaList } from 'hooks/usePracticeAreaList';

const LeadContext = createContext(() => {});
export const useLeadContext = () => useContext(LeadContext);

const LeadDataHandler = (props) => {
  const { addToast } = useToast();
  const userContext = useContext(UserDetailsContext);
  const [userList, setUserList] = useState([]);
  const [businessRole, setBusinessRole] = useState([]);
  const [relationRole, setRelationRole] = useState([]);
  const [paymentStructures, setPaymentStructures] = useState([]);
  const [leadStages, setLeadStages] = useState([]);
  const [caseMatterList, setCaseMatterList] = useState([]);
  const [officeLocationList, setOfficeLocationList] = useState([]);
  const [courtList, setCourtList] = useState([]);
  const [leadSourceList, setLeadSourceList] = useState([]);
  const [leadCount, setLeadCount] = useState(0);
  const [venueOptions, setVenueOptions] = useState([]); // to store venue list
  const [leadReferralsList, setLeadReferralsList] = useState([]);
  const [showLeadSettings, setShowLeadSettings] = useState(false);

  const { practiceAreaList: allPracticeArea } = usePracticeAreaList(true, 10, 'all');

  const practiceAreaList = useMemo(() => {
    return allPracticeArea?.filter((v) => v.status === 'ACTIVE');
  }, [allPracticeArea]);

  const loadUserList = () => {
    totalCountEvent('user')
      .then((response) => {
        listUsers('ACTIVE', response.data, '', 'forward', '', '', false, true, false)
          .then((response) => setUserList(response.data?.firm_users))
          .catch((err) => console.error(err));
      })
      .catch((error) => console.warn(error));
  };

  const loadTotalCount = () => {
    totalCountEvent('lead')
      .then((response) => {
        setLeadCount(response.data);
      })
      .catch((err) => {});
  };

  const loadBusinessRoles = () => {
    totalCountEvent('business_role').then((response) => {
      listBusinessRole(response.data).then((res) => {
        setBusinessRole(res.data?.business_role);
      });
    });
  };

  const loadCaseMatter = () => {
    totalCountEvent('case-matter')
      .then((response) => {
        getCaseMatter({ pageLimit: response.data })
          .then((res) => setCaseMatterList(res.data?.['case-matter'] || []))
          .catch((error) => console.error(error));
      })
      .catch((err) => console.log(err));
  };

  const loadOfficeLocation = () => {
    getOfficeLocations()
      .then((res) => setOfficeLocationList(res.data || []))
      .catch((error) => console.error(error));
  };

  const loadVenue = () => {
    totalCountEvent('venue').then((response) => {
      getVenueList(response.data, '', 'forward')
        .then((response) => {
          setVenueOptions(response.data.venues);
        })
        .catch((err) => {});
    });
  };

  const loadLeadStages = () => {
    const data = {
      practice_area: userContext?.userDetails?.user_preferences?.filters?.leads?.practice_area,
      show_unassigned_pa_column: userContext?.userDetails?.user_preferences?.filters?.leads?.show_unassigned_pa_column,
    };
    totalCountEvent('lead-stage')
      .then((response) => {
        listLeadStages(response.data, '', '', 'ACTIVE', data)
          .then((res) => setLeadStages(res.data?.lead_stage || []))
          .catch((error) => console.error(error));
      })
      .catch((err) => console.log(err));
  };

  const loadRelationRoles = () => {
    totalCountEvent('relation_role').then((response) => {
      listRelationRoles(response.data).then((res) => {
        setRelationRole(res.data?.relation_role);
      });
    });
  };

  const loadPaymentStructure = () => {
    listPaymentStructure()
      .then((response) => {
        if (response?.data?.payment_structures) {
          setPaymentStructures(response.data.payment_structures || []);
        }
      })
      .catch((err) => console.log(err));
  };

  const loadCourtContacts = () => {
    totalCountEvent('contact')
      .then((response) => {
        listContacts({ pageLimit: response.data, is_court_location: true })
          .then((response) => {
            if (response?.data?.contacts?.length > 0) {
              setCourtList(response.data.contacts);
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const loadLeadSources = () => {
    totalCountEvent('lead_source')
      .then((response) => {
        listLeadSource(response.data)
          .then((res) => {
            if (res.data?.lead_source?.length) {
              setLeadSourceList(res.data.lead_source);
            }
          })
          .catch((err) => {
            setLeadSourceList([]);
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  const loadLeadReferrals = () => {
    listLeadReferrals()
      .then((res) => setLeadReferralsList(res.data || []))
      .catch((error) => console.error(error));
  };

  const updateStageData = (stageData) => {
    if (stageData) {
      updateLeadStage({ ...stageData, is_lead: true })
        .then(() => {
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.STAGE_UPDATED_SUCCESSFULLY);
          setLeadStages((preVal) =>
            preVal?.map((v) => {
              if (v?.sk === stageData?.sk) {
                return { ...v, lead_stage_name: stageData?.lead_stage_name };
              } else return v;
            })
          );
        })
        .catch(() => {
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.STAGE_UPDATED_FAILED);
        });
    }
  };

  const deleteStage = (stageData) => {
    if (stageData) {
      deleteLeadStage(stageData?.pk, stageData?.sk, 'ARCHIVED')
        .then(() => {
          setLeadStages((preVal) => preVal?.filter((v) => v?.sk !== stageData?.sk));
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.STAGE_UPDATED_SUCCESSFULLY);
        })
        .catch((err) => {
          let msg = toastConstant.message.STAGE_UPDATED_FAILED;

          if (err?.response?.data) {
            msg = err?.response?.data;
          }
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, msg);
        });
    }
  };

  const updateLead = async (formData) => {
    const { case_description, lead_details, lead_stage, lead_status, sk } = formData || {};
    return updateLeadDetails(sk, {
      case_description,
      lead_details,
      lead_stage,
      lead_status,
    })
      .then(() => {
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.LEAD_UPDATE_SUCCESS);
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.LEAD_UPDATE_FAILURE);
      });
  };

  useEffect(() => {
    loadUserList();
    loadBusinessRoles();
    loadRelationRoles();
    loadPaymentStructure();
    loadLeadStages();
    loadCaseMatter();
    loadOfficeLocation();
    loadCourtContacts();
    loadLeadSources();
    loadTotalCount();
    loadVenue();
    loadLeadReferrals();
  }, []);

  const contextPayload = useMemo(
    () => ({
      userList,
      businessRole,
      relationRole,
      paymentStructures,
      leadStages,
      practiceAreaList,
      allPracticeArea,
      caseMatterList,
      officeLocationList,
      courtList,
      leadSourceList,
      leadCount,
      loadCourtContacts,
      loadLeadSources,
      deleteStage,
      updateStageData,
      updateLead,
      loadLeadStages,
      setLeadStages,
      loadTotalCount,
      venueOptions,
      loadVenue,
      loadLeadReferrals,
      leadReferralsList,
      showLeadSettings,
      setShowLeadSettings,
    }),
    [
      leadCount,
      leadSourceList,
      courtList,
      venueOptions,
      officeLocationList,
      caseMatterList,
      practiceAreaList,
      leadStages,
      userList,
      paymentStructures,
      relationRole,
      businessRole,
      leadReferralsList,
      showLeadSettings,
      setShowLeadSettings,
      setLeadStages,
    ] //eslint-disable-line
  );
  return <LeadContext.Provider value={contextPayload} {...props} />;
};

export default LeadDataHandler;
