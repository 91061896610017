import { useState, useEffect, memo, useCallback } from 'react';
import FileDownload from '../../ListView/Templates/ActionsTemplate/FileDownload';
import GetSignature from '../../ListView/Templates/ActionsTemplate/GetSignature';
// import useSpinner from 'hooks/useSpinner';

const PDFIframe = memo(({ url, onLoad, onError }) => {
  const handleError = (err) => {
    console.error('PDF loading error:', err);
    onError?.(err);
  };

  const viewerUrl = `${url}#toolbar=0`;

  return (
    <iframe
      key={viewerUrl}
      src={viewerUrl}
      className="file-viewer"
      width="1366px"
      height="623px"
      frameBorder="0"
      onLoad={onLoad}
      onError={handleError}
    />
  );
});

const PDFViewer = memo(({ document, item, isClientFileManager, onLoad }) => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  // const [spinner, showSpinner, hideSpinner] = useSpinner(true);

  // Validate document URL
  useEffect(() => {
    if (document) {
      try {
        new URL(document);
        console.log('Valid PDF URL');
      } catch (e) {
        console.error('Invalid PDF URL:', e);
        setLoadError('Invalid PDF URL');
        setIsLoading(false);
      }
    }
  }, [document]);

  // Fetch and prepare PDF URL
  useEffect(() => {
    if (document) {
      try {
        setIsLoading(true);
        setLoadError(null);
        setPdfUrl(document);
      } catch (error) {
        console.error('Error preparing PDF URL:', error);
        setLoadError('Failed to load PDF');
        setIsLoading(false);
      }
    }
  }, [document]);

  // Add timeout for loading
  useEffect(() => {
    let timeoutId;
    if (isLoading) {
      timeoutId = setTimeout(() => {
        handleRetry();
        setLoadError('Loading timeout - PDF might be too large or unavailable');
        setIsLoading(false);
      }, 10000); // 10 second timeout
    }
    return () => clearTimeout(timeoutId);
  }, [isLoading]);

  // Handle loading state
  // useEffect(() => {
  //     if (isLoading) {
  //         showSpinner();
  //     } else {
  //         hideSpinner();
  //     }
  // }, [isLoading, showSpinner, hideSpinner]);

  const handleRetry = useCallback(() => {
    if (retryCount < 3) {
      setIsLoading(true);
      setLoadError(null);
      setRetryCount((prev) => prev + 1);
    }
  }, [retryCount]);

  const handleIframeLoad = useCallback(() => {
    setIsLoading(false);
    setLoadError(null);
    setRetryCount(0);
    onLoad?.();
    console.log('PDF loaded');
  }, [onLoad]);

  const handleIframeError = useCallback(
    (error) => {
      console.error('PDF loading error:', error);
      setIsLoading(false);
      setLoadError('Failed to load PDF');
      handleRetry();
    },
    [handleRetry]
  );

  const handleManualRetry = useCallback(() => {
    setRetryCount(0);
    handleRetry();
  }, [handleRetry]);

  if (!document) return null;

  return (
    <div className="h-100">
      {!isClientFileManager && item && <GetSignature rowData={item} />}
      {item && (
        <FileDownload
          rowData={item}
          isFromFilePreviewModal={true}
          filePreviewModalIconClassName="icon-save p-2 pointer float-end h2 mb-0"
        />
      )}
      {loadError && retryCount >= 3 && (
        <div className="alert alert-danger">
          {loadError}
          <button className="btn btn-link" onClick={handleManualRetry}>
            Try again
          </button>
        </div>
      )}
      {pdfUrl && !loadError && <PDFIframe url={pdfUrl} onLoad={handleIframeLoad} onError={handleIframeError} />}
    </div>
  );
});

export default PDFViewer;
