import { useEffect, useState } from 'react';

import { Dropdown } from 'primereact/dropdown';

import { listUsers } from 'services/calendarServices';
import { getActivityLogs } from 'services/activitylogServices';

import { getTimeStampEmbeddedDate } from 'utils/utility_functions/formatTimeAndDate';
import { addDays, subtractDays } from 'utils/timeDateUtils';
import { generateFullName } from 'utils/generateFullNameUtils';

import ActivityLogTable from './ActivityLogTable';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';

const ActivityLog = (props) => {
  const [activityLog, setActivityLog] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [selectedUserList, setSelectedUserList] = useState(null);
  const [endDate, setEndDate] = useState(new Date());

  const [currentDateToDisplay, setCurrentDateToDisplay] = useState({
    start: new Date(),
    end: new Date(),
  });

  const isCaseSpecific = props?.isCaseSpecific ?? false;
  const isFromTeamManagement = props?.isFromTeamManagement ?? false;
  const isFromDashBoard = props?.isFromDashBoard ?? false;
  const isFromReports = props?.isFromReports ?? false;
  const userId = props?.userDetails?.client_cognito_username;

  let todayDate = new Date();

  useEffect(() => {
    loadUsers();
  }, []); // eslint-disable-line

  const loadUsers = (case_id = props?.caseDetails?.case_id) => {
    listUsers(case_id)
      .then((response) => setUsersList(response.data))
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDataOfActivityLog('initial');
  }, [selectedUserList]);

  const fetchActivityLogData = (backward = false, forward = false) => {
    if (backward) {
      getDataOfActivityLog('backward');
    } else if (forward) {
      let currentDate = new Date();

      if (endDate.toDateString() !== currentDate.toDateString()) {
        getDataOfActivityLog('forward');
      }
    }
  };

  function getDataOfActivityLog(type) {
    let tempStartDate = new Date();
    let tempEndDate = new Date();

    let tempFormattedStartDate = new Date();
    let tempFormattedEndDate = new Date();

    if (type === 'forward') {
      tempStartDate = addDays(endDate, 1);
      tempEndDate = addDays(endDate, 2);
    } else if (type === 'backward') {
      tempStartDate = subtractDays(endDate, 3);
      tempEndDate = subtractDays(endDate, 2);
    } else if (type === 'initial') {
      tempStartDate = subtractDays(endDate, 1);
    }

    tempFormattedStartDate = getTimeStampEmbeddedDate(tempStartDate, false, true);
    tempFormattedEndDate = getTimeStampEmbeddedDate(tempEndDate, false, true);

    setEndDate(tempEndDate);
    setCurrentDateToDisplay({ start: tempStartDate, end: tempEndDate });
    setActivityLog([]);

    let data = {
      key: '',
      status: '',
      type: '',
      sub_type: '',
      user_id: isFromTeamManagement ? userId : selectedUserList?.client_cognito_username ? selectedUserList?.client_cognito_username : '',
      case_id: isCaseSpecific ? props?.caseDetails?.case_id : '',
      start_timestamp: tempFormattedStartDate,
      end_timestamp: tempFormattedEndDate,
      pagination_limit: 1000,
    };

    getActivityLogData(data);
  }

  function getActivityLogData(data) {
    getActivityLogs(data)
      .then((response) => {
        setActivityLog([...response.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const assignToItemTemplate = (option) => {
    return (
      <>
        {option?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
        {generateFullName(option)}
      </>
    );
  };

  return (
    <div>
      {!isFromDashBoard && <GlobalLoader />}
      <div className="activity-log-wrap bg-white">
        {!isFromTeamManagement && !isFromReports && <h5 className="F-size14 mb-3 black-600 text-bold">Activity Log</h5>}
        {isFromReports && <h5 className="F-size14 mb-3 black-600 text-bold">Activity Log</h5>}
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div className="d-flex align-items-center">
            <div className="me-3 black-800 text-bold">
              {currentDateToDisplay.end.toDateString().split(' ')[1]} {currentDateToDisplay.start.toDateString().split(' ')[2]}-
              {currentDateToDisplay.end.toDateString().split(' ')[2]}
            </div>

            <div
              className="rounded me-1 pointer slider-ctrl"
              onClick={(e) => {
                fetchActivityLogData(true, false);
              }}
            >
              <i className="pi pi-angle-left p-1"></i>
            </div>
            <div
              className={
                currentDateToDisplay.end.toDateString() === todayDate.toDateString()
                  ? 'rounded ms-1 pointer slider-ctrl disabled-element '
                  : 'rounded ms-1 pointer slider-ctrl'
              }
              onClick={(e) => fetchActivityLogData(false, true)}
            >
              <i className="pi pi-angle-right p-1"></i>
            </div>
          </div>
          {!isFromTeamManagement && (
            <div className="d-flex flex-column my-2  datatable-responsive">
              <Dropdown
                placeholder={props?.caseDetails?.case_id ? 'By Case User' : 'By User'}
                optionLabel={(v) => generateFullName(v)}
                onChange={(e) => setSelectedUserList(e.value)}
                options={usersList}
                value={selectedUserList}
                className="mx-1 p-datatable-responsive"
                filter
                showClear
                style={{ minWidth: 160 }}
                itemTemplate={assignToItemTemplate}
              />
            </div>
          )}
        </div>
        <ActivityLogTable
          activityLog={activityLog}
          isCaseSpecific={isCaseSpecific}
          isLead={props?.isLead}
          isFromDashBoard={props?.isFromDashBoard}
        />
      </div>
    </div>
  );
};

export default ActivityLog;
