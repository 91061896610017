import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button } from 'primereact/button';

import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import { createContact } from 'services/contact';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

function AddLocationForm({ setAddLocation, setValue, hadleAddNewLocation, locationsList, courtEventExtractedData }) {
  const {
    control: control2,
    resetField: resetField2,
    reset,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm();

  const { addToast } = useToast();

  useEffect(() => {
    if (
      !locationsList.some((obj) => obj.value === courtEventExtractedData?.details?.court) &&
      courtEventExtractedData?.details?.court !== -1
    ) {
      reset({ newLocation: courtEventExtractedData?.details?.court });
    }
  }, [courtEventExtractedData]);

  const handleAddLocation = (data) => {
    let obj = {
      contact_type: 'location',
      title: data.newLocation,
      email: '',
      phone: '',
      country: 'United States',
      country_code: '+1',
    };
    addNewLocation(obj);
    setAddLocation(false);
    resetField2('newLocation');
    setValue('location', data.newLocation);
  };

  function addNewLocation(data) {
    createContact(data)
      .then((response) => {
        hadleAddNewLocation(data);
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.LOCATION_CREATE_SUCCESS);
      })
      .catch((err) => {
        console.log(err);
        addToast(false, toastConstant.toasterType.INFO, toastConstant.api.FAILED, toastConstant.message.LOCATION_CREATE_FAILURE);
      });
  }

  return (
    <div>
      <div className="p-inputgroup align-items-center">
        <Controller
          name="newLocation"
          control={control2}
          rules={{ required: true }}
          defaultValue=""
          render={({ field }) => (
            <TextSnippetWrapper type="text" id={field.name} {...field} className="input-shadow me-2" placeholder="Add new location" />
          )}
        />
        <Button
          icon="pi pi-times"
          className="p-button-secondary outline me-1"
          type="button"
          onClick={() => {
            setAddLocation(false);
            resetField2('newLocation');
          }}
        />
        <Button icon="pi pi-check" className="p-button-secondary outline" type="button" onClick={handleSubmit2(handleAddLocation)} />
      </div>
      <div>{errors2?.newLocation && <InputErrorMessage>Enter valid location</InputErrorMessage>}</div>
    </div>
  );
}

export default AddLocationForm;
