import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { closeLead, saveLeadCloseReason, viewLeadClosureReasons } from 'services/lead/leadServices';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { useToggle } from 'hooks/useToggle';
import { createLeadReferral, listLeadReferrals } from 'services/leadServices';

export function useCloseLead(leadDetails, onHide) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm();
  const { addToast } = useToast();
  const [reasonList, setReasonList] = useState([]);
  const [referralsList, setReferralsList] = useState([]);
  const [showCustomReason, setShowCustomReason] = useToggle();
  const [showReferredTo, setShowReferredTo] = useToggle();
  const [showCustomReferral, setShowCustomReferral] = useToggle();
  const [loading, setLoading] = useToggle();
  const [saveLoading, setSaveLoading] = useToggle();

  useEffect(() => {
    loadCloseReasons();
    loadLeadReferrals();
  }, []);

  const loadCloseReasons = () => {
    setLoading(true);
    viewLeadClosureReasons(true)
      .then((response) => {
        setReasonList(response.data ?? []);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addCloseLeadReason = (formData) => {
    setSaveLoading(true);
    const leadCloseReason = formData?.new_reason || formData?.old_reason;
    const dataToSent = {
      is_open: false,
      previous_lead_stage: leadDetails?.lead_stage,
      lead_close_reason: leadCloseReason,
      referred_to: leadCloseReason === 'Referred Out' ? formData?.old_referred_to || formData?.referred_to : null,
      is_qualified: formData?.is_qualified,
    };

    const saveReasonPromise = formData?.new_reason
      ? saveLeadCloseReason(leadDetails?.case_id, { reason: formData?.new_reason }, true)
      : Promise.resolve();

    const saveReferralPromise =
      leadCloseReason === 'Referred Out' && formData?.referred_to
        ? createLeadReferral(true, { referred_to: formData?.referred_to })
        : Promise.resolve();

    const promiseList = [saveReasonPromise, saveReferralPromise];
    Promise.all(promiseList)
      .then(() => handleCloseLead(dataToSent))
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const loadLeadReferrals = () => {
    setLoading(true);
    listLeadReferrals()
      .then((response) => {
        setReferralsList(response.data ?? []);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseLead = (dataToSent) => {
    setSaveLoading(true);
    closeLead(leadDetails?.sk, dataToSent)
      .then(() => {
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.LEAD_CLOSE_SUCCESS);
        onHide('closed');
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.LEAD_CLOSE_FAILURE);
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  return {
    handleSubmit,
    control,
    register,
    reasonList,
    showCustomReason,
    loading,
    saveLoading,
    addCloseLeadReason,
    handleCloseLead,
    errors,
    setShowCustomReason,
    showReferredTo,
    setShowReferredTo,
    showCustomReferral,
    setShowCustomReferral,
    referralsList,
  };
}
