import React, { useState, useEffect, useContext } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { listInvoice, deleteInvoice } from 'services/billingServices';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { format } from 'date-fns';
import _ from 'lodash';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { RecordPayment } from './RecordPayment';
import { listAccounts } from 'redux/actions/AccountListAction';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SendNotificationMail } from './SendNotificationMail';
import { Reminders } from './Reminders';
import { MultiSelect } from 'primereact/multiselect';
import { totalCountEvent } from 'services/generalServices';
import { Dropdown } from 'primereact/dropdown';
import { formatNumbersToCurrencyString, openLinkInBlank, paginationDropdownOptions } from 'utils/utils';
import { Tooltip } from 'primereact/tooltip';
import CreateInvoice from './CreateInvoice/CreateInvoice';
import { UserDetailsContext } from 'context/userDetailsContext';
import { handlePageNumberInPagination } from 'common/pagination';
import { getLocalStorageParseItem, removeLocalStorageItem, setLocalStorageItem } from 'utils/localStorageUtils';
import { calculateDaysPastDue, convertToTimezoneFormat, handleDateTimeOffset } from 'utils/utility_functions/timezone';
import moment from 'moment';
import { tableCell } from 'modules/reports/components/TableCell/TableCell';
import constants from 'constants/index';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { useFirmUserList } from 'hooks/useFirmUserList';
import { generateFullName } from 'utils/generateFullNameUtils';

export const Invoices = (props) => {
  const { isFromCaseSpecificModule, getCaseDetails, getTimeEntrySummarty, isFromGlobalBilling, handleInvoiceTableRedirection } =
    props || {};
  const [searchKey, setSearchKey] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [showCreateInvoice, setShowCreateInvoice] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [viewInvoice, setViewInvoice] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { addToast } = useToast();
  const [showRecordModal, setShowRecordModal] = useState(false);
  const [confirmSendMail, setConfirmSendMail] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const userContext = useContext(UserDetailsContext);
  const [showReminder, setShowReminder] = useState(false);
  const [selectedPractice, setSelectedPractice] = useState(null);
  const [pageLimit, setPageLimit] = useState(getPageLimit() ?? 10);
  const [isForward, setIsForward] = useState(true);
  const next_Token = invoiceList.next_token;
  const transition_Token = invoiceList.transition_token;
  const practiceData = useSelector((state) => state.practiceArea.practiceAreaList['practice-area']);
  const [practiceAreaOptions, setPracticeAreaOptions] = useState([]);
  const [selectedLeadAttorneyList, setSelectedLeadAttorneyList] = useState(null);
  const { userList } = useFirmUserList();

  function getPageLimit() {
    if (props.isLeadSpecific && userContext) {
      return userContext?.firmDetails?.pagination_preference?.leads?.billings?.invoices;
    } else if (isFromCaseSpecificModule && userContext) {
      return userContext?.firmDetails?.pagination_preference?.case?.billings?.invoices;
    } else if (userContext) {
      return userContext?.firmDetails?.pagination_preference?.billings?.invoices;
    }
  }

  const loadInvoices = (filters, pageLimit = getPageLimit() ?? 10, pagination_token = '', pagination_direction = 'forward') => {
    if (props.caseView) {
      filters.case_id = props?.caseDetails?.case_id;
    }
    listInvoice(filters, pageLimit, pagination_token, pagination_direction)
      .then((response) => {
        if (response?.data) {
          setInvoiceList(response.data);
        }
      })
      .catch((err) => {});
  };

  function getFilePathForPagination() {
    if (props.isLeadSpecific && userContext) {
      return { module: 'leads', subModule: 'billings', listName: 'invoices' };
    } else if (isFromCaseSpecificModule && userContext) {
      return { module: 'case', subModule: 'billings', listName: 'invoices' };
    } else {
      return { module: 'billings', subModule: '', listName: 'invoices' };
    }
  }

  const onChangePractice = (e) => {
    setSelectedPractice([...e.value]);
    let filters = {
      ...pickExtractedDate(dateFilter),
      invoice_status: '',
      search_key: searchKey,
      case_id: '',
      practice_area: [...e.value],
    };
    loadInvoices(filters);
  };

  const onChangeSearch = (e) => {
    setSearchKey(e.target.value);
    if (!e.target.value) {
      let filters = {
        ...pickExtractedDate(dateFilter),
        invoice_status: '',
        search_key: '',
        case_id: '',
        practice_area: selectedPractice,
      };
      loadInvoices(filters);
    }
  };
  const onSearchKeyDown = (e) => {
    let code = e.keyCode ? e.keyCode : e.which;
    if (code === 13) {
      let filters = {
        ...pickExtractedDate(dateFilter),
        invoice_status: '',
        search_key: searchKey,
        case_id: '',
        practice_area: selectedPractice,
      };
      loadInvoices(filters);
    }
  };

  const pickExtractedDate = (dateArray) => {
    return {
      invoice_date_lower: dateArray?.[0]
        ? convertToTimezoneFormat(moment(dateArray[0]).startOf('day'), userContext?.userDetails.timezone)
        : '',
      invoice_date_upper: dateArray?.[0]
        ? convertToTimezoneFormat(moment(dateArray[1] ? dateArray[1] : dateArray[0]).endOf('day'), userContext?.userDetails.timezone)
        : '',
    };
  };

  const onChangeCalendar = (e) => {
    setDateFilter(e.value);

    let filters = {
      ...pickExtractedDate(e.value),
      invoice_status: '',
      search_key: searchKey,
      case_id: '',
      practice_area: selectedPractice,
    };
    loadInvoices(filters);
  };

  const onChangeLeadAttorney = (e) => {
    const list = e.value !== null ? [...e.value] : null;
    setSelectedLeadAttorneyList(list);

    let filters = {
      ...pickExtractedDate(dateFilter),
      invoice_status: '',
      search_key: searchKey,
      case_id: '',
      practice_area: selectedPractice,
      lead_attorney_list: list,
    };
    loadInvoices(filters);
  };

  const caseBody = (rowData, ...rest) => {
    const { is_lead } = rowData;
    const mainModuleName = is_lead ? '/leads' : '/cases';
    let route = rowData?.invoice?.case_id ? `${mainModuleName}/${rowData?.invoice?.case_id}` : mainModuleName;

    return (
      <React.Fragment>
        <span className="p-column-title text-break">Case</span>
        <span
          onClick={(e) => {
            handleInvoiceTableRedirection(e, rowData, route);
          }}
          // className="text-break pointer F-size14 text-bold call-back text-decoration-none"
          className="redirection-link-no-text-transform"
          title={rowData?.invoice?.case_name ? rowData.invoice?.case_name : undefined}
        >
          {rowData?.invoice?.case_name ? rowData.invoice?.case_name : '-'}
        </span>
      </React.Fragment>
    );
  };

  const onSuccess = () => {
    setShowCreateInvoice(false);
    setShowRecordModal(false);
    let filters = {
      ...pickExtractedDate(dateFilter),
      invoice_status: '',
      search_key: searchKey,
      case_id: '',
      practice_area: selectedPractice,
    };
    loadInvoices(filters);
    typeof getTimeEntrySummarty === 'function' && getTimeEntrySummarty();
    if (
      (props?.caseDetails && !props?.caseDetails?.default_trust_account?.[0]?.account_id) ||
      !props?.caseDetails?.default_operating_account?.[0]?.account_id
    ) {
      typeof getCaseDetails === 'function' && getCaseDetails();
    }
  };
  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown PrevPageLink NextPageLink',
    RowsPerPageDropdown: (options) => {
      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Item Per Page
          </span>
          <Dropdown value={options.value} options={paginationDropdownOptions} onChange={onPageChange} />
        </React.Fragment>
      );
    },
    NextPageLink: ({ iconClassName }) => {
      return (
        <Button
          onClick={onNextPageClick}
          disabled={next_Token === null && isForward}
          className={'p-paginator-next p-paginator-element p-link'}
          icon={iconClassName}
        />
      );
    },
    PrevPageLink: ({ iconClassName }) => {
      return (
        <Button
          onClick={onPrevPageClick}
          disabled={transition_Token === null || (transition_Token && next_Token === null && !isForward)}
          className={'p-paginator-next p-paginator-element p-link'}
          icon={iconClassName}
        />
      );
    },
  };
  let filters = {
    ...pickExtractedDate(dateFilter),
    invoice_status: '',
    search_key: searchKey,
    case_id: '',
    practice_area: selectedPractice,
  };

  const onNextPageClick = () => {
    loadInvoices(filters, pageLimit, !isForward ? transition_Token : next_Token, 'forward');
    setIsForward(true);
  };
  const onPrevPageClick = () => {
    loadInvoices(filters, pageLimit, isForward ? transition_Token : next_Token, 'reverse');
    setIsForward(false);
    //setPreviousToken(transition_Token);
  };
  const onPageChange = (event) => {
    setPageLimit(event.value);
    let path = getFilePathForPagination();
    handlePageNumberInPagination(userContext, path?.module, path?.subModule, path?.listName, event.value);
    loadInvoices(filters, event.value, '', 'forward');
  };

  const refreshCase = () => {
    setSearchKey('');
    setDateFilter('');
    setSelectedInvoice('');
    setSelectedPractice();
    let filters = {
      invoice_date_upper: '',
      invoice_date_lower: '',
      invoice_status: '',
      search_key: '',
      case_id: '',
      practice_area: '',
    };
    loadInvoices(filters);
  };

  const actionBody = (rowData) => {
    let due_amount = rowData?.due_amount;
    due_amount = due_amount ? parseInt(due_amount) : 0;

    const onClickViewInvoiceDetailsLink = (e) => {
      e.preventDefault();

      const isLead = rowData?.invoice?.is_lead;
      const caseId = rowData?.invoice?.case_id;

      const mainModuleName = isLead ? '/leads' : '/cases';
      const route = caseId ? `${mainModuleName}/${caseId}/billing` : mainModuleName;
      const stateToPass = { invoiceDetails: rowData };

      setLocalStorageItem('viewInvoice', true);

      if (!openLinkInBlank(route, stateToPass)) {
        setSelectedInvoice({ ...rowData });
        setViewInvoice(true);
        setShowCreateInvoice(true);
      }
    };

    return (
      <React.Fragment>
        <span className="p-column-title text-break">Action</span>
        <span
          role="button"
          onClick={(e) => {
            onClickViewInvoiceDetailsLink(e);

            // cases/6d70e75e-331b-4799-8cf4-f07bf84109b5/billing
          }}
          className="text-primary-dark"
        >
          {' '}
          View
        </span>
        {due_amount > 0 && (
          <span
            role="button"
            onClick={(e) => {
              setSelectedInvoice({ ...rowData });
              setConfirmSendMail(true);
            }}
            className={props.isCaseClosed || (props.isLeadSpecific && props.isLeadClosed) ? 'pe-none opacity-50' : ''}
          >
            <i className="icon-send-form ms-2" id="payReq">
              <Tooltip target={'#payReq'} position={'bottom'} className="case-tooltip mb-3">
                Payment Request
              </Tooltip>
            </i>
          </span>
        )}
        {due_amount > 0 && (
          <span role="button" className={props.isCaseClosed || (props.isLeadSpecific && props.isLeadClosed) ? 'pe-none opacity-50' : ''}>
            <i
              className="icon-radio ms-2"
              onClick={(e) => {
                setSelectedInvoice({ ...rowData });
                setShowRecordModal(true);
              }}
              id="record"
            >
              <Tooltip target={'#record'} position={'bottom'} className="case-tooltip mb-3">
                Record Payment
              </Tooltip>
            </i>
          </span>
        )}
        <span
          role="button"
          onClick={(e) => {
            setSelectedInvoice({ ...rowData });
            setShowReminder(true);
          }}
          className={props.isCaseClosed || (props.isLeadSpecific && props.isLeadClosed) ? 'pe-none opacity-50' : ''}
        >
          <i className="icon-notification ms-2" id="rem">
            <Tooltip target={'#rem'} position={'bottom'} className="case-tooltip mb-3">
              Reminder
            </Tooltip>
          </i>
        </span>
        <span role="button" className={props.isCaseClosed || (props.isLeadSpecific && props.isLeadClosed) ? 'pe-none opacity-50' : ''}>
          <i
            className="icon-delete cursor-pointer ms-2"
            onClick={() => {
              setSelectedInvoice({ ...rowData });
              setConfirmDelete(true);
            }}
            id="delete"
          >
            <Tooltip target={'#delete'} position={'bottom'} className="case-tooltip mb-3">
              Delete
            </Tooltip>
          </i>
        </span>
      </React.Fragment>
    );
  };
  const onCreateSuccess = () => {
    setShowReminder(false);
    let filters = {
      ...pickExtractedDate(dateFilter),
      invoice_status: '',
      search_key: searchKey,
      case_id: '',
      practice_area: selectedPractice,
    };
    loadInvoices(filters);
    typeof getTimeEntrySummarty === 'function' && getTimeEntrySummarty();
  };

  const onDeleteInvoice = () => {
    let sk = selectedInvoice?.sk;
    deleteInvoice(sk, props?.isLeadSpecific ? props?.isLeadSpecific : selectedInvoice?.is_lead)
      .then((response) => {
        let filters = {
          ...pickExtractedDate(dateFilter),
          invoice_status: '',
          search_key: searchKey,
          case_id: '',
          practice_area: selectedPractice,
        };
        loadInvoices(filters);
        typeof getTimeEntrySummarty === 'function' && getTimeEntrySummarty();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.DELETE_INVOICE_SUCCESS);
      })
      .catch((err) => {
        let msg = toastConstant.message.DELETE_INVOICE_FAILED;
        if (err && err?.response?.data?.message) {
          msg = err.response.data.message;
        }
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, msg);
      });
  };

  const dueDateBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Sent Date</span>
        <span
          className="text-break"
          title={handleDateTimeOffset(userContext?.userDetails?.timezone, rowData?.invoice_date, constants.month_date_year_format)}
        >
          {handleDateTimeOffset(userContext?.userDetails?.timezone, rowData?.invoice_date, constants.month_date_year_format)}
        </span>
      </React.Fragment>
    );
  };

  const daysPastDueBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Days Past Due</span>
        <span className="text-break" title={calculateDaysPastDue(rowData?.due_date, userContext?.userDetails?.timezone)}>
          {calculateDaysPastDue(rowData?.due_date, userContext?.userDetails?.timezone)}
        </span>
      </React.Fragment>
    );
  };

  const assignToItemTemplate = (option) => {
    return (
      <>
        {option?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
        {generateFullName(option)}
      </>
    );
  };

  useEffect(() => {
    let filters = {
      ...pickExtractedDate(dateFilter),
      invoice_status: '',
      search_key: searchKey,
      case_id: '',
      practice_area: selectedPractice,
    };
    loadInvoices(filters);
    totalCountEvent('account').then((response) => {
      dispatch(listAccounts(response.data));
    });
  }, []);

  useEffect(() => {
    if (practiceData && practiceData.length) {
      let practice_options = practiceData.map((val) => {
        return {
          label: val.practice_area_name,
          value: val.practice_area_name,
        };
      });
      setPracticeAreaOptions(practice_options);
    } else {
      setPracticeAreaOptions([]);
    }
  }, [practiceData]);

  useEffect(() => {
    if (localStorage.getItem('redirectToInvoice')) {
      setShowCreateInvoice(true);
      localStorage.removeItem('redirectToInvoice');
    }
  }, []);

  useEffect(() => {
    let invoiceDetails = location?.state?.invoiceDetails || getLocalStorageParseItem('stateData')?.invoiceDetails;
    if (invoiceDetails) {
      setSelectedInvoice(invoiceDetails);
      setViewInvoice(true);
      setShowCreateInvoice(true);
      removeLocalStorageItem('stateData');
    }
  }, [location]);

  useEffect(() => {
    if (showCreateInvoice) {
      document.body.style.overflow = 'hidden';
    }
    // Clean up the effect when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showCreateInvoice]); // to remove the dropdown scroll issue in dialog dropdown

  return (
    <div>
      {showRecordModal && (
        <RecordPayment
          show={showRecordModal}
          onHide={() => {
            setShowRecordModal(false);
          }}
          selectedInvoice={selectedInvoice}
          onSuccess={onSuccess}
          caseDetails={props?.caseDetails}
        />
      )}

      {showCreateInvoice && (
        <CreateInvoice
          show
          onHide={() => {
            setShowCreateInvoice(false);
          }}
          onSuccess={onSuccess}
          selectedInvoice={selectedInvoice}
          viewInvoice={viewInvoice}
          caseView={props.caseView}
          isLeadSpecific={props.isLeadSpecific}
          caseDetails={props.caseDetails}
          isCaseClosed={props.isCaseClosed}
          isLeadClosed={props.isLeadClosed}
          setShowRecordModal={setShowRecordModal}
          loadInvoices={loadInvoices}
          filters={filters}
          getPageLimit={getPageLimit}
        />
      )}
      {confirmDelete && (
        <ConfirmDialog
          className="common-popup-style confirm-delete-popup"
          visible={confirmDelete}
          rejectClassName="p-button-secondary outline"
          onHide={() => setConfirmDelete(false)}
          header="Confirm Delete"
          message={' Do you want to delete this invoice?'}
          icon="pi pi-exclamation-triangle"
          accept={() => {
            onDeleteInvoice();
          }}
          reject={() => setConfirmDelete(false)}
        />
      )}
      {confirmSendMail && (
        <SendNotificationMail
          show={confirmSendMail}
          onHide={() => {
            setConfirmSendMail(false);
          }}
          selectedRequest={selectedInvoice}
          invoiceView={true}
          isLeadSpecific={props.isLeadSpecific}
        />
      )}

      {showReminder && (
        <Reminders
          show
          onHide={() => {
            setShowReminder(false);
          }}
          selectedRequest={{ ...selectedInvoice, ...(selectedInvoice?.invoice || {}) }}
          onSuccess={onCreateSuccess}
          isInvoice
          isLeadSpecific={props.isLeadSpecific}
        />
      )}

      {/* {viewModal && (
        <ViewEditInvoice
          show={viewModal}
          onHide={() => {
            setViewModal(false);
          }}
          selectedInvoice={selectedInvoice}
        />
      )} */}

      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center mb-3">
            <Button
              label="Create Invoice"
              icon="pi pi-plus"
              className="p-button p-button-primary ms-auto"
              onClick={() => {
                setSelectedInvoice();
                setViewInvoice(false);
                setShowCreateInvoice(true);
              }}
              disabled={props.isCaseClosed || (props.isLeadSpecific && props.isLeadClosed)}
            ></Button>
          </div>
        </div>
      </div>
      <div className="">
        <div className="d-flex flex-wrap align-items-center filter-wrap mb-3 p-2 p-lg-0 w-100 align-items-center justify-content-between">
          <div className="p-2 filter-wraper">
            <span className="p-input-icon-left input-shadow">
              <i className="icon-search F-size16"></i>
              <TextSnippetWrapper
                type="text"
                placeholder="Search"
                onChange={(e) => {
                  onChangeSearch(e);
                }}
                value={searchKey}
                onKeyDown={onSearchKeyDown}
                className="p-inputtext p-component input-search"
              />
            </span>
          </div>
          <div className="d-flex flex-wrap align-items-center p-2 filter-wraper">
            <label className="text-bold black-600 p-0 filter-label">Filter:</label>
            <div className="d-flex flex-column ms-2 filter-wraper">
              <Calendar
                className="input-shadow filter-calender"
                id="icon"
                selectionMode="range"
                value={dateFilter}
                onChange={(e) => {
                  onChangeCalendar(e);
                }}
                showIcon
                placeholder="Date"
              />
            </div>
            {!isFromCaseSpecificModule && (
              <div className="d-flex flex-column mx-lg-2 mx-0 filter-wraper">
                <MultiSelect
                  value={selectedPractice}
                  options={practiceAreaOptions}
                  onChange={onChangePractice}
                  placeholder="Practice Area"
                  maxSelectedLabels={1}
                  display="chip"
                  filter
                  className="input-shadow w-100 F-size14 input-width"
                  style={{ height: '40px' }}
                />
              </div>
            )}
            <div className="d-flex flex-column mx-lg-2 mx-0 filter-wraper">
              <MultiSelect
                value={selectedLeadAttorneyList}
                options={userList}
                onChange={onChangeLeadAttorney}
                optionLabel={(v) => generateFullName(v)}
                optionValue="client_cognito_username"
                placeholder="Lead Attorney"
                maxSelectedLabels={1}
                display="chip"
                filter
                className="input-shadow w-100 F-size14 input-width"
                showClear
                itemTemplate={assignToItemTemplate}
              />
            </div>

            <div className="d-flex flex-column mx-2">
              <div className="pointer ms-0">
                <i className="fas fa-sync-alt" onClick={refreshCase}></i>
                <Tooltip content="Clear Filter" position="top" target=".fa-sync-alt" showDelay={500} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 datatable-responsive">
          <DataTable
            value={invoiceList?.invoices || []}
            className="p-datatable-responsive"
            paginator
            paginatorTemplate={paginatorTemplate}
            rows={pageLimit}
          >
            <Column header="ID" field="invoice_number" body={tableCell} />
            {isFromGlobalBilling && <Column header="Case" body={caseBody} bodyClassName="ellipsis-text" />}
            <Column
              header="Viewed"
              body={(rowData) => {
                return (
                  <>
                    <span className="p-column-title text-break">Viewed</span>
                    <span
                      title={
                        Boolean(Date.parse(rowData?.viewed_status))
                          ? format(new Date(rowData?.viewed_status), 'MM/dd/yyyy')
                          : rowData?.viewed_status
                      }
                    >
                      {Boolean(Date.parse(rowData?.viewed_status))
                        ? format(new Date(rowData?.viewed_status), 'MM/dd/yyyy')
                        : rowData?.viewed_status}
                    </span>
                  </>
                );
              }}
            />
            <Column
              header="Status"
              field="invoice_status"
              body={(rowData) => {
                if (rowData?.invoice_status)
                  return (
                    <>
                      <span className="p-column-title text-break">Status</span>
                      <span title={_.startCase(_.toLower(rowData?.invoice_status))}>{_.startCase(_.toLower(rowData?.invoice_status))}</span>
                    </>
                  );
              }}
              bodyClassName="ellipsis-text"
            />
            <Column
              header="Due"
              body={(rowData) => {
                return (
                  <>
                    <span className="p-column-title text-break">Due</span>
                    {rowData?.due_amount && <span className="text-break">{formatNumbersToCurrencyString(rowData?.due_amount)}</span>}
                    {rowData?.invoice_unpaid_ref_number && (
                      <span className="text-break"> Forwarded to {rowData?.invoice_unpaid_ref_number}</span>
                    )}
                  </>
                );
              }}
            />
            <Column header="Sent Date" field="invoice_date" body={dueDateBody} bodyClassName="ellipsis-text" />
            <Column header="Days Past Due" field="invoice_date" body={daysPastDueBody} bodyClassName="ellipsis-text" />
            <Column body={actionBody} />
          </DataTable>
        </div>
      </div>
    </div>
  );
};
