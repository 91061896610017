import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import LabelName from 'components/UI/LabelName/LabelName';

import AddLocationForm from './AddLocationForm';

function CourtEventLocation({ control, setValue, locationsList, hadleAddNewLocation, courtEventExtractedData }) {
  const [addLocation, setAddLocation] = useState(false);

  useEffect(() => {
    if (
      !locationsList.some((obj) => obj.value === courtEventExtractedData?.details?.court) &&
      courtEventExtractedData?.details?.court !== -1
    ) {
      setAddLocation(true);
    } else {
      if (courtEventExtractedData?.details?.court !== -1) {
        setValue('location', courtEventExtractedData?.details?.court);
      }
    }
  }, [courtEventExtractedData]);

  return (
    <div className="d-flex align-items-center py-2 flex-wrap">
      <div className="col-md-4 col-12 p-medium">
        <LabelName required={false} htmlFor="location">
          Location
        </LabelName>
      </div>
      <div className="col-md-8 col-12">
        {addLocation ? (
          <AddLocationForm
            setAddLocation={setAddLocation}
            setValue={setValue}
            hadleAddNewLocation={hadleAddNewLocation}
            locationsList={locationsList}
            courtEventExtractedData={courtEventExtractedData}
          />
        ) : (
          <div className="d-flex align-items-center">
            <Controller
              name="location"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={locationsList}
                  placeholder="Select Location"
                  className="w-100"
                  filter
                />
              )}
            />
            <div className="col-sm-1 d-flex justify-content-end ps-2">
              <span className="pointer">
                <i className="fas fa-plus-circle location-add" onClick={() => setAddLocation(true)}></i>
                <Tooltip content="Add Location" position="top" target=".location-add" showDelay={500} />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CourtEventLocation;
