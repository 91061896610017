import { CASES, DAYS, LEADS, MONTHS, WEEKS, generalCategoryContactList, teamMemberRolesList } from 'constants/automationConstants';
import { deleteAttachment, downloadFile } from 'utils/filesUtils';
import { generateFullName } from 'utils/generateFullNameUtils';
import { getTenantDetails, loadIcon } from 'utils/utils';
import { FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL, SetS3Config } from 'config/amplify_config';

import { Auth, Storage } from 'aws-amplify';
import { phoneValidation } from 'services/contact';
import { uuidv4 } from 'common/uuidGenerator';

/** displayFormError - Function for displaying form errors */
export const displayFormError = (error = {}) => {
  return <>{error?.message ? <small className="p-error mt-2">{error?.message}</small> : null}</>;
};

/**--- Filtering only active firm users --- **/
const changeFirmUserData = (firmUsersList = []) => {
  try {
    let dat =
      firmUsersList?.length &&
      firmUsersList.map((val) => {
        if (val?.access_level && val?.access_level === 'firm-admin') {
          let obj = { ...val };
          obj.user_type = val.access_level;
          obj.contact_id = val.user_id;
          return obj;
        } else {
          return val;
        }
      });
    // let newData = dat?.length ? dat.filter((v) => (v.access_level === 'firm-admin' || v.is_confirmed) && v.status === 'ACTIVE') : [];
    let newData = dat ? dat : [];

    return newData;
  } catch (err) {
    console.log('===err===', err);
    return [];
  }
};

const filterUserGroupsOrFirmUsers = (arr = []) => {
  let allFirmUsers = [];
  let allUserGroups = [];
  if (arr?.length) {
    arr.forEach((item, idx) => {
      const { res_type = '' } = item || {};

      if (res_type) {
        if (res_type === 'user-group') {
          allUserGroups?.push(item);
        } else if (res_type === 'user') {
          allFirmUsers?.push(item);
        }
      }
    });
  }
  return { allFirmUsers, allUserGroups };
};

/**--- Formatting firm users in a format for selectbox --- **/
export const generateFormattedFirmUsers = (params) => {
  // firmUsersAndUserGroupsList = [], triggerName = {}, isForLeadAutomation
  const { firmUsersList: firmUsersAndUserGroupsList = [], triggerName, isForLeadAutomation, setFirmUsersOnlyList } = params || {};

  let groupedArr = [];
  let firmUsersOnlyList = [];

  const groupedContacts = {
    Users: {
      groupLabel: 'Users',
      code: 'Users',
      items: [...generalCategoryContactList], //Primary and Billing contact list
    },
    // TeamMemberRoles: {
    //   groupLabel: 'Team Member Roles',
    //   code: 'TeamMemberRoles',
    //   items: [...teamMemberRolesList], //Team Member Roles list
    // },
  };

  //This condition is added to remove team member roles for all lead-module related triggers except the trigger "lead converted to case"
  // if (triggerName?.module === 'LEADS' && triggerName?.code !== 'TRIG_LEAD_CONV_CASE') {
  //   delete groupedContacts['TeamMemberRoles'];
  // }

  //Team Member Roles list is showing for CASES module and for LEADS/leads converted to case trigger only
  let caseAutomationCondition = triggerName?.module === CASES || !isForLeadAutomation;
  let leadAutomationCondition = (triggerName?.module === LEADS || isForLeadAutomation) && triggerName?.code === 'TRIG_LEAD_CONV_CASE';

  if (triggerName?.module && triggerName?.code && (caseAutomationCondition || leadAutomationCondition)) {
    groupedContacts['TeamMemberRoles'] = {
      groupLabel: 'Team Member Roles',
      code: 'TeamMemberRoles',
      items: [...teamMemberRolesList],
    };
  }

  const { allFirmUsers = [], allUserGroups = [] } = filterUserGroupsOrFirmUsers(firmUsersAndUserGroupsList) || {};

  const activeFirmUsers = changeFirmUserData(allFirmUsers); //Filtering only active firm-users

  const usersAndGroupsArr = [...activeFirmUsers, ...allUserGroups];

  if (usersAndGroupsArr?.length > 0) {
    usersAndGroupsArr?.forEach((element, idx) => {
      const {
        res_type = '',
        contact_id = '',
        email = '',
        sk = '',
        pk = '',
        group_name = '',
        user_group_id = '',
        access_level = '',
        client_cognito_username = '',
        firm_user_id = '',
        first_name = '',
        middle_name,
        last_name,
        profile_image = '',
        is_confirmed,
        status,
      } = element || {};

      const contactType = res_type === 'user-group' ? 'Groups' : 'Users';
      let singleContactDataObj = {};

      if (res_type === 'user') {
        // let pkValue = firm_user_id ? firm_user_id : pk?.split('#') && pk?.split('#')[pk?.split('#')?.length - 1];
        let pkValue = client_cognito_username ? client_cognito_username : pk?.split('#') && pk?.split('#')[pk?.split('#')?.length - 1];
        if (first_name) {
          let name = generateFullName(element);

          if (name) {
            singleContactDataObj = {
              label: name,
              value: pkValue,
              contact_id,
              email,
              access_level,
              client_cognito_username,
              firm_user_id,
              is_individual_user: true,
              profile_image,
              first_name,
              middle_name,
              last_name,
              res_type,
              is_confirmed,
              status,
            };
          }
          firmUsersOnlyList?.push(singleContactDataObj);
        }
      } else if (res_type === 'user-group') {
        singleContactDataObj = {
          label: group_name,
          // value: sk,
          value: user_group_id,
          pk,
          sk,
          group_id: user_group_id,
          res_type,
          status,
        };
      }

      if (!groupedContacts[contactType]) {
        groupedContacts[contactType] = {
          groupLabel: contactType,
          code: contactType,
          items: [],
        };
      }

      groupedContacts[contactType].items.push({
        ...singleContactDataObj,
      });
    });
  }

  setFirmUsersOnlyList(firmUsersOnlyList);

  groupedArr = Object.values(groupedContacts).reverse();

  return groupedArr;
};

/** Priority template options for priority dropdown list */
export const PriorityOptionsTemplateFacets = ({ data }) => {
  let actualData = data;
  let iconClassName = '';
  if (actualData?.label === 'High') {
    iconClassName = 'icon-High-filled';
  } else if (actualData?.label === 'Medium') {
    iconClassName = 'icon-Medium-filled';
  } else if (actualData?.label === 'Low') {
    iconClassName = 'icon-Low-filled';
  } else if (actualData?.label === 'No Priority') {
    iconClassName = 'icon-Error';
  }

  return (
    <div>
      <i className={`${iconClassName}`}></i>
      <span className="ms-1">{data?.label || 'Select'}</span>
    </div>
  );
};

/** --- getFilteredContacts - function to filter "Pirmary" or "Billing" and firm-users (attendees or assignees)--- **/
export const getFilteredContacts = (params) => {
  const { arrayToBeFiltered = [], isBillingOrPrimary = false, isFirmUser = true, isUserGroup = false, isTeamMember } = params || {};

  let filteredArr = [];
  filteredArr = arrayToBeFiltered?.filter((attendee, idx) => {
    const { value = '', group_id = '', contact_id = '', email = '', code = '' } = attendee || {};

    let isBillingOrPrimaryCondition = value === 'primary_contact' || value === 'billing_contact';

    let userGroupCondition = group_id ? true : false;

    let firmUserCondition = email || contact_id ? true : false;

    let temMemberRolesCondition = code === 'TEAM_MEMBER_ROLE' || teamMemberRolesList?.includes(value);

    let condition = false;

    if (isUserGroup) {
      condition = userGroupCondition; // User group
    } else if (isBillingOrPrimary) {
      condition = isBillingOrPrimaryCondition; // Primary or Billing contacts
    } else if (isFirmUser) {
      condition = firmUserCondition; //Firm users
    } else if (isTeamMember) {
      condition = temMemberRolesCondition;
    }
    return condition;
  });

  return filteredArr;
};

export const calculateDateTimeCylce = (dateCount = 0, datePeriod = DAYS) => {
  switch (datePeriod) {
    case MONTHS:
      return 30 * dateCount;
    case WEEKS:
      return 7 * dateCount;
    default: // DAYS => ( By default)
      return dateCount ? dateCount : 0;
  }
};

export const getFilteredReminders = (remindersArr = []) => {
  let filteredArr = [];
  if (remindersArr?.length) {
    filteredArr = remindersArr?.filter((rem, idx) => rem?.value);
  }
  return filteredArr;
};

/** Generating an array of firm-users cognito id */
export const getAccessibleList = (userArr = []) => {
  let firmUsersIdList = [];

  firmUsersIdList = userArr?.map((guest) => {
    const {
      cognito_username = '',
      contact_id = '',
      //  firm_user_id = ''
    } = guest || {};

    if (cognito_username) return cognito_username;
    // if (firm_user_id) return firm_user_id;

    return contact_id;
  });

  return firmUsersIdList;
};

/** File attachment related functionalities */
export const onClickAttachments = (e, fileInfo) => {
  e?.preventDefault();
  downloadFile(fileInfo);
};

export const onDeleteFile = (fileInfo) => {
  deleteAttachment(fileInfo);
};

export const onChangeReactDropZoneFile = (params) => {
  const {
    allAttachmentsWatched = [],
    filesData = [],
    notifyFormDataChange = () => {},
    setValue = () => {},
    isTextMessage = false,
  } = params || {};

  notifyFormDataChange();
  let totalFiles = [];
  if (isTextMessage && filesData?.length > 0) {
    totalFiles = [filesData[0]];
  } else {
    totalFiles = [...allAttachmentsWatched, ...filesData];
  }

  setValue('allAttachments', totalFiles);
};

//deleting attachment from local react state on confirm . Push this selected files to an array and deleting it when we hit update workflow
export const deleteAttachmentOnConfirm = (params) => {
  const {
    fileDetails = {},
    allAttachmentsWatched = [],
    attachmentsToBeDeletedFromS3BucketWatched = [],
    notifyFormDataChange = () => {},
    setValue = () => {},
  } = params || {};
  notifyFormDataChange();
  const { isFileUploaded, fileIndex } = fileDetails || {};

  let _attachments = [...allAttachmentsWatched];
  let attachmentToBeDeleted = _attachments[fileIndex] || {};

  if (isFileUploaded) {
    setValue('attachmentsToBeDeletedFromS3Bucket', [...attachmentsToBeDeletedFromS3BucketWatched, attachmentToBeDeleted]);
  }

  _attachments?.splice(fileIndex, 1);

  setValue('allAttachments', _attachments);
};

/** File upload functionality */
export const handleFileUpload = async (params) => {
  const { allAttachments = [], subModuleName = '', setIsLoading = () => {} } = params || {};

  try {
    setIsLoading(true);

    for (let i = 0; i < allAttachments?.length; i++) {
      let singleAttachment = allAttachments[i];

      if (!singleAttachment?.isFileUploaded) {
        //Here reuploading of the files those are already uploaded are blocked
        SetS3Config(FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL);
        let user = await Auth.currentAuthenticatedUser();
        const userId = user?.attributes?.sub;
        const [tenantId] = getTenantDetails();
        let module = subModuleName ? `automation/${subModuleName}` : 'automation';
        let response = await Storage.put(`${module}/${tenantId}/${userId}/${uuidv4()}#$#${singleAttachment?.name}`, singleAttachment, {
          contentType: singleAttachment?.type,
          progressCallback: (progress) => {
            const { loaded, total } = progress;
            let percent = Math.floor((loaded * 100) / total);
            if (percent <= 100) {
              // setUploadPercentage(percent); //use this if we need to show upload percentage on the screen
            }
          },
          useAccelerateEndpoint: true,
        });

        if (response?.key) {
          setIsLoading(false);

          let file_type = singleAttachment?.type?.toString();
          // file_type = constants.upload_doc_types[file_type];

          let file_details = {
            file_name: singleAttachment?.name,
            display_name: singleAttachment?.name,
            size: singleAttachment?.size?.toString(),
            file_path: 'public/' + response?.key,
            file_type: file_type,
            created_by: user?.attributes,
            isFileUploaded: true,
          };
          // allAttachments.push(file_details);
          allAttachments[i] = file_details;
        }
      }
    }
    return allAttachments;
  } catch (error) {
    setIsLoading(false);
  }
};

export const deleteFilesFromS3Bucket = async (params) => {
  const { attachmentsToBeDeletedFromS3Bucket = [], setValue = () => {} } = params || {};

  if (attachmentsToBeDeletedFromS3Bucket?.length) {
    await Promise.all(attachmentsToBeDeletedFromS3Bucket?.map((item) => deleteAttachment(item)));
    setValue('attachmentsToBeDeletedFromS3Bucket', []);
  }
};

/** --- Listing all attachments --- **/
export const renderAttachmentsList = (params) => {
  const { allAttachmentsWatched, setConfirmDelete = () => {}, setFileDetails = () => {} } = params || {};
  return (
    <div className="file-list-container d-flex align-items-center py-2 flex-wrap">
      <div className="col-md-3 col-12"></div>
      <div className="col-md-9 col-12">
        {allAttachmentsWatched?.length
          ? allAttachmentsWatched?.map((item, index) => {
              const { isFileUploaded = false, name = '', display_name = '' } = item || {};

              let fileName = display_name || name;

              const filePathSplittedArr = fileName?.split('.');

              const fileExtention = filePathSplittedArr?.length ? filePathSplittedArr?.reverse()[0] : 'pdf';

              return (
                <div className="d-flex mb-3 pe-2 align-items-center justify-content-between" key={index}>
                  <div
                    className="d-flex align-items-center"
                    key={index}
                    onClick={(e) => {
                      if (isFileUploaded) onClickAttachments(e, item);
                    }}
                  >
                    <i className="fa-lg primary-dark text-medium">{loadIcon(fileExtention)}</i>
                    <p className="mb-0 ms-2 text-primary-dark text-medium">
                      {/* {removeFirstSlash(filePath)} */}
                      {fileName}

                      {/* {isFileUploaded ? <span className="text-muted">(Remote File)</span> : null} */}
                    </p>
                  </div>

                  <div className="d-flex align-items-center ms-2">
                    <i
                      onClick={(e) => {
                        e.preventDefault();
                        setConfirmDelete(true);
                        setFileDetails({
                          fileIndex: index,
                          isFileUploaded,
                        });
                      }}
                      className="icon-delete icon-red pointer"
                    ></i>
                  </div>
                </div>
              );
            })
          : []}
      </div>
    </div>
  );
};

export const displayActionCardHeader = (header = '') => {
  return (
    <div className="row">
      <div className="col-12 col-lg-12 d-flex justify-content-between my-3">
        <div className="d-flex align-items-center justify-content-start">
          <h5 className="F-size16 text-bold"> {header}</h5>
        </div>
      </div>
    </div>
  );
};

export const handleDisplayWorkflowAutomationCardSaveButton = (params) => {
  const { isEditMode, isFormDataChanging } = params || {};

  let showButton = true;

  if (isEditMode) {
    if (isFormDataChanging) {
      showButton = true;
    } else {
      showButton = false;
    }
  } else {
    if (isFormDataChanging !== false) {
      showButton = true;
    } else {
      showButton = false;
    }
  }

  return showButton;
};

//This function is added to restrict only single firm-user selection in all lead-specific actions (except for "lead converted to case" trigger)
export const handleLeadTriggerValidationOnFirmUserSelection = (params) => {
  const { firmUsersOnlyList, selectedFirmUsers, triggerName, isForLeadAutomation } = params || {};
  let firmUsersCount = 0;

  if (
    firmUsersOnlyList?.length > 0 &&
    selectedFirmUsers?.length > 0 &&
    isForLeadAutomation &&
    // triggerName?.module === 'LEADS' &&
    triggerName?.code !== 'TRIG_LEAD_CONV_CASE'
  ) {
    firmUsersOnlyList?.every((item) => {
      if (selectedFirmUsers?.includes(item?.value)) {
        firmUsersCount++;
        if (firmUsersCount > 1) return false;
      }
      return true;
    });
  }

  return Boolean(firmUsersCount > 1);
};

export const formatManualTotalHours = (totalDuration = '') => {
  let totalHours = '';

  // if (totalDuration?.length) {
  //   if (!totalDuration?.includes('.')) {
  //     totalDuration = Number(totalDuration);
  //     totalHours = totalDuration?.toFixed(1);
  //   } else {
  //     totalHours = totalDuration;
  //   }
  // }
  // return totalHours;

  if (totalDuration) {
    let isIncludesDecimal = String(totalDuration)?.includes('.');
    if (!isIncludesDecimal) {
      totalHours = totalDuration?.toFixed(1);
    } else {
      totalHours = totalDuration;
    }
  }
  return String(totalHours);
};

export const validateDotsCount = (value, countValue) => {
  const dotCount = (value?.match(/\./g) || [])?.length;
  return dotCount === countValue || "Input must contain only one decimal '.' character";
};

export const validatePhoneNumber = async (value, contact_id) => {
  return new Promise((resolve) => {
    phoneValidation(value, true)
      .then((res) => {
        if (res?.data?.filter((v) => v.contact_id !== contact_id).length > 0) {
          resolve('Phone Number Already Exist');
        } else resolve(true);
      })
      .catch(() => resolve('Invalid Phone Number'));
  });
};

export const checkOptionDisabled = (v) => {
  if (v?.value?.status === 'ARCHIVED') {
    return true;
  }
  if (v?.value?.is_confirmed === false) {
    return true;
  }
  return false;
};
