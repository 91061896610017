export const pagination_preference = {
  dashboard: {
    activity_log: 10,
  },
  case: {
    self: 10,
    file_manager: 10,
    message: 10,
    note: 10,
    billings: {
      expenses: 10,
      automated_time_entries: 10,
      manual_time_entries: 10,
      payments_request: 10,
      invoices: 10,
      payment_history: 10,
      trust_accounting: 10,
    },
    activity_log: 10,
    medical_expenses: 10,
    case_expenses: 10,
  },
  file_manager: {
    self: 10,
  },
  templates: {
    self: 10,
  },
  messages: {
    self: 10,
  },
  contacts: {
    self: 10,
  },
  billings: {
    expenses: 10,
    automated_time_entries: 10,
    manual_time_entries: 10,
    payments_request: 10,
    invoices: 10,
    payment_history: 10,
    trust_accounting: 10,
  },
  leads: {
    file_manager: 10,
    message: 10,
    note: 10,
    billings: {
      expenses: 10,
      automated_time_entries: 10,
      manual_time_entries: 10,
      payments_request: 10,
      invoices: 10,
      payment_history: 10,
      trust_accounting: 10,
    },
    activity_log: 10,
  },
  automation: {
    self: 10,
    error_list: 10,
  },
  settings: {
    snippet: {
      self: 10,
    },
    team_management: {
      self: 10,
    },
    custom_fields: {
      case: 10,
      contact: 10,
      companies: 10,
    },
    custom_descriptors: {
      case_matters: 10,
      practice_areas: 10,
      case_stages: 10,
      lead_stages: 10,
      event_types: 10,
      relation_role: 10,
      job_titles: 10,
      global_tags: 10,
      business_role: 10,
      lead_reasons: 10,
      case_reasons: 10,
      case_outcomes: 10,
      venue: 10,
    },
    billing_codes: {
      self: 10,
    },
    expenses_codes: {
      self: 10,
    },
    lead_source: {
      self: 10,
    },
    time_manager: {
      automated_time_manager: 10,
      manual_time_manager: 10,
    },
    accounts: {
      self: 10,
    },
    client_intake_form: {
      self: 10,
    },
    client_intake_list: {
      self: 10,
    },
    lead_intake_list: {
      self: 10,
    },
  },
  client_portals: {
    billings: {
      payments_request: 10,
      invoices: 10,
    },
    filemanager: {
      self: 10,
    },
    tasks: {
      self: 10,
    },
    messages: {
      self: 10,
    },
  },
  report: {
    case_report: 10,
    closed_case_report: 10,
    trust_account_transaction_report: 10,
    personal_injury_report: 10,
    personal_injury_case_report: 10,
    personal_injury_medical_report: 10,
    case_stage_age_report: 10,
    payment_report: 10,
    lead_report: 10,
    referral_report: 10,
    conversion_report: 10,
    medical_provider: 10,
  },
};
