import React from 'react';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import './styles.css';

const UploadWindow = ({ files, onMinimize, minimized, onCancel }) => {
  return (
    <div className={`upload-window ${minimized ? 'minimized' : ''}`}>
      <div className="upload-header">
        <h6 className="mb-0">File Upload</h6>
        <div className="d-flex">
          <Button
            onClick={onMinimize}
            icon={minimized ? 'pi pi-chevron-up' : 'pi pi-chevron-down'}
            className="p-button-rounded p-button-text p-button-sm"
          />
          <Button onClick={onCancel} icon={'pi pi-times'} className="p-button-rounded p-button-text p-button-sm" />
        </div>
      </div>
      {!minimized && (
        <div className="upload-body">
          {Object.keys(files).map((key) => {
            const file = files[key];
            if (!file?.file) return null;
            return (
              <div key={key} className="upload-item">
                <div className="file-info">
                  <p className="file-name">{file.file.name}</p>
                </div>
                <ProgressBar
                  color="#4BA256"
                  value={file.progress}
                  style={{ height: 12, lineHight: '0.8rem' }}
                  displayValueTemplate={() => <>{!file?.paused ? file.progress + '%' : file.completed ? 'Completed' : 'Paused'} </>}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default UploadWindow;
