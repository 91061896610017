import React from 'react';
import { Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';

import LabelName from 'components/UI/LabelName/LabelName';
import { usePracticeAreaList } from 'hooks/usePracticeAreaList';

function EventPracticeArea({ control, setValue }) {
  const { practiceAreaList } = usePracticeAreaList();

  return (
    <div className="d-flex align-items-center py-2 flex-wrap">
      <div className="col-md-4 col-12">
        <LabelName required={false} htmlFor="practice_area">
          Practice Area
        </LabelName>
      </div>
      <div className="col-md-8 col-12">
        <Controller
          name="practice_area"
          control={control}
          defaultValue=""
          render={({ field }) => {
            return (
              <Dropdown
                id={field.name}
                onChange={(e) => field.onChange(e.value)}
                value={Array.isArray(field.value) ? field.value[0] : field.value}
                options={practiceAreaList || []}
                optionLabel="practice_area_name"
                optionValue="sk"
                placeholder="Select Practice Area"
                className="input-shadow w-100"
                filter
              />
            );
          }}
        />
      </div>
    </div>
  );
}

export default EventPracticeArea;
