import React, { useState, useEffect } from 'react';
import { Avatar } from 'primereact/avatar';
import { Tooltip } from 'primereact/tooltip';
import { Storage, Cache } from 'aws-amplify';
import { capitalizeFirstLetter } from 'utils/utils';

const ImageComponent = (props) => {
  const { filePath, fileName, fileSize, avatarSize, className, style, noCache = false, key, ...rest } = props;
  const [imageUrl, setImageUrl] = useState('');
  const defaultAvatartStyle = { backgroundColor: '#2196F3', color: '#ffffff' };
  useEffect(() => {
    if (filePath) {
      fetchImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filePath]);

  const fetchImage = async () => {
    //workaround to avoid re-render in task module
    const v = Cache.getItem(filePath);
    if (v && !noCache) {
      setImageUrl(v === 'no-image' ? '' : v);
      return;
    }
    const expiration = new Date();
    expiration.setMinutes(expiration.getMinutes() + 3);
    try {
      if (filePath) {
        let proURL = filePath;
        let url = proURL.split('/');
        url.pop();
        if (fileSize) {
          url.push(fileSize);
        } else {
          url.push('small.jpg');
        }
        let finalUrl = url.join('/');
        Storage.configure({
          bucket: process.env.REACT_APP_IMG_BUCKET,
          region: process.env.REACT_APP_REGION,
          identityPoolId: process.env.REACT_APP_IDP_ID,
          customPrefix: {
            public: '',
            protected: '',
            private: '',
          },
        });
        let data = await Storage.get(finalUrl);
        if (data) {
          setImageUrl(data);
          Cache.setItem(filePath, data, { expires: expiration.getTime() });
          props.getImageUrl && props.getImageUrl(data);
          //workaround to avoide rerender in task module
        } else {
          //workaround to avoide rerender in task module
          Cache.setItem(filePath, 'no-image', { expires: expiration.getTime() });
          props.getImageUrl && props.getImageUrl('');
          setImageUrl('');
        }
      }
    } catch (error) {
      //workaround to avoid rerender in task module
      Cache.setItem(filePath, 'no-image', { expires: expiration.getTime() });
      props.getImageUrl && props.getImageUrl('');
      setImageUrl('');
    }
  };

  return (
    <React.Fragment key={key}>
      {imageUrl ? (
        <Avatar
          image={imageUrl}
          onImageError={() => setImageUrl('')}
          className={className ? className : 'me-1'}
          size={avatarSize ? avatarSize : 'medium'}
          shape="circle"
          imageAlt=""
          style={style ? style : defaultAvatartStyle}
          onClick={() => {
            if (props?.onClick) {
              props?.onClick();
            }
          }}
          {...rest}
        />
      ) : (
        <Avatar
          label={capitalizeFirstLetter(fileName)}
          className={className ? className : 'me-1'}
          size={avatarSize ? avatarSize : 'medium'}
          shape="circle"
          imageAlt=""
          style={style ? style : defaultAvatartStyle}
          onClick={() => {
            if (props?.onClick) {
              props?.onClick();
            }
          }}
          {...rest}
        />
      )}

      {props?.showTooltip ? (
        <Tooltip target={props?.toolTipTarget} position={props?.tooltipPosition} className="case-tooltip mb-3 text-capitalize">
          {props?.toolTipContent}
        </Tooltip>
      ) : null}
    </React.Fragment>
  );
};

export default React.memo(ImageComponent);
