import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FileDownload from '../../ListView/Templates/ActionsTemplate/FileDownload';

function TextFileViewer(props) {
  const [fileContent, setFileContent] = useState('');

  useEffect(() => {
    axios(props.fileUrl)
      .then((res) => {
        setFileContent(JSON.stringify(res.data));
        typeof props?.onLoad === 'function' && props.onLoad();
      })
      .catch((error) => console.error('Error loading .txt file:', error));
  }, [props.fileUrl]);

  return (
    <div className="txt-file-viewer">
      {props?.item && (
        <FileDownload
          rowData={props?.item}
          isFromFilePreviewModal={true}
          filePreviewModalIconClassName={'icon-save p-2 pointer float-end h2 mb-0'}
        />
      )}
      {/* <PrintFile
        rowData={props?.item}
        isFromFilePreviewModal={true}
        filePreviewModalIconClassName={'icon-Print p-2 pointer float-end h2 mb-0'}
      /> */}
      <pre>{fileContent}</pre>
    </div>
  );
}

export default TextFileViewer;
