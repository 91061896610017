import constants from 'constants/index';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { handleSettingSkAndPkToLocalStorage } from 'utils/localStorageUtils';
import { truncateText } from 'utils/utility_functions/textTruncate';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { handleRedirection } from 'utils/utils';

function UploadedTemplate(props) {
  const { rowData, userContext, isClientFileManager } = props;
  const history = useHistory();
  const IsoFormattedDate = rowData?.time || rowData?.version_edited || '';
  const { sk, pk } = rowData;

  const isClient = rowData?.created_by_details?.access_level?.toLowerCase() === 'client';
  let url = '';

  if (isClient) {
    const contactId = rowData?.created_by_details?.created_by_contact_id;
    url = contactId ? `/contacts/${contactId}` : '/contacts';
  } else {
    const firmUserId = rowData?.created_by_details?.created_by_firm_user_id;
    url = firmUserId ? `/settings/team-management/${firmUserId}` : '/settings/team-management';
  }

  const onClickCreatedBy = (e) => {
    e.preventDefault();
    if (!isClientFileManager) {
      handleSettingSkAndPkToLocalStorage(sk, pk);
      handleRedirection(history, url, rowData);
    }
  };

  return (
    <React.Fragment>
      <span className="p-column-title text-break">Date Uploaded</span>
      <div className="text-break d-inline-table file-manager-ellipsis-text ">
        <small
          className="file-manager-ellipsis-text"
          title={handleDateTimeOffset(userContext?.userDetails?.timezone, IsoFormattedDate, constants.month_date_year_time_12_format)}
        >
          {handleDateTimeOffset(userContext?.userDetails?.timezone, IsoFormattedDate, constants.month_date_year_time_12_format)}
        </small>
        <div
          className={`text-hint text-capitalize file-manager-ellipsis-text ${!isClientFileManager ? 'cursor-pointer' : ''}`}
          onClick={onClickCreatedBy}
          title={
            rowData?.created_by_details?.first_name
              ? `${rowData?.created_by_details?.first_name} ${rowData?.created_by_details?.last_name || ''}`
              : undefined
          }
        >
          {rowData?.created_by_details?.created_by_firm_user_id && <i className="fas fa-user-tie me-2"></i>}
          {rowData?.created_by_details?.first_name
            ? `${rowData?.created_by_details?.first_name} ${rowData?.created_by_details?.last_name || ''}`
            : ''}
        </div>
      </div>
    </React.Fragment>
  );
}

export default UploadedTemplate;
