import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { Controller, useForm } from 'react-hook-form';

import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';

import { businessTypes, titles, types } from 'constants/dropdownOptions';
import { useRelationRole } from 'hooks/useRelationRole';
import { useBusinessRole } from 'hooks/useBusinessRole';
import { PI_PROVIDERS_TYPE } from 'constants/index';
import { validatePhoneNumber } from 'modules/lead/helpers/helpers';
import { emailDuplicateCheck } from 'services/Contact/contactServices';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { useCaseContact } from 'modules/cases/hooks/useCaseContact';
import { useCaseDetailsCustomField } from 'modules/cases/hooks/useCaseDetailsCustomField';
import { getContactTypeLabel } from 'utils/utils';
import CustomFields from 'modules/lead/AddLead/components/ContactForm/CustomFields';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import DuplicateDetection from './DuplicateDetection';
import { InputSwitch } from 'primereact/inputswitch';
import { useExperts } from 'hooks/useExperts';

export default function AddContact({ isLeadSpecific, toggleAddContact, onContactSubmit }) {
  const {
    watch,
    formState: { errors },
    control,
    setValue,
    trigger,
    reset,
    handleSubmit,
  } = useForm();
  const [selectedContact, setSelectedContact] = useState('');

  const { checkSubPermission } = useUserDetailsContext();
  const { caseCustomFieldList } = useCaseDetailsCustomField();
  const { updateContactHandler } = useCaseContact({});

  useEffect(() => {
    setValue('custom_field', caseCustomFieldList);
  }, [caseCustomFieldList]);

  const contactType = watch('contact_type');
  const businessType = watch('business_type');

  const isContactSelected = typeof selectedContact === 'object';

  const { relationRoleList } = useRelationRole();
  const { businessRoleList } = useBusinessRole();
  const { expertList } = useExperts();

  const getFormErrorMessage = (name) => {
    return errors?.[name] && <small className="p-error">{errors[name].message}</small>;
  };

  const validatePhone = async (value) => {
    if (isContactSelected) return true;
    if (!Boolean(value)) {
      return true;
    }
    return validatePhoneNumber(value, null);
  };

  const emailValidation = async (value) => {
    if (isContactSelected) return true;
    if (!Boolean(value)) {
      return true;
    }
    try {
      await emailDuplicateCheck(value);
      return true;
    } catch (error) {
      return 'Email already exists';
    }
  };

  const onContactSelect = (contact) => {
    setSelectedContact(contact);
    typeof contact === 'object' && reset({ ...contact });
  };

  const onDismiss = () => {
    reset({
      contact_type: null,
      title: null,
      first_name: null,
      email: null,
      phone: null,
      role: null,
      is_primary_contact: null,
      is_billing_contact: null,
      is_create_client_portal: null,
      notify_case_updates: null,
      custom_field: caseCustomFieldList,
      fax_number: null,
    });
    setSelectedContact('');
  };

  return (
    <React.Fragment>
      <DuplicateDetection value={selectedContact} onSelect={onContactSelect} onDismiss={onDismiss} />
      <Tooltip target=".on-behalf-infos" position="right" />
      <div className="relations-box">
        <div className="d-flex flex-wrap mt-3 pt-3">
          <div className="col-12 col-lg-6 px-2 mb-3">
            <label className="p-medium" htmlFor="name">
              Contact Type
              <span className="text-danger"> *</span>
            </label>
            <Controller
              name="contact_type"
              control={control}
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <Dropdown
                  className="input-shadow w-100 border-0"
                  placeholder="Contact Type"
                  options={types?.filter((v) => v.value !== 'location')}
                  id={field.name}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  disabled={isContactSelected}
                />
              )}
            />
            {getFormErrorMessage('contact_type')}
          </div>
          {contactType && contactType === 'business' && (
            <div className="col-12 col-lg-6 px-2 mb-3">
              <label className="p-medium" htmlFor="Business_Type">
                Business Type
              </label>
              <Controller
                name="business_type"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    value={value}
                    onChange={(e) => onChange(e.value)}
                    name="business_type"
                    options={businessTypes}
                    placeholder="Select"
                    className="w-100"
                    inputStyle={{ width: '100%' }}
                    filter
                    showClear
                  />
                )}
              />
              {getFormErrorMessage('business_type')}
            </div>
          )}
          {contactType === 'business' && businessType === 'expert' && (
            <div className="col-12 col-lg-6 px-2 mb-3">
              <div className="col-12 mt-1">
                <label className="p-medium" htmlFor="Area_of_Expertise">
                  Expert Type
                  <span className="text-danger"> *</span>
                </label>
                <Controller
                  name="expert_type"
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field: { onChange, value } }) => (
                    <Dropdown
                      value={value}
                      onChange={(e) => onChange(e.value)}
                      name="expert_type"
                      options={expertList}
                      optionLabel="title"
                      optionValue="title"
                      placeholder="Select"
                      className="w-100"
                      inputStyle={{ width: '100%' }}
                      filter
                    />
                  )}
                />
                {getFormErrorMessage('expert_type')}
              </div>
            </div>
          )}
          {/* title field business name for business  */}

          {contactType && contactType === 'person' && (
            <div className="col-12 col-lg-6 px-2 mb-3">
              <label className="p-medium w-100" htmlFor="name">
                {getContactTypeLabel(contactType)}
                <span className="text-danger"> *</span>
              </label>
              <Controller
                name="title"
                control={control}
                rules={{ required: 'Required' }}
                render={({ field }) => (
                  <Dropdown
                    className="input-shadow w-100 border-0"
                    placeholder={getContactTypeLabel(contactType)}
                    id={field.name}
                    options={titles}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    filter
                    disabled={isContactSelected}
                  />
                )}
              />
              {getFormErrorMessage('title')}
            </div>
          )}
          {contactType === 'business' && (
            <div className="col-12 col-lg-6 px-2 mb-3">
              <label className="p-medium w-100" htmlFor="name">
                {getContactTypeLabel(contactType)}
                <span className="text-danger"> *</span>
              </label>
              <Controller
                name="title"
                control={control}
                rules={{ required: 'Required' }}
                render={({ field }) => (
                  <TextSnippetWrapper
                    type="text"
                    disabled={isContactSelected}
                    className="input-shadow w-100 border-0"
                    placeholder={getContactTypeLabel(contactType)}
                    {...field}
                  />
                )}
              />
              {getFormErrorMessage('title')}
            </div>
          )}
          {/* first name,middle, last name field */}
          {contactType === 'person' && (
            <>
              <div className="col-12 col-lg-6 px-2 mb-3">
                <label className="p-medium" htmlFor="name">
                  First Name
                  <span className="text-danger"> *</span>
                </label>
                <Controller
                  name="first_name"
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field }) => (
                    <TextSnippetWrapper
                      type="text"
                      disabled={isContactSelected}
                      className="input-shadow w-100 border-0"
                      placeholder="First Name"
                      {...field}
                    />
                  )}
                />
                {getFormErrorMessage('first_name')}
              </div>
              <div className="col-12 col-lg-6 px-2 mb-3">
                <label className="p-medium" htmlFor="name">
                  Middle Name
                </label>
                <Controller
                  name="middle_name"
                  control={control}
                  render={({ field }) => (
                    <TextSnippetWrapper
                      type="text"
                      disabled={isContactSelected}
                      className="input-shadow w-100 border-0"
                      placeholder="  Middle Name"
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="col-12 col-lg-6 px-2 mb-3">
                <label className="p-medium" htmlFor="name">
                  Last Name
                  <span className="text-danger"> *</span>
                </label>
                <Controller
                  name="last_name"
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field }) => (
                    <TextSnippetWrapper
                      type="text"
                      disabled={isContactSelected}
                      className="input-shadow w-100 border-0"
                      placeholder="Last Name"
                      {...field}
                    />
                  )}
                />
                {getFormErrorMessage('last_name')}
              </div>
            </>
          )}
          <div className="col-12 col-lg-6 px-2 mb-3">
            <label className="p-medium" htmlFor="role">
              Email
              {watch('is_create_client_portal') ? <span className="text-danger"> *</span> : null}
            </label>
            <Controller
              name="email"
              control={control}
              rules={{
                required: watch('is_create_client_portal') ? 'Email is required.' : false,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Entered value does not match email format',
                },
                validate: { asyncValidation: (v) => emailValidation(v) },
              }}
              render={({ field }) => (
                <InputText
                  disabled={isContactSelected}
                  placeholder="Type in"
                  id={field.name}
                  {...field}
                  onChange={(e) => setValue('email', e.target.value)}
                  onBlur={() => trigger('email')}
                  className="w-100 new-task-popup input-shadow"
                />
              )}
            />
            {getFormErrorMessage('email')}
          </div>

          <div className="col-12 col-lg-6 px-2 mb-3">
            <label className="p-medium" htmlFor="phone">
              Phone Number
            </label>
            <Controller
              name="phone"
              control={control}
              rules={{
                validate: { asyncValidation: (v) => validatePhone(v) },
                minLength: { value: 10, message: 'Min length should be 10' },
              }}
              render={({ field }) => (
                <NumberFormat
                  className="input-shadow w-100 p-2 border-0 number-format"
                  placeholder="Type in"
                  disabled={isContactSelected}
                  format="(###) ###-####"
                  mask="_"
                  name="phone"
                  keyfilter={'pnum'}
                  onValueChange={(e) => setValue('phone', e.value)}
                  value={field.value}
                  onBlur={() => trigger('phone')}
                />
              )}
            />
            {getFormErrorMessage('phone')}
          </div>

          <div className="col-12 col-lg-6 px-2 mb-3">
            <label className="p-medium" htmlFor="Business_Role">
              {contactType === 'business' ? 'Business' : null} Role
              <span className="text-danger"> *</span>
            </label>
            <Controller
              name="role"
              control={control}
              rules={{ required: 'Required' }}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  value={value}
                  onChange={(e) => onChange(e.value)}
                  name="role"
                  options={contactType === 'business' ? businessRoleList : relationRoleList}
                  optionLabel={contactType === 'business' ? 'name' : 'relation_role'}
                  optionValue={contactType === 'business' ? 'name' : 'relation_role'}
                  placeholder="Select"
                  className="w-100"
                  inputStyle={{ width: '100%' }}
                  filter
                />
              )}
            />
            {getFormErrorMessage('role')}
          </div>
          <div className="col-12 col-lg-6 px-2 mb-3">
            <label className="p-medium" htmlFor="name">
              Fax Number
            </label>
            <Controller
              name="fax_number"
              control={control}
              render={({ field }) => (
                <TextSnippetWrapper
                  type="text"
                  disabled={isContactSelected}
                  className="input-shadow w-100 border-0"
                  placeholder="Fax Number"
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          {/* <div className="col-12 mb-3 px-2">
            {(contactType === 'business' && businessType === 'expert') && (
              <div className="col-12 mt-1">
                <label className="p-medium" htmlFor="Area_of_Expertise">
                  {contactType === 'vendor'} Expert Type
                  <span className="text-danger"> *</span>
                </label>
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field: { onChange, value } }) => (
                    <Dropdown
                      value={value}
                      onChange={(e) => onChange(e.value)}
                      name="title"
                      options={expertList}
                      optionLabel="title"
                      optionValue="title"
                      placeholder="Select"
                      className="w-100"
                      inputStyle={{ width: '100%' }}
                      filter
                    />
                  )}
                />
                {getFormErrorMessage('title')}
              </div>
            )}
          </div> */}

          <div className="row checkbox-row px-3 mt-3">
            {!isLeadSpecific && (
              <div className="col-12 col-lg-6 mb-3 px-2">
                <div className="d-flex align-items-center">
                  <Controller
                    name="is_primary_contact"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Checkbox
                        name="is_primary_contact"
                        checked={value}
                        onChange={(e) => onChange(e.checked)}
                        // disabled={isCaseClosed || (isLeadClosed && isLeadSpecific)}
                      />
                    )}
                  />
                  <label className="mx-2 p-medium" htmlFor="is_primary_contact">
                    Primary Contact
                  </label>
                </div>
              </div>
            )}
            {!isLeadSpecific && (
              <div className="col-12 col-lg-6 mb-3 px-2">
                <div className="d-flex align-items-center">
                  <Controller
                    name="is_billing_contact"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Checkbox name="is_billing_contact" checked={value} onChange={(e) => onChange(e.checked)} />
                    )}
                  />
                  <label className="mx-2 p-medium" htmlFor="is_billing_contact">
                    Billable Contact
                  </label>
                </div>
              </div>
            )}

            {contactType === 'person' ? (
              <>
                <div className="col-12 col-lg-6 mb-3 px-2">
                  <div className="d-flex align-items-center">
                    <Controller
                      name="is_create_client_portal"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Checkbox
                          name="is_create_client_portal"
                          checked={value}
                          onChange={(e) => {
                            onChange(e.checked);
                            if (isContactSelected) {
                              updateContactHandler({ ...selectedContact, is_create_client_portal: e.checked }, isLeadSpecific);
                            }
                          }}
                          disabled={!watch('email')}
                        />
                      )}
                    />
                    <label className="mx-2 p-medium enable_lawft_acess" htmlFor="is_create_client_portal">
                      Enable Lawft Access
                    </label>
                  </div>
                  {watch('email') ? (
                    ''
                  ) : (
                    <Tooltip target=".enable_lawft_acess" position="right" className="case-tooltip">
                      {'Add Email To Enable Lawft Access'}
                    </Tooltip>
                  )}
                </div>

                <div className="col-12 mb-3 px-2">
                  <div className="d-flex align-items-center">
                    <Controller
                      name="notify_case_updates"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Checkbox
                          name="notify_case_updates"
                          checked={value}
                          onChange={(e) => onChange(e.checked)}
                          disabled={!watch('is_create_client_portal')}
                        />
                      )}
                    />
                    <label className="mx-2 p-medium" htmlFor="notify_case_updates">
                      Notify Case Updates
                    </label>
                  </div>
                </div>
                {isLeadSpecific && (
                  <div className="col-12 mb-3 px-2">
                    <div className="d-flex align-items-center">
                      <Controller
                        name="type"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Checkbox
                            name="type"
                            checked={value === 'on-behalf'}
                            onChange={(e) => onChange(e.checked ? 'on-behalf' : undefined)}
                          />
                        )}
                      />
                      <label className="mx-2 p-medium" htmlFor="is_billing_contact">
                        On-behalf Contact
                        <i
                          className="ms-1 pi pi-info-circle on-behalf-infos cursor-pointer"
                          data-pr-tooltip="Person who made first contact"
                          style={{ fontSize: '13px' }}
                        />
                      </label>
                    </div>
                  </div>
                )}
              </>
            ) : null}
            <div className="col-12 mb-3 px-2">
              <div className="d-flex align-items-center">
                <Controller
                  name="is_client_opt_in"
                  control={control}
                  defaultValue={true}
                  render={({ field }) => (
                    <Checkbox name="is_create_client_portal" checked={field.value} onChange={(e) => field.onChange(e.checked)} />
                  )}
                />
                <label className="mx-2 p-medium" htmlFor="notify_case_updates">
                  Client has opted in to recieve text messages
                </label>
              </div>
            </div>
          </div>

          <div className="col-12 px-2 mb-1">
            {checkSubPermission('custom_fields') && !isContactSelected && (
              <>
                <div className="d-flex align-items-center py-2">
                  <div className="col-sm-6 d-flex addfield">
                    <Controller
                      name="custom_field_toggle"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <Button
                          id={field.name}
                          type="button"
                          className="p-button button-text text-medium addfield ps-0"
                          onClick={(e) => field.onChange(!field.value)}
                        >
                          <i className={field?.value ? 'pi pi-minus F-size14 me-1' : 'pi pi-plus F-size14 me-1'}></i>Add Custom Fields
                        </Button>
                      )}
                    />
                  </div>
                </div>
                {watch('custom_field_toggle') && <CustomFields field="custom_field" control={control} />}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-12 px-2 mb-2">
        <div className="footer-btn">
          <Button onClick={toggleAddContact} className="p-button p-button-secondary outline me-2">
            Cancel
          </Button>
          <Button
            className="p-button p-button-secondary"
            onClick={handleSubmit((formdata) => onContactSubmit(formdata, !isContactSelected))}
          >
            Add
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}
