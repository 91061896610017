import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import { MultiSelect } from 'primereact/multiselect';
import { useForm, Controller } from 'react-hook-form';

import { useToggle } from 'hooks/useToggle';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { useLeadContext } from 'modules/lead/Context/LeadContext';

export default function AddNewStage(props) {
  const { handleAddStage, checkTaskStageExists } = props;
  const [displayPosition, setDisplayPosition] = useToggle(false);
  const { practiceAreaList } = useLeadContext();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const onHide = () => {
    reset();
    setDisplayPosition(false);
  };

  const handleStageCreate = (params) => {
    handleAddStage(params);
    onHide();
  };

  return (
    <>
      <div id="addtask" className="add-task-btn pointer p-2" onClick={setDisplayPosition}>
        <i className="icon-add" id="addtask" />
        <Tooltip position="left" target="#addtask" content={`Add new stage`} />
      </div>
      <Dialog
        header="Enter Stage Name"
        visible={displayPosition}
        position="right"
        className="task-add-stage"
        footer={<></>}
        modal
        onHide={onHide}
      >
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-3">
            <Controller
              name="laneName"
              control={control}
              defaultValue=""
              rules={{ required: true, validate: checkTaskStageExists, pattern: { value: /^(?!^\d+$).*/i } }}
              render={({ field }) => <TextSnippetWrapper type="text" className="input-shadow me-2" {...field} />}
            />
            {errors?.laneName?.type === 'validate' ? <InputErrorMessage>This Stage Already Exists</InputErrorMessage> : null}

            {errors?.laneName?.type === 'required' ? <InputErrorMessage>Stage name is required</InputErrorMessage> : null}
            {errors?.laneName?.type === 'pattern' ? <InputErrorMessage>At least 1 character is required</InputErrorMessage> : null}
          </div>
          <div className="d-flex flex-column mb-3">
            <Controller
              name="practice_area"
              control={control}
              // rules={{ required: 'Required' }}
              render={({ field }) => (
                <MultiSelect
                  id={field.name}
                  onChange={(e) => field.onChange(e.value)}
                  value={field.value}
                  options={practiceAreaList}
                  optionLabel="practice_area_name"
                  optionValue="sk"
                  className="col-sm-12 input-shadow w-100"
                  placeholder="Select Practice Area"
                  filter
                  display="chip"
                />
              )}
            />
            {/* {errors?.practice_area_name?.type === 'required' ? <InputErrorMessage>Practice Area is required</InputErrorMessage> : null} */}
          </div>

          {/* <div className="d-flex justify-content-center align-items-center mx-2">
            <i className="pi pi-check cursor-pointer" onClick={handleSubmit(handleStageCreate)} />
          </div>
          <div className="d-flex justify-content-center align-items-center mx-2">
            <i className="pi pi-times cursor-pointer" onClick={onHide} />
          </div> */}
          <div className="d-flex justify-content-center align-items-center mx-2 flex-row">
            <i className="pi pi-check cursor-pointer me-3" onClick={handleSubmit(handleStageCreate)} />
            <i className="pi pi-times cursor-pointer" onClick={onHide} />
          </div>
        </div>
      </Dialog>
    </>
  );
}
