import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { MultiSelect } from 'primereact/multiselect';

import { uuidv4 } from 'common/uuidGenerator';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { RequiredValidation } from 'components/UI/RequiredValidation/RequiredValidation';

export default function NestedSubTask({ control, register, errors, checkOptionDisabled, assignToItemTemplate, clientData }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sub_task',
  });

  return (
    <>
      {fields.map((item, index) => {
        return (
          <div
            className="d-flex align-items-center bg-grey mb-2"
            key={item.id}
            style={{
              padding: 8,
              borderRadius: 12,
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            }}
          >
            <div className="w-100">
              <div className="w-100">
                <TextSnippetWrapper
                  type="text"
                  className="mb-2 w-100 input-shadow"
                  defaultValue={item.sub_task_description}
                  placeholder="Sub task title"
                  {...register(`sub_task[${index}].sub_task_description`, { required: 'Sub task title required' })}
                />
                <RequiredValidation field={`sub_task.${index}.sub_task_description`} errors={errors} />
              </div>
              <Controller
                name={`sub_task[${index}].assign_to`}
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    options={clientData?.find((v) => v?.code === 'Users')?.items}
                    className="w-100 input-shadow F-size14 input-height"
                    filter
                    filterPlaceholder="Search"
                    placeholder="Select Assignee"
                    display="chip"
                    optionLabel="assignee_name"
                    optionDisabled={checkOptionDisabled}
                    itemTemplate={assignToItemTemplate}
                    optionValue={(v) => v}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    resetFilterOnHide
                    showSelectAll={false}
                  />
                )}
              />
            </div>
            <div className="ms-2">
              <i className="icon-delete icon-red pointer" onClick={() => remove(index)} />
            </div>
          </div>
        );
      })}
      <div
        className="mt-2 edit-field pointer addfield"
        onClick={() => append({ sub_task_description: '', sub_task_status: false, sk: uuidv4() })}
      >
        <i className="icon-add F-size14 me-1"></i> Add Sub Task
      </div>
    </>
  );
}
