import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import Filter from './Filter';
import constants from 'constants/index';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { useLeadContext } from 'modules/lead/Context/LeadContext';
import { Tooltip } from 'primereact/tooltip';
import { useNavbarContext } from 'context/NavbarContext';

export default function Header(props) {
  const { setAddToggle, filter, filterOnChange, refreshList } = props;
  const userDetails = useUserDetailsContext();
  const history = useHistory();

  const { practiceAreaList, userList, showLeadSettings, setShowLeadSettings } = useLeadContext();
  const { notificationData } = useNavbarContext();

  useEffect(() => {
    notificationData?.loadNotificationCount();
  }, []);

  return (
    <React.Fragment>
      <GlobalLoader />
      <div className="d-flex justify-content-between align-items-center ">
        <h1 className="title mb-0">Leads</h1>
      </div>
      <div className="d-flex align-items-center mb-3 justify-content-between flex-wrap">
        <Filter {...{ practiceAreaList, userList, filter, filterOnChange, refreshList }} />
        <div className="d-flex align-items-center my-2">
          <div
            className={'list-view gear-icon lead-gear '}
            id={'lead-gear'}
            onClick={() => {
              setShowLeadSettings(true);
            }}
          >
            <Tooltip content="Lead Display Settings" position="bottom" target="#lead-gear" style={{ bottom: 0 }} showDelay={500} />
            <i className="icon-Setting cursor-pointer"></i>
          </div>
          <div className="mt-3 mt-lg-0">
            <Button
              style={{ overflow: 'visible', color: 'white' }}
              label="Online Leads"
              className="p-button-primary button outline ms-2"
              onClick={() => history.push('/leads/online')}
            >
              {Number(notificationData?.notificationCount?.online_lead_count) > 0 && (
                <p className="indicator-online-lead">{notificationData?.notificationCount?.online_lead_count}</p>
              )}
            </Button>
            {userDetails.getPermission('leads') === constants.permissions.WRITE && (
              <Button
                label="Add New Lead"
                icon="pi pi-plus"
                className="button p-button p-component p-button p-button-primary ms-2"
                onClick={setAddToggle}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
