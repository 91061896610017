import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

function CreateCourtEvent(props) {
  const { rowData } = props;
  const { handleShowCreateEvent } = useFileManagerContext();

  const handleCourtEvent = (rowData) => {
    const data = {
      file_path: rowData?.file_path,
      file_name: rowData?.file_name,
      sk: rowData?.sk,
    };
    handleShowCreateEvent(rowData, data);
  };

  return (
    <>
      <i
        id="icon-building"
        onClick={() => handleCourtEvent(rowData)}
        className={'icon-building me-3 text-primary-main pointer'}
        aria-hidden="true"
      />
      <Tooltip content="Create Event" position="top" target="#icon-building" showDelay={500} />
    </>
  );
}

export default CreateCourtEvent;
