import { Auth } from 'aws-amplify';
import { jsPDF } from 'jspdf';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { Storage } from 'aws-amplify';

import Constants from 'constants/index';
import { generateFullName } from './generateFullNameUtils';
import { timeZonesToRemove } from 'constants/time_zones';
import { currentBrowserTimeZone } from './utility_functions/timezone';
import { dateFormatMap, reminderOptions } from 'constants/dropdownOptions';
import { FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL, SetS3Config } from 'config/amplify_config';

const domains = Constants.APP_DOMAINS;

let cookie = new Cookies();

export const getUserInfo = () => {
  try {
    Auth.currentUserInfo()
      .then((res) => {
        localStorage.setItem('userInfo', JSON.stringify(res));
      })
      .catch((error) => {
        localStorage.setItem('userInfo', null);
      });
  } catch (e) {
    localStorage.setItem('userInfo', null);
  }
};

export const getTodaysDate = (date = false) => {
  var today = date ? new Date(date) : new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();
  today = mm + '/' + dd + '/' + yyyy;
  return today;
};

export const getOpenLinkInNewTabCondition = () => {
  return window?.event?.metaKey || window?.event?.ctrlKey || window?.event?.keyCode === 224 || window?.event?.keyCode === 91;
};

//Control Key (or Command key) + Click must open link in New Tab
export const openLinkInBlank = (moduleName, stateData = null) => {
  if (getOpenLinkInNewTabCondition()) {
    if (stateData) {
      window?.localStorage?.setItem('stateData', JSON.stringify(stateData));
    }
    window?.open(window?.location?.protocol + '//' + window?.location?.host + '/#' + moduleName, '_blank');
    return true;
  }

  return false;
};

export const handleRedirection = (history, url = '/', stateToPass = {}) => {
  if (!openLinkInBlank(url, stateToPass)) {
    // window.location.hash = route;

    history?.push({
      pathname: url,
      state: stateToPass,
    });
  }
};

export const paginationDropdownOptions = [
  // { label: 5, value: 5 },
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

export const getTenantDetails = () => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replaceAll('#', '%23') : '';
  return [tenantId, tenantSk];
};

export const getUserId = () => {
  const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage?.getItem('userInfo')) : {};
  return userDetails?.attributes?.sub;
};

export const fixToTwoDecimal = (amount) => {
  if (amount) {
    try {
      amount = parseFloat(amount);
      amount = amount.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      amount = parseFloat(amount);
      return amount;
    } catch (err) {
      return amount;
    }
  } else {
    return amount;
  }
};

export const trimString = function (string, length) {
  return string.length > length ? string.substring(0, length) + '...' : string;
};

//convert object data to query params
export const convertObjectToQuery = (params) => {
  return Object.keys(params)
    .map(function (key) {
      return key + '=' + (params[key] || '');
    })
    .join('&');
};

export const dollarFormat = (val) => {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(val || '');
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '';
};

export const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const getOnlyNumbersFromString = (stringValueInput = '') => {
  let res = stringValueInput.replace(/\D/g, '');
  return res;
};

export const convertDateToSeconds = (dateVal) => {
  const date = new Date(dateVal);
  const seconds = Math.floor(date.getTime() / 1000);
  return seconds;
};

//time delay functionality
export const delayFunctionality = (ms) => new Promise((res) => setTimeout(res, ms));

export const formatNumbersToCurrencyString = (numberToBeFormated, locale = 'en-US', currency = 'USD') => {
  const options = { style: 'currency', currency };

  return new Intl.NumberFormat(locale, options).format(numberToBeFormated || '');
};

//letter case formatting functions
export const toTitleCase = (str) => {
  if (!str) {
    return '';
  }
  const arr = str.split('_');

  const result = [];

  for (const word of arr) {
    result.push(
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // 👈️ lowercase remainder
    );
  }

  return result.join(' ');
};

export const toCapitalized = (str = '') => {
  if (str?.trim()?.length) {
    let newStr = str?.charAt(0).toUpperCase() + str?.replace('-', ' ').slice(1).toLowerCase();
    let splitStr = newStr?.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }
  return '';
};

export const toPascalCase = (str) => {
  const words = str.split(' ');
  const result = [];
  for (const word of words) {
    result.push(
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // 👈️ lowercase remainder
    );
  }
  return result.join(' ');
};

//get apiId from app url

export const getApiIdFromAppUrl = (ApiUrl = '') => {
  let apiId = '';
  if (ApiUrl) {
    let splitteArr = [];

    splitteArr = ApiUrl?.split('.');

    if (splitteArr?.length) {
      apiId = splitteArr[0]?.split('//')[1];
    }
  }

  return apiId;
};

export const getFullName = (first_name, middle_name, last_name) => {
  let fullName = '';
  if (first_name) fullName = first_name;
  if (middle_name) fullName = fullName + ' ' + middle_name;
  if (last_name) fullName = fullName + ' ' + last_name;
  return fullName;
};

/**
 * @param str - The input string.
 * @returns Returning a string with the first letter of each word
 */
export const startCase = (str) => {
  if (typeof str !== 'string' || str.trim() === '') {
    return '';
  }
  const words = str.replace('_', ' ').toLowerCase().split(' ');
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join(' ');
};

export const returnFileType = (format) => {
  switch (format.toLowerCase()) {
    case 'pdf':
      return 'pdf';
    case 'docx':
    case 'doc':
      return 'doc';
    case 'png':
    case 'jpg':
    case 'jpeg':
      return 'image';
    case 'mp4':
    case 'avi':
    case 'mkv':
    case 'mov':
    case 'ogg':
      return 'video';
    case 'mp3':
    case 'aac':
    case 'wav':
    case 'm4a':
      return 'audio';
    default:
      return 'folder';
  }
};

export function getFileTypeFromMimeType(mimeType) {
  const mimeTypesToExtensions = {
    // Images
    'image/jpeg': 'image',
    'image/png': 'image',
    'image/gif': 'image',
    'image/bmp': 'image',
    'image/tiff': 'image',
    'image/webp': 'image',
    // Videos
    'video/mp4': 'video',
    'video/quicktime': 'video',
    'video/webm': 'video',
    'video/3gpp': 'video',
    'video/3gpp2': 'video',
    'video/3gpp-tt': 'video',
    'video/H261': 'video',
    'video/H263': 'video',
    'video/H263-1998': 'video',
    'video/H263-2000': 'video',
    'video/H264': 'video',
    // Audio
    'audio/basic': 'audio',
    'audio/L24': 'audio',
    'audio/mp4': 'audio',
    'audio/mpeg': 'audio',
    'audio/ogg': 'audio',
    'audio/vnd.rn-realaudio': 'audio',
    'audio/vnd.wave': 'audio',
    'audio/3gpp': 'audio',
    'audio/3gpp2': 'audio',
    'audio/ac3': 'audio',
    'audio/webm': 'audio',
    'audio/amr-nb': 'audio',
    'audio/amr': 'audio',
    // Text
    'text/vcard': 'doc',
    'text/x-vcard': 'doc',
    'text/csv': 'doc',
    'text/rtf': 'doc',
    'text/richtext': 'doc',
    'text/calendar': 'doc',
    'text/directory': 'doc',

    'application/pdf': 'doc',
    'application/vcard': 'doc',
    'application/vnd.apple.pkpass': 'doc',
    'application/msword': 'doc',
    'application/vnd.ms-excel': 'doc',
    'application/vnd.ms-powerpoint': 'doc',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'doc',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
    // More MIME types can be added as needed
  };

  const defaultFileType = 'unknown'; // Default file type if the MIME type is not found in the lookup object

  const extension = mimeTypesToExtensions[mimeType];
  return extension || defaultFileType;
}

export const loadIcon = (format = 'pdf') => {
  switch (format?.toLowerCase()) {
    case 'pdf':
      return <i className="icon-pdf" />;
    case 'docx':
    case 'doc':
    case 'ods':
      return <i className="icon-doc" />;
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'image':
      return <i className="icon-jpg" />;
    case 'mp4':
    case 'avi':
    case 'mkv':
    case 'mov':
    case 'ogg':
    case 'video':
      return <i className="icon-play" />;
    default:
      return <i className="icon-folder" />;
  }
};

export const getTeamMemberName = (teamMemberArr) => {
  try {
    if (!teamMemberArr?.length) {
      return 'N/A';
    }
    let names = teamMemberArr.filter((value) => value.first_name).map((val) => generateFullName(val));
    return names?.join(', ');
  } catch (error) {
    return 'N/A';
  }
};

export const getLeadAttorneyName = (leadAttorneyArr) => {
  try {
    let leadAttorney = leadAttorneyArr?.find((val) => val.is_lead_attorney);
    if (leadAttorney?.first_name) {
      return `${leadAttorney.first_name} ${leadAttorney.last_name}`;
    } else {
      return 'N/A';
    }
  } catch (error) {
    return 'N/A';
  }
};

/**
 * This function capitalizes the first letter of a given string or returns "C" if the input is empty or
 * undefined.
 * @param data - The input string that needs to be capitalized.
 * @returns return the first character of `data` capitalized. If `data` is falsy, the function
 * will return the string "C".
 */
export const capitalizeFirstLetter = (data) => {
  if (data) {
    return data.charAt(0).toUpperCase();
  } else {
    return 'C';
  }
};

/**
 * The function takes a list, a start index, and an end index, and returns a new list with the item at
 * the start index moved to the end index.
 * @param list - The list parameter is an array that contains the items to be reordered.
 * @param startIndex - The index of the item in the list that needs to be moved.
 * @param endIndex - The index where the removed item will be inserted back into the list.
 * @returns The function `reorderList` returns a new array with the same elements as the input `list`,
 * but with the element at index `startIndex` removed and inserted at index `endIndex`.
 */
export const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const formatStageName = (name) => (name === 'to_do' || name === 'in_progress' || name === 'done' ? startCase(name) : name);

var has = Object.prototype.hasOwnProperty;
export function deepEqual(foo, bar) {
  var ctor, len;
  if (foo === bar) return true;
  if (foo && bar && (ctor = foo.constructor) === bar.constructor) {
    if (ctor === Date) return foo.getTime() === bar.getTime();
    if (ctor === RegExp) return foo.toString() === bar.toString();
    if (ctor === Array) {
      if ((len = foo.length) === bar.length) {
        while (len-- && deepEqual(foo[len], bar[len]));
      }
      return len === -1;
    }
    if (!ctor || typeof foo === 'object') {
      len = 0;
      for (ctor in foo) {
        if (has.call(foo, ctor) && ++len && !has.call(bar, ctor)) return false; // NOSONAR
        if (!(ctor in bar) || !deepEqual(foo[ctor], bar[ctor])) return false;
      }
      return Object.keys(bar).length === len;
    }
  }
  // eslint-disable-next-line no-self-compare
  return foo !== foo && bar !== bar;
}

export const formatContact = (data) => {
  if (data.res_type === 'user-group') {
    return {
      group_name: data?.group_name,
      group_id: data?.user_group_id,
      sk: data?.sk,
      assignee_id: data?.user_group_id,
      assignee_name: data?.group_name,
    };
  } else
    return {
      assignee_id: data?.client_cognito_username || data?.contact_id,
      assignee_name: generateFullName(data),
      cognito_username: data?.client_cognito_username,
      contact_id: data?.contact_id,
      firm_user_id: data?.firm_user_id,
      email: data?.email,
      access_level: data?.access_level,
    };
};

export const formatMultiSelectContact = (data) => {
  if (data.res_type === 'user-group') {
    return {
      group_name: data?.group_name,
      group_id: data?.user_group_id,
      sk: data?.sk,
      assignee_id: data?.user_accessible_list,
      // assignee_id: data,
      assignee_name: data?.group_name,
    };
  } else
    return {
      assignee_id: data?.client_cognito_username || data?.contact_id,
      assignee_name: generateFullName(data),
      cognito_username: data?.client_cognito_username,
      contact_id: data?.contact_id,
      firm_user_id: data?.firm_user_id,
      email: data?.email,
      access_level: data?.access_level,
    };
};

export const formatContactValue = (data) => {
  if (data.group_id) {
    return {
      group_name: data?.group_name,
      group_id: data?.user_group_id,
      sk: data?.sk,
      assignee_id: data?.user_group_id,
      assignee_name: data?.group_name,
    };
  } else
    return {
      assignee_id: data?.client_cognito_username || data?.contact_id,
      assignee_name: generateFullName(data),
      cognito_username: data?.client_cognito_username,
      contact_id: data?.contact_id,
      email: data?.email,
    };
};

export function findArrayDifferences(initialArray, newArray) {
  const added = newArray.filter((item) => !initialArray.includes(item));
  const removed = initialArray.filter((item) => !newArray.includes(item));

  return { added, removed };
}

export const formatContactList = (data) => {
  const groupedContacts = {};

  data.forEach((contact) => {
    const contactType = contact.res_type === 'user-group' ? 'Groups' : 'Users';

    if (!groupedContacts[contactType]) {
      groupedContacts[contactType] = {
        assignee_name: contactType, // Assuming code is used as the label for the group
        code: contactType,
        items: [],
      };
    }

    groupedContacts[contactType].items.push({
      ...contact,
      ...formatContact(contact),
    });
  });

  // return Object.values(groupedContacts).reverse();
  return Object.values(groupedContacts);
};

export const formatMultiSelectContactList = (data) => {
  const groupedContacts = {};

  data?.forEach((contact) => {
    const contactType = contact.res_type === 'user-group' ? 'Groups' : 'Users';

    if (!groupedContacts[contactType]) {
      groupedContacts[contactType] = {
        assignee_name: contactType, // Assuming code is used as the label for the group
        code: contactType,
        items: [],
      };
    }

    groupedContacts[contactType].items.push({
      ...contact,
      ...formatMultiSelectContact(contact),
    });
  });

  // return Object.values(groupedContacts).reverse();
  return Object.values(groupedContacts);
};

export const getPriorityIcon = (priority) => {
  let iconClassName;
  if (priority) {
    if (priority === 'High') {
      iconClassName = 'icon-High-filled';
    } else if (priority === 'Medium') {
      iconClassName = 'icon-Medium-filled';
    } else if (priority === 'Low') {
      iconClassName = 'icon-Low-filled';
    } else if (priority === 'No Priority') {
      iconClassName = 'icon-Error';
    }
    return iconClassName;
  }
};

export const doesObjectHaveValue = (obj) => {
  return Object.values(obj).some((value) => !!value);
};

export const getCustomFieldListOptions = (options) => {
  let newOptions = [{ label: 'Select', value: '' }];
  if (options && options.length) {
    options.forEach((val) => {
      newOptions.push({ label: val.option, value: val.option });
    });
    return newOptions;
  }
};

export function getNestedProperty(obj, propertyPath) {
  const properties = propertyPath.split('.');
  let value = obj;

  for (const property of properties) {
    if (value && value.hasOwnProperty(property)) {
      value = value[property];
    } else {
      return undefined; // Property not found, return undefined
    }
  }

  return value;
}

export const getRelativeTime = (messageTime) => {
  const currentTime = moment();
  const messageDate = moment(messageTime);

  if (currentTime.isSame(messageDate, 'day')) {
    // Today
    return messageDate.format('hh:mm A');
  } else if (currentTime.clone().subtract(1, 'day').isSame(messageDate, 'day')) {
    // Yesterday
    return 'Yesterday';
  } else {
    // Other dates
    return messageDate.format('MM/DD/YYYY');
  }
};

export const removeHtmlTags = (input = '') => {
  return input?.replace(/<[^>]*>/g, '');
};

export const settingsDropdownOptions = [
  {
    label: 'Team Management',
    value: '/team-management',
    icon: 'icon-teammanagement',
    icon_active: 'icon-teammanagement-filled',
  },
  {
    label: 'Manage Lawft Subscription',
    value: '/subscription',
    icon: 'icon-subscription',
    icon_active: 'icon-subscription-filled',
  },
  {
    label: 'Manage Saved Cards',
    value: '/saved-cards',
    icon: 'icon-expenses',
    icon_active: 'icon-expenses-filled',
  },
  {
    label: 'General Settings',
    value: '/general-settings',
    icon: 'icon-Setting',
    icon_active: 'icon-Setting',
  },
  {
    label: "Review Request URL's",
    value: '/request-review-urls',
    icon: 'far fa-share-square',
    icon_active: 'far fa-share-square',
  },
  {
    label: 'Groups, Roles, Permissions',
    value: '/groups',
    icon: 'icon-shared',
    icon_active: 'icon-shared',
  },
  {
    label: 'Custom Fields',
    value: '/custom-fields',
    icon: 'icon-Customfield',
    icon_active: 'icon-Customfield-filled',
  },

  {
    label: 'Custom Descriptors',
    value: '/custom-descriptors',
    icon: 'icon-custom-descriptors',
    icon_active: 'icon-custom-descriptors-filled',
  },
  {
    label: 'Office Locations',
    value: '/office-locations',
    icon: 'icon-officeaddress',
    icon_active: 'icon-officeaddress-filled',
  },
  {
    label: 'Billing Codes',
    value: '/billing-codes',
    icon: 'icon-activity',
    icon_active: 'icon-activity-filled',
  },
  {
    label: 'Expense Codes',
    value: '/expense-codes',
    icon: 'icon-expensetype',
    icon_active: 'icon-expensetype-filled',
  },
  {
    label: 'Lead Sources',
    value: '/lead-sources',
    icon: 'icon-leadsource',
    icon_active: 'icon-leadsource-filled',
  },
  {
    // label: 'Accounts',
    label: 'Bank Accounts',
    value: '/accounts',
    icon: 'icon-accounts',
    icon_active: 'icon-accounts-filled',
  },
  {
    label: 'Payment Structure',
    value: '/payment-structure',
    icon: 'icon-paymrntstructure',
    icon_active: 'icon-paymrntstructure-filled',
  },
  {
    label: 'Automation',
    value: '/workflow-automation',
    icon: 'icon-automation',
    icon_active: 'icon-automation-filled',
  },
  {
    label: 'Text Snippets',
    value: '/snippets',
    icon: 'icon-text-input',
    icon_active: 'icon-text-input',
  },
  {
    label: 'NPS Settings',
    value: '/nps',
    icon: 'icon-NPS',
    icon_active: 'icon-NPS',
  },
];

export const activeInactiveOptions = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'ARCHIVED' },
];

//scroll to top using scrollIntoView
export const scrollToElement = (element) => {
  if (element) {
    element?.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
      inline: 'nearest',
    });
  }
};

export const getIndexofArrayByKeyValue = (array = [], key = false, value = false) => {
  let index = false;
  if (array && array.length && key && value !== false) {
    array.find((item, i) => {
      let result = false;
      if (item[key] === value) {
        index = i;
        return true;
      }
      return result;
    });
  }
  return index;
};

export const removeUnwantedKey = (array = [], keysToRemove = []) => {
  const newArray = array.map((item) => {
    const newItem = { ...item }; // Create a copy of the original object
    keysToRemove.forEach((key) => delete newItem[key]); // Remove specified keys
    return newItem;
  });
  return newArray;
};

export const removeHyphensFromStringNumber = (input) => {
  // Use a regular expression to remove hyphens from the string
  return input.replace(/[-()]/g, '');
};

export const makeListUnique = (list, key) => {
  const uniqueMap = new Map();
  list.forEach((obj) => !uniqueMap.has(obj[key]) && uniqueMap.set(obj[key], obj));
  return Array.from(uniqueMap.values());
};
export function getPropByString(obj, propString) {
  if (!propString) return obj;

  var prop,
    props = propString.split('.');

  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i];

    var candidate = obj[prop];
    if (candidate !== undefined) {
      obj = candidate;
    } else {
      break;
    }
  }
  return obj[props[i]];
}

export function areArraysEqual(arr1, arr2) {
  // Check if the arrays have the same length
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  // Sort the arrays
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  // Compare the sorted arrays
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  // If all elements are equal, return true
  return true;
}

export const getProfileImageFilePath = (userId = '') => {
  let profileImageFilePath = null;

  if (userId) {
    const tenantId = cookie.get('tenantId') || null;
    profileImageFilePath = `${tenantId}/${userId}/profile_image`;
  }

  return profileImageFilePath;
};
export function convertSecondsToTime(seconds) {
  if (isNaN(seconds) || Number(seconds) === 0) {
    return '-';
  }
  const oneMinuteInSeconds = 60;
  const oneHourInSeconds = oneMinuteInSeconds * 60;
  const oneDayInSeconds = oneHourInSeconds * 24;

  const days = Math.floor(seconds / oneDayInSeconds);

  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''}`;
  } else {
    return '< 1 day';
  }
}

export const RedirectToDashboardHandler = async (history, isFirstLogin) => {
  const access_level = cookie.get('access_level') || null;
  const subdomain = cookie.get('subdomain') || null;
  const origin = window.location.hostname.replace('www.', '');
  const app_domain = process.env.REACT_APP_DOMAIN;
  if (access_level && subdomain) {
    if (origin === 'localhost') {
      if (access_level === 'client') {
        if (isFirstLogin) {
          history.push('/client/upload-picture');
        } else {
          history.replace('/client/dashboard');
        }
      } else {
        history.replace('/dashboard');
      }
    } else {
      if (window.location.hostname === `${subdomain}.${app_domain}`) {
        if (access_level === 'client') {
          if (isFirstLogin) {
            history.push('/client/upload-picture');
          } else {
            history.replace('/client/dashboard');
          }
        } else {
          history.replace('/dashboard');
        }
      } else {
        if (access_level === 'client') {
          if (isFirstLogin) {
            window.open(`https://${subdomain}.${app_domain}/#/client/upload-picture`, '_self');
          } else {
            window.open(`https://${subdomain}.${app_domain}/#/client/dashboard`, '_self');
          }
        } else {
          window.open(`https://${subdomain}.${app_domain}/#/dashboard`, '_self');
        }
      }
    }
  }
};

export const logout = (history) => {
  const origin = window.location.hostname.replace('www.', '');

  cookie.remove('token', { path: '/', domain: process.env.REACT_APP_DOMAIN });
  cookie.remove('access_level', { path: '/', domain: process.env.REACT_APP_DOMAIN });
  cookie.remove('subdomain', { path: '/', domain: process.env.REACT_APP_DOMAIN });
  cookie.remove('tenantId', { path: '/', domain: process.env.REACT_APP_DOMAIN });
  cookie.remove('tenantSk', { path: '/', domain: process.env.REACT_APP_DOMAIN });
  cookie.remove('tenantName', { path: '/', domain: process.env.REACT_APP_DOMAIN });
  cookie.remove('userId', { path: '/', domain: process.env.REACT_APP_DOMAIN });
  cookie.remove('isSignUp', { path: '/', domain: process.env.REACT_APP_DOMAIN });

  localStorage.clear();

  if (origin === 'localhost') {
    return typeof history?.push === 'function' ? history.push('/') : (window.location.href = '/');
  } else {
    window.open(`${process.env.REACT_APP_URL}/#/login`, '_self');
  }
};

export const checkSubdomain = (publicRoutes, websocketContext, history, publicRoutesNoRedirect) => {
  let hash = window.location.hash;
  const qParams = convertObjectToQuery(getQueryParams(window.location.href));
  const path = hash.replace('#', '').split('?')[0];
  const origin = window.location.hostname.replace('www.', '');

  const subdomain = cookie.get('subdomain') || null;
  const token = cookie.get('token') || null;

  if (publicRoutesNoRedirect.includes(path)) {
    //
    return;
  }

  if (origin !== 'localhost') {
    if (subdomain && token) {
      if (domains.includes(origin)) {
        window.open(`https://${subdomain}.${origin}/${qParams ? '?' + qParams : ''}${hash}`, '_self');
      } else if (!domains.includes(origin) && publicRoutes.includes(path)) {
        window.open(`https://${origin}/#/dashboard`, '_self');
        return;
      }
    } else {
      if (token && !subdomain && path !== '/tenant-selection') {
        Auth.signOut()
          .then((data) => {
            logout(history);
            websocketContext.closeConnection();
          })
          .catch((error) => {});
        return;
      } else if (!domains.includes(origin)) {
        window.open(process.env.REACT_APP_URL, '_self');
        return;
      } else if (domains.includes(origin) && !publicRoutes.includes(path) && path !== '/tenant-selection') {
        window.open(`${process.env.REACT_APP_URL}/#/login`, '_self');
        return;
      }
    }
  }
};

export const firmSelectHandler = (history) => {
  if (window.location.hostname === 'localhost') {
    // history.push('/tenant-selection');
    const url = '/tenant-selection';
    history.replace({
      pathname: url,
      state: {},
    });
  } else {
    let target = '_self';
    if (getOpenLinkInNewTabCondition()) target = '_blank';

    cookie.remove('access_level', { path: '/', domain: process.env.REACT_APP_DOMAIN });
    cookie.remove('subdomain', { path: '/', domain: process.env.REACT_APP_DOMAIN });
    cookie.remove('tenantId', { path: '/', domain: process.env.REACT_APP_DOMAIN });
    cookie.remove('tenantSk', { path: '/', domain: process.env.REACT_APP_DOMAIN });
    cookie.remove('tenantName', { path: '/', domain: process.env.REACT_APP_DOMAIN });
    // cookie.remove('userId', { path: '/', domain: process.env.REACT_APP_DOMAIN });
    cookie.remove('isSignUp', { path: '/', domain: process.env.REACT_APP_DOMAIN });

    // window.open(`${process.env.REACT_APP_URL}/#/tenant-selection`, '_self');

    // window.open(`http://${process.env.REACT_APP_DOMAIN}/#/tenant-selection`, '_self');
    window.open(`http://${process.env.REACT_APP_DOMAIN}/#/tenant-selection`, target);

    // cookies.remove('selected_tenant', { path: '/',domain: ".lawft.com" });
  }
};
export function bytesToFormattedSize(bytes) {
  if (bytes < 0) {
    throw new Error('Input must be a non-negative number.');
  }

  const gigabytes = bytes / Math.pow(1024, 3);
  const megabytes = bytes / Math.pow(1024, 2);

  if (gigabytes >= 1) {
    return `${gigabytes.toFixed(2)} GB`;
  } else {
    return `${megabytes.toFixed(2)} MB`;
  }
}

export function removePhoneNumberFormatting(phoneNumber) {
  // Define the expected format using a regular expression
  const expectedFormat = /^\(\d{3}\) \d{3}-\d{4}$/;

  // Check if the phone number matches the expected format
  if (expectedFormat.test(phoneNumber)) {
    // If it matches, remove formatting
    return phoneNumber.replace(/\D/g, '');
  }

  // If it doesn't match, return the original phone number
  return phoneNumber;
}

export function bringElementToTop(array, id) {
  const index = array.findIndex((item) => item.user_id === id);

  if (index !== -1) {
    const element = array[index];
    array.splice(index, 1);
    array.unshift(element);
  }
  return array;
}

export function bringMultiSelectElementToTop(data, id) {
  // Iterate through each assignee type (Groups, Users, etc.)
  data.forEach((assigneeType) => {
    // Check if assigneeType has items
    if (assigneeType.items && Array.isArray(assigneeType.items)) {
      // Find the index of the item with the specified user_id
      const index = assigneeType.items.findIndex(
        (item) => item.user_id === id || (item.users_list && item.users_list.some((user) => user.firm_user_id === id))
      );

      // If the item is found, move it to the top
      if (index !== -1) {
        const element = assigneeType.items[index];
        assigneeType.items.splice(index, 1);
        assigneeType.items.unshift(element);
      }
    }
  });

  return data;
}

export function validateNumber(a) {
  return a === 0 || a ? a : '0';
}

export function round2Decimals(num) {
  return Math.round((num + Number.EPSILON) * 10000) / 10000;
}

export function convertNumbersToStrings(obj) {
  for (let key in obj) {
    if (typeof obj[key] === 'number') {
      obj[key] = obj[key].toString();
    } else if (typeof obj[key] === 'object') {
      convertNumbersToStrings(obj[key]); // Recursively convert numbers in nested objects
    }
  }
  return obj;
}

export const getContactTypeLabel = (contactType) => {
  let label = 'Title';
  switch (contactType) {
    case 'person':
      break;

    case 'business':
      label = 'Business Name';
      break;
    case 'vendor':
      label = 'Vendor Name';

      break;

    case 'medical_provider':
      label = 'Medical Provider Name';
      break;
    case 'location':
      label = 'Location Name';
      break;

    default:
      label = 'Title';
      break;
  }

  return label || '';
};

export function filterTimeZones(timeZones) {
  const obj = Object.keys(timeZones).reduce((acc, key) => {
    if (!timeZonesToRemove.includes(key)) {
      acc[key] = timeZones[key];
    }
    return acc;
  }, {});
  return obj;
}

export function checkKeyIsPresentInObject(obj, key) {
  if (obj === null || typeof obj !== 'object') {
    return false;
  }
  if (obj.hasOwnProperty(key)) {
    return true;
  }
  for (const k in obj) {
    if (obj.hasOwnProperty(k) && checkKeyIsPresentInObject(obj[k], key)) {
      return true;
    }
  }
}

export const convertToGroupedDates = (datetimes, timezone) => {
  const modData = convertAndFilterSlots(datetimes, timezone);
  const groupedDates = {};

  modData?.forEach((datetime) => {
    const date = moment(datetime)
      .tz(timezone || currentBrowserTimeZone)
      .format('MMM DD, YYYY');
    const time = moment(datetime)
      .tz(timezone || currentBrowserTimeZone)
      .format('hh:mmA');

    if (!groupedDates[date]) {
      groupedDates[date] = [];
    }

    groupedDates[date].push({ time, datetime });
  });

  // Convert the groupedDates object into the desired array format
  return Object.keys(groupedDates).map((date) => ({
    date,
    times: groupedDates[date],
  }));
};

export const convertCaseToFilterFormat = (item) => {
  if (!item) {
    return '';
  }
  return {
    label:
      item && item.case_description && item.case_description.case_name
        ? `${item.unique_number ? `${item.unique_number} ` : ''}${item.case_description.case_name}`
        : 'TBD',
    case_name: item?.case_description?.case_name || 'TBD',
    practice_area: item?.case_description?.case_practice_area,
    id: item.case_id,
  };
};

export const parseReminderData = (data = []) => {
  let _reminders = [];
  data.forEach((value) => {
    let _reminder = reminderOptions.find((index) => index?.value === value?.reminder_expression);
    if (_reminder) {
      _reminders.push({
        value: _reminder.value,
        count: 1,
        type: 'minutes',
        reminder_channel_type: value?.reminder_channel_type,
      });
    } else {
      let custom_reminder = value?.reminder_expression?.split('#');
      let obj = {
        value: 'custom',
        customValue: custom_reminder?.[0] ? parseInt(custom_reminder[0]) : 0,
        type: custom_reminder?.[1] ? custom_reminder[1] : 'minutes',
        reminder_channel_type: value?.reminder_channel_type,
      };
      _reminders.push(obj);
    }
  });
  return _reminders;
};

export const parseRemiderPayload = (data = []) => {
  return data?.map(({ value, customValue, type, reminder_channel_type }, i) => {
    if (value && value.includes('#')) {
      return {
        reminder_expression: value,
        reminder_channel_id: i,
        reminder_channel_type: reminder_channel_type,
      };
    } else if (customValue && type) {
      return {
        reminder_expression: `${customValue}#${type}#`,
        reminder_channel_id: i,
        reminder_channel_type: reminder_channel_type,
      };
    } else return {};
  });
};

const businessStartHour = 9; // 09:00
const businessEndHour = 18; // 18:00
function roundToNext30Minutes(time) {
  const minutes = time.minutes();

  // Round to the next 30-minute mark
  if (minutes > 0 && minutes <= 30) {
    return time.minutes(30).second(0).millisecond(0);
  } else if (minutes > 30) {
    return time.add(1, 'hour').minutes(0).second(0).millisecond(0);
  }
  return time;
}

export function convertAndFilterSlots(data, timeZone) {
  const slots = [];

  data.forEach(([start, end]) => {
    let startTime = roundToNext30Minutes(moment(start).tz(timeZone));
    let endTime = moment(end).tz(timeZone);

    // Loop to create 1-hour slots within the start and end time
    while (startTime < endTime) {
      if (!startTime.isSame(moment(start).tz(timeZone), 'day') && startTime.hour() === businessStartHour) {
        startTime = startTime.minutes(0).second(0).millisecond(0);
      }
      let slotEndTime = moment(startTime).add(1, 'hour');

      // Check if slot end time is within the specified range
      if (
        startTime.hour() >= businessStartHour &&
        slotEndTime.hour() <= businessEndHour &&
        slotEndTime.hour() > businessStartHour &&
        !slotEndTime.isAfter(endTime)
      ) {
        slots.push(startTime.format());
      }

      // Move to the next 1-hour slot
      startTime = slotEndTime;
    }
  });

  return slots;
}

export function getQueryParams(url) {
  const params = new URL(url).searchParams;
  const queryParams = {};

  for (const [key, value] of params.entries()) {
    queryParams[key] = value;
  }

  return queryParams;
}

export const getLeadIframe = (url) => {
  return `<iframe src="${url}" title="Contact Us Form" style="border:none;" width="100%" height="500"><!-- Specify a width and height by changing the width/height properties of this iframe --></iframe>`;
};

export const clientIntakeGenerator = (data) => {
  const caseData = data?.case_data || {};
  const contactData = data?.contact_data || {};
  const customFields = [...(caseData?.case_description?.custom_field || []), ...(contactData?.custom_field || [])];
  return {
    ...(data || {}),
    form_data: {
      ...(data?.form_data || {}),
      form_data: data?.form_data?.form_data?.map((item) => {
        if (item?.res_type === 'custom-field') {
          const customField = customFields?.find((val) => val.custom_field_id === item.custom_field_id);
          return {
            ...item,
            value: customField?.value || '',
            listOptions: customField?.listOptions || [],
          };
        } else return item;
      }),
      contact_data: contactData,
    },
  };
};

export const mergeValueField = (arr1, arr2) => {
  // Create a lookup map for `array2` with `custom_field_id` as the key
  const map = new Map(arr2?.map((item) => [item?.custom_field_id, item]));

  // Merge values from `arr1` and append unmatched items from `arr2`
  const merged = arr1?.map((item) => ({
    ...item,
    ...(map.has(item?.custom_field_id) ? { value: map.get(item?.custom_field_id)?.value } : {}), // Add `value` if exists
  }));

  // Find items in `arr2` not present in `arr1` by `custom_field_id`
  const unmatched = arr2?.filter((item2) => !arr1?.some((item1) => item1?.custom_field_id === item2?.custom_field_id));

  // Return the merged array with unmatched items appended
  return [...merged, ...unmatched];
};

export const formatClientIntakePayload = (formData, data) => {
  const caseCustomFields = data?.form_data?.filter((item) => item?.res_type === 'custom-field' && item?.module === 'case');
  const contactCustomFields = data?.form_data?.filter((item) => item?.res_type === 'custom-field' && item?.module === 'contact');

  const UpdatedCaseCustomFields = mergeValueField(formData?.case_data?.case_description?.custom_field || [], caseCustomFields);
  const UpdatedContactCustomFields = mergeValueField(formData?.contact_data?.custom_field || [], contactCustomFields);

  return {
    ...(formData || {}),
    contact_data: {
      ...(data?.contact_data || {}),
      custom_field: UpdatedContactCustomFields || [],
    },
    case_data: {
      ...(formData?.case_data || {}),
      case_description: {
        ...(formData?.case_data?.case_description || {}),
        custom_field: UpdatedCaseCustomFields || [],
      },
    },
    form_data: {
      ...(formData?.form_data || {}),
      contact_data: undefined,
      form_data: data?.form_data || [],
    },
  };
};

export const generateFormPdf = (data) => {
  const doc = new jsPDF({
    unit: 'pt',
    format: 'a4',
    orientation: 'portrait',
  });

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  const PADDING = 30; // Define padding value for left, right, top, and bottom
  const contentWidth = pageWidth - 2 * PADDING; // Adjust content width with padding
  let y = PADDING; // Adjust initial Y position for padding

  const LINE_HEIGHT = 20; // Line spacing
  const TAB_INDENT = 20; // Tab spacing for subsections
  const LABEL_WIDTH = 180; // Fixed width for labels

  // Handle page overflow
  const checkPageOverflow = (doc, currentY) => {
    if (currentY + LINE_HEIGHT >= pageHeight - PADDING) {
      // Adjust for bottom padding
      doc.addPage();
      return PADDING; // Reset y position for new page with padding
    }
    return currentY;
  };

  const addSpacing = (height = LINE_HEIGHT) => {
    y += height;
    y = checkPageOverflow(doc, y);
  };

  const addSectionHeader = (text) => {
    y = checkPageOverflow(doc, y);
    doc.setFontSize(16);
    doc.setTextColor('#2B5ECA');
    doc.text(text, PADDING, y); // Use padding for text placement
    y += LINE_HEIGHT / 2; // Add spacing after text
    doc.setDrawColor('#2B5ECA');
    doc.setLineWidth(1);
    doc.line(PADDING, y, pageWidth - PADDING, y); // Draw a line with padding
    y += LINE_HEIGHT; // Add extra spacing after the border
  };

  const addSubsectionHeader = (text) => {
    y = checkPageOverflow(doc, y);
    doc.setFontSize(14);
    doc.setTextColor('#2B5ECA');
    doc.text(text, PADDING + TAB_INDENT, y); // Adjust for left padding
    y += LINE_HEIGHT; // Add spacing after subsection header
  };

  const addField = (label, value, isSubsection = false, isDate = false, dateFormat, type, time) => {
    y = checkPageOverflow(doc, y);
    const x = isSubsection ? PADDING + TAB_INDENT : PADDING;

    // Determine how to display the value
    let displayValue = '-';
    if (type === 'time') {
      displayValue = time;
    } else if (Array.isArray(value)) {
      displayValue = value?.join(', ').replace(/_/g, ' ');
    } else if (typeof value === 'boolean') {
      displayValue = value ? 'Yes' : 'No';
    } else if (isDate) {
      displayValue = value ? moment(value).format(dateFormatMap.get(dateFormat) ?? 'MM-DD-YYYY') : '-';
    } else if ((typeof value === 'number' || typeof value === 'string') && value !== '') {
      displayValue = String(value);
    } else {
      displayValue = '-'; // Default fallback for unsupported types
    }

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(12);
    doc.setTextColor('#000');
    doc.text(`${label}:`, x, y, { maxWidth: LABEL_WIDTH });

    // Calculate the available width for the value text
    const availableWidth = contentWidth - LABEL_WIDTH - 10;

    // Split long text into lines that fit within the available width
    const wrappedText = doc.splitTextToSize(displayValue, availableWidth);

    // Draw the wrapped text, and make sure it doesn't overlap the next field
    doc.setFont('helvetica', 'normal');
    wrappedText.forEach((line, index) => {
      doc.text(line, x + LABEL_WIDTH + 10, y + LINE_HEIGHT * index);
    });

    // Update y position after drawing the text (taking into account the wrapped lines)
    y += LINE_HEIGHT * wrappedText.length; // Adjust for the wrapped lines
  };

  const addFieldsGroup = (fields, isSubsection = false) => {
    fields.forEach(({ name, label, value, type, dateFormat, time, period }) => {
      const isDate = type === 'Date' || type === 'date';
      const timeVal = `${time} ${period}`;
      addField(label || name, value, isSubsection, isDate, dateFormat, type, timeVal);
    });
  };

  // Extract data
  const contactData = data?.contact_data || {};
  const formFields = data?.form_data?.form_data || data?.form_data || [];
  const caseCustomFields = formFields.filter((item) => item?.res_type === 'custom-field' && item?.module === 'case');
  const contactCustomFields = formFields.filter((item) => item?.res_type === 'custom-field' && item?.module === 'contact');
  const nonMappedFields = formFields.filter(
    (item) =>
      !(
        item?.res_type === 'custom-field' ||
        item?.field_type === 'contact' ||
        item?.type === 'divider' ||
        item?.type === 'heading' ||
        item?.type === 'paragraph'
      )
  );

  // Add Form Title
  addSpacing(25);
  doc.setFontSize(20);
  doc.setTextColor('#2B5ECA');
  doc.setFont('helvetica', 'bold');
  doc.text(`${data?.form_data?.form_name || 'Form'} Response`, pageWidth / 2, y, { align: 'center' });
  y += LINE_HEIGHT * 2;

  // Contacts Section
  addSectionHeader('Contact Details');
  addField('First Name', contactData.first_name);
  addField('Middle Name', contactData.middle_name);
  addField('Last Name', contactData.last_name);
  addField('Email', contactData.email);
  addField('Phone', contactData.phone);
  addField('Date of Birth', contactData.dob, null, true);

  addSpacing(10);

  if (['street', 'city', 'state', 'zip', 'country'].some((key) => contactData[key])) {
    // Address Section
    addSubsectionHeader('Address');
    ['street', 'city', 'state', 'zip', 'country'].forEach((key) => {
      addField(key.charAt(0).toUpperCase() + key.slice(1), contactData[key], true);
    });
    addSpacing(10);
  }

  // Contact Custom Fields
  if (contactCustomFields.length > 0) {
    addSubsectionHeader('Custom Fields');
    addFieldsGroup(contactCustomFields, true);
    addSpacing(20);
  }

  // Case Custom Fields
  if (caseCustomFields.length > 0) {
    addSectionHeader('Case Custom Fields');
    addFieldsGroup(caseCustomFields, false);
    addSpacing(20);
  }

  // Non-Mapped Fields
  if (nonMappedFields.length > 0) {
    addSectionHeader('Non-Mapped Fields');
    addFieldsGroup(nonMappedFields, false);
    addSpacing(20);
  }

  // Save the PDF
  const filename = `${data?.form_data?.form_name || data?.contact_data?.first_name}-response-${Date.now()}.pdf`;
  doc.save(filename);
};

export const uploadFilesWithConcurrency = async (files, concurrency, prefixName, onOverallProgress) => {
  let totalSize = files.reduce((acc, file) => acc + file.size, 0); // Total size of all files
  let uploadedSize = 0; // Tracks total uploaded bytes across all files

  SetS3Config(FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL);

  // Tracks progress for each file
  const fileProgressMap = new Map(); // Maps file.name -> current uploaded size

  const updateOverallProgress = (fileName, loadedBytes) => {
    const previousUploaded = fileProgressMap.get(fileName) || 0; // Get previous progress
    const delta = loadedBytes - previousUploaded; // Calculate new bytes uploaded since last update
    uploadedSize += delta; // Update total uploaded size
    fileProgressMap.set(fileName, loadedBytes); // Update file progress map

    // Emit overall progress
    const overallProgress = Math.min(Math.round((uploadedSize / totalSize) * 100), 100); // Cap at 100%
    onOverallProgress(overallProgress);
  };

  const uploadFileWithTracking = async (file) => {
    await Storage.put(prefixName + encodeURIComponent(file.name), file, {
      contentType: file.type,
      progressCallback(progress) {
        updateOverallProgress(file.name, progress.loaded); // Track progress
      },
      useAccelerateEndpoint: true,
    });

    // Mark the file as fully uploaded when done
    updateOverallProgress(file.name, file.size);
  };

  // Concurrency queue logic
  const uploadQueue = [...files];
  const activeUploads = Array.from({ length: concurrency }, async () => {
    while (uploadQueue.length > 0) {
      const file = uploadQueue.shift(); // Get next file from the queue
      await uploadFileWithTracking(file); // Upload the file
    }
  });

  // Wait for all uploads to complete
  await Promise.all(activeUploads);

  // Ensure progress is set to 100% when all uploads are complete
  onOverallProgress(100);
};

/**
 * Evaluate conditions for a given field
 * @param {array} fields - All fields in the form
 * @param {object} fieldData - The field data being evaluated
 * @returns {boolean} - True if all conditions are satisfied, false otherwise
 */
export function evaluateConditions(fields, fieldData) {
  // conditions
  const conditions = fields.reduce((acc, item) => {
    if (Array.isArray(item?.conditions) && item?.conditions?.find((v) => v?.targetField === fieldData?._id)) {
      return [...acc, ...item?.conditions?.filter((v) => v?.targetField === fieldData?._id)];
    } else return acc;
  }, []);

  if (!conditions || !Array.isArray(conditions)) {
    return true;
  }

  const evaluateCondition = (fieldValue, condition, value) => {
    const entryValue = fieldValue?.value;
    const entryType = fieldValue?.type;

    if (entryType === 'date' || entryType === 'Date') {
      if (!Boolean(value) || !Boolean(entryValue) || !moment(value).isValid() || !moment(entryValue).isValid()) {
        return false;
      }
      const entryDate = moment(entryValue).startOf('day');
      const checkValue = moment(value).startOf('day');

      switch (condition) {
        case 'equals':
          return entryDate.isSame(checkValue);
        case 'does-not-equal':
          return !entryDate.isSame(checkValue);
        case 'is-empty':
          return !entryDate.isValid();
        case 'is-not-empty':
          return entryDate.isValid();
        case 'greater-than':
          return entryDate.isAfter(checkValue);
        case 'less-than':
          return entryDate.isBefore(checkValue);
        default:
          return false;
      }
    } else {
      switch (condition) {
        case 'equals':
          return entryValue === value;
        case 'does-not-equal':
          return entryValue !== value;
        case 'is-empty':
          return entryValue == null || entryValue === '' || entryValue === undefined;
        case 'is-not-empty':
          return entryValue != null && entryValue !== '' && entryValue !== undefined;
        case 'contains':
          return typeof entryValue === 'string' && entryValue?.includes(value);
        case 'does-not-contains':
          return (
            (typeof entryValue === 'string' && !entryValue?.includes(value)) ||
            entryValue == null ||
            entryValue === '' ||
            entryValue === undefined
          );
        case 'greater-than':
          return typeof entryValue === 'number' && entryValue > value;
        case 'less-than':
          return typeof entryValue === 'number' && entryValue < value;
        default:
          return false;
      }
    }
  };

  const evaluateSubConditions = (subConditions = [], fieldValue, operator = 'and') => {
    if (!subConditions || subConditions.length === 0) {
      return true; // Default to true if no subconditions
    }

    return subConditions.reduce((result, subCondition, index) => {
      const { condition, value } = subCondition;

      const conditionResult = evaluateCondition(fieldValue, condition, value);

      if (index === 0) {
        return conditionResult;
      }

      return operator === 'and' ? result && conditionResult : result || conditionResult;
    }, true);
  };

  const results = [];
  conditions.forEach((condition) => {
    const { subConditions = [], operator, sourceField } = condition;
    const fieldValue = fields.find((field) => field._id === sourceField);
    const isSatisfied = evaluateSubConditions(subConditions, fieldValue, operator);
    results.push(isSatisfied);
  });

  return results.every((result) => Boolean(result));
}

export const getUserStatusLabel = (option) => {
  if (option) {
    if (option?.is_confirmed === false && (option?.status === 'ACTIVE' || option?.status === undefined)) {
      return ' (Unconfirmed)';
    } else if ((option?.is_confirmed || option?.is_confirmed === undefined) && option?.status === 'ARCHIVED') {
      return ' (Archived)';
    } else if (option?.is_confirmed === false && option?.status === 'ARCHIVED') {
      return ' (Archived)';
    }
  }
  return '';
};
