/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ModalHeader } from 'shared/ModalHeader';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { updateRelation } from 'services/relationServices';
import { toastConstant } from 'constants/toastmessage';
import { useToast } from 'context/ToastContext';
import { getClientUserList } from 'services/CaseServices';
import { contactDetails, updateContactDetails } from 'services/contact';
import { toCapitalized } from 'utils/utils';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'), // Added required validation
});

export const EnableLawftAccessModal = ({
  visible,
  onHide,
  enableLawftAccessBillingContact,
  isLeadSpecific,
  selectedCase,
  caseDetailsState,
  setBillingContacts,
}) => {
  const initialValues = {
    email: enableLawftAccessBillingContact?.email || '', // Pre-fill with existing email if available
  };

  const { addToast } = useToast();

  const onSubmit = (values) => {
    // Load contact details for the selected contact_id
    contactDetails('', '', enableLawftAccessBillingContact?.contact_id)
      .then((response) => {
        if (response?.data) {
          // Update the contact details with the new email and set is_create_client_portal to true
          const contactFinalData = {
            ...response?.data,
            email: values.email, // Update the email from form values
            is_create_client_portal: true, // Set this to true as required
          };

          // Call updateContactDetails with the modified contact data
          updateContactDetails(contactFinalData)
            .then(() => {
              // Show success toast message
              addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.CONTACT_UPDATE_SUCCESS);
              // Fetch updated client user list
              let user_list = [];
              getClientUserList(selectedCase?.id, false, false, true, false, isLeadSpecific ? true : false, false, false)
                .then((response) => {
                  if (response?.data && response.data.length > 0) {
                    response.data.forEach((index) => {
                      let name = index.first_name ? index.first_name : '';
                      if (index.last_name) name += ` ${index.last_name}`;

                      let obj = {
                        name: name,
                        ...index,
                      };
                      user_list.push(obj);
                    });

                    // Update the billing contacts state
                    setBillingContacts([...user_list]);
                  }
                })
                .catch((error) => {
                  console.error('Error fetching updated client user list:', error);
                });

              // Reload contact details (if necessary, to refresh data in UI)
              // loadContactDetails('', '', enableLawftAccessBillingContact?.contact_id);

              // Close the modal after successful update
              onHide();
            })
            .catch((err) => {
              // Handle the error and show an error toast message
              let msg = toastConstant.message.CONTACT_UPDATE_FAILED;
              if (err.response && err.response.data) msg = toCapitalized(err.response.data);
              addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, msg);
            });
        }
      })
      .catch((err) => {
        // Handle error in loading contact details
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, 'Failed to load contact details');
      });
  };

  return (
    <Dialog header={ModalHeader} closable={false} visible={visible} modal onHide={onHide} resizable={false} className="create-msg-popup">
      <GlobalLoader />
      <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
        <div className="modal-heading F-size16 text-bold">
          {`Enable Lawft Access for ${enableLawftAccessBillingContact?.title} ${enableLawftAccessBillingContact?.name}?`}
        </div>
      </div>
      <div>
        <div className="d-flex align-items-center">
          <i className="p-confirm-dialog-icon pi pi-exclamation-triangle"></i>
          <p className="ms-2 mb-0">
            Proceeding with this action will enable Lawft access for {enableLawftAccessBillingContact?.title}{' '}
            {enableLawftAccessBillingContact?.name}.
          </p>
        </div>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ handleBlur, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              {(enableLawftAccessBillingContact?.email === '' || enableLawftAccessBillingContact?.email === undefined) && (
                <>
                  <p className="mb-0 mt-3">Please add an email ID for this contact:</p>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-12 col-md-4">
                      <label className="p-medium">
                        Email<span style={{ color: 'red' }}>*</span>
                      </label>
                    </div>
                    <div className="col-12 col-md-8">
                      <Field type="email" id="email" name="email" className="input-shadow w-100 border-0 p-2" onBlur={handleBlur} />
                      <ErrorMessage name="email" component="div" className="text-danger small" />
                    </div>
                  </div>
                </>
              )}
              <div className="footer-btn mt-3">
                <Button className="p-button-secondary outline mx-2" label="Cancel" onClick={onHide} />
                <Button
                  label="Enable"
                  className="p-button p-button-secondary"
                  type="submit" // Changed to type="submit" to handle form submission correctly
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};
