import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import Dropzone from 'react-dropzone';

import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import LabelName from 'components/UI/LabelName/LabelName';
import { useSecureMessageContext } from '../Context/SecureMessageContext';
import { useUserDetailsContext } from 'context/userDetailsContext';
import CaseListDropDown from 'components/CaseListDropDown/CaseListDropDown';
import useExistingFiles from 'hooks/useExistingFiles';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

function MessageCreateForm({
  onSubmit,
  handleSubmit,
  errors,
  control,
  watch,
  attendeesList,
  eventData,
  setFiles,
  files,
  clientPortalDashboard,
  handleClose,
  getCaseClients,
}) {
  const { isGlobalMessagesScreen, isFromClientPortal } = useSecureMessageContext();
  const { checkSubPermission } = useUserDetailsContext();
  const { existingFiles, getExistingFiles } = useExistingFiles();

  const caseId = watch('caseId');

  const onChangeFile = (filesData) => {
    setFiles([...files, ...filesData]);
  };

  const [filteredAttendeesList, setFilteredAttendeesList] = useState();

  useEffect(() => {
    if (caseId) {
      getExistingFiles(`&case_id=${isGlobalMessagesScreen ? caseId.id : caseId}&format=`);
    }
  }, [caseId]);

  useEffect(() => {
    setFilteredAttendeesList(attendeesList?.filter((item) => item?.label !== 'Groups'));
  }, [attendeesList, isFromClientPortal]);

  function MessageToOptionTemplate(e) {
    if (e?.res_type === 'user-group') {
      return <div>{e?.label}</div>;
    } else {
      let isClient = e.access_level === 'client';

      return (
        <div>
          {e?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
          {e?.label}{' '}
          {(isClient || !e.contact_id) && !e.email
            ? '( Email address not found )'
            : (isClient || e.contact_id) && !e.is_create_client_portal
            ? '(Disabled Lawft Access  )'
            : e?.status === 'ARCHIVED' && !e.contact_id
            ? ` (Inactive)`
            : ''}
        </div>
      );
    }
  }

  function checkOptionDisabled(index) {
    if ((index.contact_id || index.access_level === 'client') && index?.res_type !== 'user-group') {
      return index.email === '' || !index.is_create_client_portal ? true : false;
    }
    return false;
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="F-size14 message-create-form">
      <div className="d-flex justify-content-between align-items-center mb-3 mt-3">
        <div className="modal-heading F-size16 text-bold">Create New Message</div>
      </div>
      <div className="d-flex align-items-center py-2">
        <div className="col-12">
          <div>
            {/* case list dropdown */}
            <>
              {(isGlobalMessagesScreen || clientPortalDashboard) && (
                <div className="d-flex align-items-center py-2 flex-wrap">
                  <div className="col-md-4 col-12">
                    <LabelName required={true} htmlFor="caseId">
                      Case/Lead Name
                    </LabelName>
                  </div>
                  <div className="col-md-8 col-12">
                    <Controller
                      className={'w-100 input-shadow'}
                      name="caseId"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CaseListDropDown
                          isClient={isFromClientPortal}
                          value={field.value}
                          onChange={(e) => {
                            if (field.value !== e) {
                              field.onChange(e);
                              if (e?.id) {
                                getCaseClients(e?.id); // Call getCaseClients only when a value is selected
                              }
                            }
                          }}
                          placeholder="By Case/Lead"
                          noOptionsMessage="No Cases/Leads Found"
                          name="case"
                          disabled={clientPortalDashboard}
                        />
                      )}
                    />
                    {errors.caseId && <InputErrorMessage>Please select a case/lead</InputErrorMessage>}
                  </div>
                </div>
              )}
            </>
            {/* Recipient multi select dropdown */}
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-md-4 col-12">
                <LabelName required={true} htmlFor="messageTo">
                  To
                </LabelName>
              </div>

              <div className="col-md-8 col-12">
                <Controller
                  name="messageTo"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <MultiSelect
                      id={field.name}
                      value={field.value}
                      placeholder="Select a Recipient"
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      display="chip"
                      className="input-shadow w-100"
                      filter
                      disabled={!watch('caseId')}
                      // options={attendeesList}
                      options={!isFromClientPortal ? attendeesList || [] : filteredAttendeesList || []}
                      optionLabel="label"
                      // optionValue="value"
                      optionGroupLabel="label"
                      optionGroupChildren="items"
                      optionDisabled={(index) => checkOptionDisabled(index)}
                      itemTemplate={MessageToOptionTemplate}
                      emptyFilterMessage="No records found"
                      showSelectAll={false}
                    />
                  )}
                />
                {errors.messageTo?.type === 'required' ? (
                  <InputErrorMessage>Please select a Recipient</InputErrorMessage>
                ) : errors.messageTo?.type === 'validate' ? (
                  <InputErrorMessage>The contact you selected has no email address to receive messages</InputErrorMessage>
                ) : null}
              </div>
            </div>
            {/* Subject input */}
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-md-4 col-12">
                <LabelName required={true} htmlFor="subject">
                  Subject
                </LabelName>
              </div>
              <div className="col-md-8 col-12">
                <Controller
                  name="subject"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextSnippetWrapper type="text" id={field.name} {...field} placeholder="Subject" className={'w-100 input-shadow'} />
                  )}
                />
                {errors.subject && <InputErrorMessage>Please Enter subject name</InputErrorMessage>}
              </div>
            </div>
            {/* Message Editor input */}
            <div className="d-flex align-items-start py-2 flex-wrap">
              <div className="col-md-4 col-12">
                <LabelName required={true} htmlFor="message">
                  Message
                </LabelName>
              </div>
              <div className="col-md-8 col-12">
                <Controller
                  control={control}
                  name="message"
                  className="shadow-small"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <TextSnippetWrapper type="editor" value={value} style={{ height: '150px' }} onChange={(e) => onChange(e)} />
                  )}
                />
                {errors?.message && <InputErrorMessage>Please enter your message</InputErrorMessage>}
              </div>
            </div>
            {/* optional Heading */}
            <div className="my-3">
              <div className="col-md-12 col-12">
                <h5 className="modal-heading F-size16 text-bold mb-0">Optional</h5>
              </div>
            </div>
            {/* Attachments input*/}
            <div className="d-flex align-items-start py-2 flex-wrap">
              <div className="col-md-4 col-12 mt-2">
                <LabelName required={false} htmlFor="message">
                  Attachments
                </LabelName>
              </div>
              <div className="col-md-8 col-12">
                <Dropzone
                  onDrop={(acceptedFiles) => onChangeFile(acceptedFiles)}
                  accept="application/pdf, application/msword, .docx, .doc, audio/*, video/*,image/*"
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className="text-center font-bold attachmnet-input input-shadow mb-0">
                          Click or drag file to this area to upload
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
                <ul>
                  {files &&
                    files.length > 0 &&
                    files.map((val, index) => (
                      <div className="d-flex mt-2">
                        <i className="fas fa-paperclip me-2 mt-1"></i>
                        <li key={index} className="text-break">
                          {val.name}
                        </li>
                        <i
                          className="icon-delete ms-2 mt-1 pointer"
                          onClick={() => {
                            let removedFiles = files.filter((data) => data.path !== val.path);
                            setFiles(removedFiles);
                          }}
                        ></i>
                      </div>
                    ))}
                </ul>
              </div>
            </div>
            {/* tasks and events */}
            {!Boolean(isFromClientPortal) && (
              <>
                <div className="d-flex align-items-center py-2 flex-wrap">
                  <div className="col-md-4 col-12">
                    <LabelName required={false} htmlFor="event_attachment">
                      Event
                    </LabelName>
                  </div>
                  <div className="col-md-8 col-12">
                    <Controller
                      name="event_attachment"
                      control={control}
                      render={({ field }) => (
                        <MultiSelect
                          options={
                            eventData?.events?.length === 0
                              ? [
                                  {
                                    label: 'No Records Found',
                                    value: 'no-records-found',
                                  },
                                ]
                              : eventData?.events
                          }
                          optionLabel="label"
                          optionValue="value"
                          optionDisabled={(index) => index.value === 'no-records-found'}
                          display="chip"
                          className="input-shadow w-100"
                          filter
                          id={field.name}
                          value={field.value}
                          placeholder="Select a Event"
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          showSelectAll={eventData?.events?.length > 0}
                          emptyFilterMessage="No records found"
                          disabled={!watch('caseId')}
                          // showSelectAll={eventData?.events?.length > 0}
                        />
                      )}
                    />
                  </div>
                </div>

                {checkSubPermission('task_management') && (
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12">
                      <LabelName required={false} htmlFor="task_attachment">
                        Task
                      </LabelName>
                    </div>
                    <div className="col-md-8 col-12">
                      <Controller
                        name="task_attachment"
                        control={control}
                        render={({ field }) => (
                          <MultiSelect
                            options={
                              eventData?.tasks?.length === 0
                                ? [
                                    {
                                      label: 'No Records Found',
                                      value: 'no-records-found',
                                    },
                                  ]
                                : eventData?.tasks
                            }
                            optionLabel="label"
                            optionValue="value"
                            optionDisabled={(index) => index.value === 'no-records-found'}
                            display="chip"
                            className="input-shadow w-100"
                            filter
                            id={field.name}
                            value={field.value}
                            placeholder="Select a Task"
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            emptyFilterMessage="No records found"
                            showSelectAll={eventData?.tasks?.length > 0}
                            disabled={!watch('caseId')}
                            // showSelectAll={eventData?.tasks?.length > 0}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-md-4 col-12">
                <LabelName required={false} htmlFor="existing_files">
                  Attach Existing Files
                </LabelName>
              </div>
              <div className="col-md-8 col-12">
                <Controller
                  name="existing_files"
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <MultiSelect
                      options={
                        existingFiles?.length === 0
                          ? [
                              {
                                label: 'No Records Found',
                                value: 'no-records-found',
                              },
                            ]
                          : existingFiles
                      }
                      className="input-shadow w-100"
                      placeholder="Select Existing Files"
                      display="chip"
                      value={value}
                      filter
                      onChange={(e) => {
                        onChange(e.value);
                      }}
                      disabled={!watch('caseId')}
                      optionLabel="label"
                      optionValue="value"
                      optionDisabled={(index) => index.value === 'no-records-found'}
                      showClear
                      resetFilterOnHide
                      id={name}
                      emptyFilterMessage="No records found"
                      showSelectAll={false}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="footer-btn">
            <Button type="button" label="Cancel" className="p-button p-button-secondary outline me-2" onClick={handleClose} />
            <Button type="submit" label="Send" className="p-button p-button-secondary" />
          </div>
        </div>
      </div>
    </form>
  );
}

export default MessageCreateForm;
