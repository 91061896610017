export const generalCategoryContactList = [
  {
    label: 'Primary Contact',
    value: 'primary_contact',
  },
  {
    label: 'Billing Contact',
    value: 'billing_contact',
  },
];

export const teamMemberRolesList = [
  { label: 'Lead Attorney', value: 'lead_attorney', code: 'TEAM_MEMBER_ROLE' },
  { label: 'Lead Associate', value: 'lead_associate', code: 'TEAM_MEMBER_ROLE' },
  { label: 'Lead Paralegal', value: 'lead_paralegal', code: 'TEAM_MEMBER_ROLE' },
  { label: 'Lead Case Manager', value: 'lead_case_manager', code: 'TEAM_MEMBER_ROLE' },
];

// export const dueDateDropdownList = [
//   { id: 0, singular: "Day", plural: "Days" },
//   { id: 1, singular: "Week", plural: "Weeks" },
//   { id: 2, singular: "Month", plural: "Months" },
// ];

export const MINUTES = 'Minute (s)';
export const HOURS = 'Hour (s)';
export const DAYS = 'Day (s)';
export const WEEKS = 'Week (s)';
export const MONTHS = 'Month (s)';
export const YEARS = 'Year (s)';

//AUTOMATION time tracker toggle button name and label
export const TIME_TRACKER_LABEL = 'Track Time for this Action';
export const TIME_TRACKER_NAME = 'trackActionTime';

export const timeTrackerDuration = {
  durationLabel: 'Time Duration',
  durationName: 'manualTotalHours',
  durationTooltipMessage:
    'If you leave this field as empty or with zero value, the time duration will automatically be set to system generated duration.',
};

export const dueDateDropdownList = [
  { label: DAYS, value: DAYS },
  { label: WEEKS, value: WEEKS },
  { label: MONTHS, value: MONTHS },
];

export const timeIntervals = [
  { label: '1 day before', value: '1#days#' },
  { label: '3 days before', value: '3#days#' },
  { label: '7 days before', value: '7#days#' },
];

//Commented due to client requirements
// export const reminderOptions = [
//   { label: '30 minutes before the meeting', value: '30#minutes#' },
//   { label: 'The day before at 12:30 pm', value: '1#days#12:30' },
//   { label: '2 days before at 12:30 pm', value: '2#days#12:30' },
//   { label: '1 week before at 12:30 pm', value: '1#weeks#12:30' },
//   { label: 'custom', value: 'custom' },
// ];

export const reminderOptions = [
  { label: '30 minutes before the meeting', value: '30#minutes#' },
  { label: '1 day before the meeting', value: '1#days#' },
  { label: '2 days before the meeting', value: '2#days#' },
  { label: '1 week before the meeting', value: '1#weeks#' },
  { label: 'Custom', value: 'custom' },
];

//Add automation rule
export const repeateActionIntervalOptions = [
  { label: 'Minutes', value: 'minutes' },
  { label: 'Hours', value: 'hours' },
  { label: 'Days', value: 'days' },
  { label: 'Weeks', value: 'weeks' },
  { label: 'Months', value: 'months' },
  { label: 'Years', value: 'years' },
];

export const reminderCustomOptions = [
  { label: MINUTES, value: 'minutes' },
  { label: HOURS, value: 'hours' },
  { label: DAYS, value: 'days' },
  { label: WEEKS, value: 'weeks' },
];

export const delayCardReminderCustomOptions = [
  ...reminderCustomOptions,
  { label: MONTHS, value: 'months' },
  { label: YEARS, value: 'years' },
];

// Events contants
export const eventTirggerLabels = {
  EVENT_CREATED: 'Event Created',
  EVENT_CANCELLED: 'Event Cancelled',
  EVENT_DATETIME_CHANGED: 'Event Date Or Time Changed',
};

// Tasks contants
export const taskTiggerLabels = {
  NEW_TASK_CREATED: 'New Task Created',
  TASK_STATUS_CHANGED: 'Task Status Changed',
};

// Billing constants
export const billingTiggerLabels = {
  NEW_PAYMENT_RECEIVED: 'New Payment Received',
  PAYMENT_REFUNDED: 'Payment Refunded',
  PAYMENT_PAST_DUE: 'Payment Past Due',
  INVOICE_PAST_DUE: 'Invoice Past Due',
};

//Case constants
export const caseTiggerLabels = {
  CASE_CREATED: 'Case Created',
  CASE_STATUS_CHANGED: 'Case Status Changed',
  CASE_CLOSED: 'Case Closed',
  CASE_STAGE_CHANGED: 'Case Stage Changed',
  REVIEW_REQUEST_SENT: 'Review Request Sent',
};

//File constants
export const fileTiggerLabels = {
  NEW_FILE_CREATED: 'New File Created',
  NEW_TEMPLATE_CREATED: 'New Template Created',
  ESIGANATURE_STATUS_CHANGED: 'E-Signature Status Changed',
  NEW_FILE_UPLOADED: 'New File Uploaded',
};

//Contact constants
export const contactTiggerLabels = {
  NEW_CONTACT_CREATED: 'New Contact Created',
};

//Leads constants
export const leadTiggerLabels = {
  LEAD_CREATED: 'Lead Created',
  LEAD_STATUS_CHANGED: 'Lead Status Changed',
  LEAD_CONVERTED_TO_CASE: 'Lead Converted To Case',
  LEAD_STAGE_CHANGED: 'Lead Stage Changed',
};

export const stausOptionLabels = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const statusOptions = [
  {
    label: stausOptionLabels?.ACTIVE,
    value: stausOptionLabels?.ACTIVE,
  },
  {
    label: stausOptionLabels?.INACTIVE,
    value: stausOptionLabels?.INACTIVE,
  },
];

//pagination data
export const initialPagiatonData = {
  pageLimit: 10,
  apiTransitionToken: '',
  apiNextToken: '',
  isForward: true,
};

// ===========================  Automation Filter Section Starts =================================== //

export const BY_PRACTICE_AREA = 'By Practice Area';
export const VIEWED_UNVIEWED = 'By Viewed/ Unviewed';
export const IS_GREATER_THAN = 'is greater than';

export const INCLUDES = 'includes';
export const IS_NOT = 'is not';
export const IS = 'is';
export const BEFORE = 'before';
export const AFTER = 'after';
export const ON_OR_BEFORE = 'on or before';
export const ON_OR_AFTER = 'on or after';
export const IS_VIEWED = 'is_viewed';

//Event Filter Options Labels
export const BY_EVENT_GUESTS = 'By Event Guests';
export const BY_EVENT_LOCATION = 'By Event Location';
export const BY_EVENT_TYPE = 'By Event Type';

// Task Filter Options Labels
export const BY_TASK_PRIORITY = 'By Task Priority';
export const BY_TASK_DUE_DATE = 'By Task Due Date';

// Case Filter Options Labels
export const BY_CASE_MATTER = 'By Case Matter';
export const BY_CASE_CLOSURE_REASON = 'By Case Closure Reason';
export const BY_CASE_OUTCOME = 'By Case Outcome';

//Lead Filter Options Labels
export const BY_LEAD_STAGE = 'By Lead Stage';
export const BY_CASE_STAGE = 'By Case Stage';
export const BY_POTENTIAL_CASE_VALUE = 'By Potential Case Value';
export const BY_TAG = 'By Global Tag';
export const BY_LEAD_PRIORITY = 'By Lead Priority';
export const LEAD_CONVERTED_TO_CASE = 'Lead Converted To Case';

//modules
export const EVENTS = 'EVENTS';
export const TASKS = 'TASKS';
export const BILLING = 'BILLING';
export const CASES = 'CASES';
export const FILES = 'FILES';
export const LEADS = 'LEADS';
export const CONTACTS = 'CONTACTS';
export const MESSAGES = 'MESSAGES';

export const filterCardModules = {
  EVENTS,
  TASKS,
  BILLING,
  CASES,
  FILES,
  CONTACTS,
  LEADS,
  MESSAGES,
};
export const automationModules = {
  EVENTS,
  TASKS,
  BILLING,
  CASES,
  FILES,
  CONTACTS,
  LEADS,
  MESSAGES,
};

export const isOrNotConditionOptions = [
  { label: IS_NOT, value: IS_NOT },
  { label: IS, value: IS },
];

export const viewedUnviewedOptions = [
  { label: 'Viewed', value: 'true' },
  { label: 'Not Viewed', value: 'false' },
];

export const includesConditionOptions = [{ label: INCLUDES, value: INCLUDES }];

export const dueDateConditionOptions = [
  { label: IS_NOT, value: IS_NOT },
  { label: IS, value: IS },
  { label: BEFORE, value: BEFORE },
  { label: AFTER, value: AFTER },
  { label: ON_OR_BEFORE, value: ON_OR_BEFORE },
  { label: ON_OR_AFTER, value: ON_OR_AFTER },
];

export const isGreaterThanOptions = [{ label: IS_GREATER_THAN, value: IS_GREATER_THAN }];

export const priorityOptions = [
  { label: 'No Priority', value: 'No Priority' },
  {
    label: 'Low',
    value: 'Low',
  },
  { label: 'Medium', value: 'Medium' },
  { label: 'High', value: 'High' },
];

export const CUSTOM_FILTER_ERROR = 'All field are required';

// =================================  Automation Filter Section Ends ===================================== //

// =================================  Automation Action Section Starts =================================== //

export const CREATE_EVENT = 'Create Event';
export const CREATE_TASK = 'Create Task';
export const SEND_SECURE_MESSAGE = 'Send Secure Message';
export const SEND_TEXT_MESSAGE = 'Send Text Message';
export const CREATE_FOLDER_STURCTURE = 'Create Folder Structure';
export const CREATE_CASE_FILE = 'Create Document From Template';

export const REQUIRED = 'Required'; //May be we can use a message "This Field is Required"
export const required = {
  required: REQUIRED,
};

export const FOLDER_ALREADY_EXISTS = 'Folders already exists';

// =================================  Automation Action Section Ends ======================================== //

//================================== Automation Error Log Constants Section Starts ==========================//

export const paginationDropdownOptions = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

export const automationErrorLogCodes = {
  userNotInCase: 'USER_NOT_IN_CASE',
  noLawftAccess: 'NO_LAWFT_ACCESS',
  noEmail: 'NO_EMAIL',
  noBillingContact: 'NO_BILLING_CONTACT',
  noPhoneNumber: 'NO_PHONE_NUMBER',
  invalidPhoneNumber: 'INVALID_PHONE_NUMBER',
  eventTimePassed: 'EVENT_TIME_PASSED',

  noLeadAttorney: 'NO_LEAD_ATTORNEY',
  noLeadAssociate: 'NO_LEAD_ASSOCIATE',
  noLeadParalegal: 'NO_LEAD_PARALEGAL',
  noLeadCaseManager: 'NO_LEAD_CASE_MANAGER',
  noLeadTeamMember: 'NO_LEAD_TEAM_MEMBER',

  userArchived: 'USER_ARCHIVED',
  userUnconfirmed: 'USER_UNCONFIRMED',
  senderArchived: 'SENDER_ARCHIVED',
  senderUnconfirmed: 'SENDER_UNCONFIRMED',
  groupMembersArchived: 'GROUP_MEMBERS_ARCHIVED',
  groupMembersUnconfirmed: 'GROUP_MEMBERS_UNCONFIRMED',
};

export const automationErrorLogCodeOptions = [
  { label: 'User Not In Case', value: automationErrorLogCodes?.userNotInCase },
  { label: 'No Lawft Access', value: automationErrorLogCodes?.noLawftAccess },
  { label: 'No Email', value: automationErrorLogCodes?.noEmail },
  {
    label: 'No Billing Contacts',
    value: automationErrorLogCodes?.noBillingContact,
  },
  { label: 'No Phone Number', value: automationErrorLogCodes?.noPhoneNumber },
  { label: 'No Lead Attorney', value: automationErrorLogCodes?.noLeadAttorney },
  { label: 'No Lead Associate', value: automationErrorLogCodes?.noLeadAssociate },
  { label: 'No Lead Paralegal', value: automationErrorLogCodes?.noLeadParalegal },
  { label: 'No Lead Case Manager', value: automationErrorLogCodes?.noLeadCaseManager },
  {
    label: 'Event Time Passed',
    value: automationErrorLogCodes?.eventTimePassed,
  },
  {
    label: 'User Archived',
    value: automationErrorLogCodes?.userArchived,
  },
  {
    label: 'User Unconfirmed',
    value: automationErrorLogCodes?.userUnconfirmed,
  },
  {
    label: 'Sender Archived',
    value: automationErrorLogCodes?.senderArchived,
  },
  {
    label: 'Sender Unconfirmed',
    value: automationErrorLogCodes?.senderUnconfirmed,
  },
  {
    label: 'Group Members Archived',
    value: automationErrorLogCodes?.groupMembersArchived,
  },
  {
    label: 'Group Members Unconfirmed',
    value: automationErrorLogCodes?.groupMembersUnconfirmed,
  },
];

export const automationErrorLogStatusOptions = [
  { label: 'Show All', value: 'showAll' },
  { label: 'Closed', value: 'closed' },
  { label: 'Open', value: 'open' },
];

/** Error fixing dialog form - validation constants for react-hook-form*/
export const formControlLabels = {
  firmUser: 'Firm User',
  email: 'Email',
  enableLawftAccess: 'Enable Lawft Access',
  phoneNumber: 'Phone Number',
  billingContacts: 'Billing Contacts',
  teamMembers: 'Team Members',
  attendees: 'Attendees',
  assignees: 'Assignees',
  to: 'To',
};
export const formControlNames = {
  email: 'email',
  lawftAccess: 'lawftAccess',
  phone: 'phone',
  billingContacts: 'billingContacts',
  teamMembers: 'teamMembers',
  firmUsers: 'firmUsers',
  senderUser: 'senderUser',
};

/**React-Hook-Form Validation Rules */
export const reactHookFormRegisterOptions = {
  //Vallidation rule for email
  email: {
    required: 'Required',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Invalid email address',
    },
  },

  //Vallidation rule for lawft-access
  lawftAccess: {
    required: 'Please enable the lawft access',
  },

  //Vallidation rule for phone-number
  phone: {
    required: 'Required',
    minLength: {
      value: 10,
      message: '10 Digits required',
    },
    maxLength: {
      value: 10,
      message: 'Only 10 digits are allowed',
    },
  },

  //Vallidation rule for billing-contacts
  billingContacts: {
    required: 'Please select minimum one contact',
  },
  //Vallidation rule for team member
  teamMembers: {
    required: 'Please select a team member',
  },
  firmUsers: {
    required: 'Please select minimum one user',
  },
  senderUser: {
    required: 'Please select minimum one user',
  },
};

export const daysPastDue = [
  { label: '30 Days', value: '30#days' },
  { label: '60 Days', value: '60#days' },
  { label: '90 Days', value: '90#days' },
];

//============================= Automation Error Log Constants Section Ends ==========================//

// Leads module trigger validation message for each action cards when a firm user is selected
export const LeadTriggerActionCardFirmUserSelectionValidationMessage =
  'You cannot add more than one firm user for this lead-specific action.';
