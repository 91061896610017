/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { listTimeEntries, deleteTimeEntry, updateTimeEntry } from 'services/timerServices';
import useSpinner from 'hooks/useSpinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useSelector, useDispatch } from 'react-redux';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { CreateTimer } from './CreateTimer';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useLocation } from 'react-router-dom';
import { dollarFormat, formatMultiSelectContactList, getTenantDetails, paginationDropdownOptions } from 'utils/utils';
import ImageComponent from 'shared/ImageComponent';
import { MultiSelect } from 'primereact/multiselect';
import { totalCountEvent } from 'services/generalServices';
import { Tooltip } from 'primereact/tooltip';
import { convertToTimezoneFormat, convertToTimezoneWithStartOfDayFormat, handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { UserDetailsContext } from 'context/userDetailsContext';
import constants from 'constants/index';
import { handlePageNumberInPagination } from 'common/pagination';
import { ModalHeader } from 'shared/ModalHeader';
import { generateFullName } from 'utils/generateFullNameUtils';
import { getPracticeAreaAction } from 'redux/actions/PracticeAreaAction';
import { billingOptions, statusOptions } from 'constants/dropdownOptions';
import moment from 'moment';
import { nofifyTimeManager } from 'redux/actions/TimeEntryUpdateActions';
import { listCasesAndLeads } from 'services/CaseServices';
import { AutoComplete } from 'primereact/autocomplete';
import debounce from 'lodash.debounce';
import { listUsers } from 'services/users/userServices';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import CaseListDropDown from 'components/CaseListDropDown/CaseListDropDown';

const TimeEntries = (props) => {
  const {
    is_automated,
    onTimeManageMent = false,
    isCaseClosed,
    caseDetailsPage,
    isLeadClosed,
    isLeadSpecific,
    isFromCaseSpecificModule,
    handleTimeEntryRedirection,
    getTimeEntrySummarty,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [timeData, setTimeData] = useState([]);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [skToDelete, setSkToDelete] = useState('');
  const [isLead, setIsLead] = useState(false);
  const [editDataObject, setEditDataObject] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();
  const { addToast } = useToast();
  const practiceData = useSelector((state) => state.practiceArea.practiceAreaList['practice-area']);
  const timeEntryNotificationState = useSelector((state) => state.timeEntryUpdateNotification);
  const userContext = useContext(UserDetailsContext);
  const userList = useSelector((state) => state.userList.userList.firm_users);
  const [firmUserList, setFirmUserList] = useState([]);
  const [practiceAreaOptions, setPracticeAreaOptions] = useState([]);
  const [pageLimit, setPageLimit] = useState(getPageLimit() ?? 10);
  const [isForward, setIsForward] = useState(true);
  const [searchKey, setSearchKey] = useState('');
  const [spinner, showSpinner, hideSpinner] = useSpinner(true);
  const [showCreateTimer, setShowCreateTimer] = useState(false);

  const [searchValue, setSearchValue] = useState();
  const [load, setLoad] = useState(false);
  const [showUserFilter, setShowUserFilter] = useState(false);
  const [tenantId] = getTenantDetails();

  useEffect(() => {
    setShowCreateTimer(Boolean(location?.state?.createTimeEntry));
  }, [location]);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedCaseId, setSelectedCaseId] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedPractice, setSelectedPractice] = useState(null);
  const [selectedBillingStatus, setSelectedBillingStatus] = useState();
  const [caseFullList, setCaseFullList] = useState();
  const ref = useRef(null);

  // const { caseAndLeadsList } = useCaseAndLeadsList();

  const next_Token = timeData.next_token;
  const transition_Token = timeData.transition_token;

  function getAllCaseList(is_lead = false, case_id = '', exclude_pi_case = false, keyword = '', limit = 15, is_filter_dropdown = true) {
    listCasesAndLeads(is_lead, case_id, exclude_pi_case, keyword, limit, is_filter_dropdown)
      .then((res) => {
        let tempArray = [];
        res?.data?.case_list?.forEach((item) => {
          tempArray.push({
            label:
              item && item.case_description && item.case_description.case_name
                ? `${item.unique_number ? `${item.unique_number} ` : ''}${item.case_description.case_name}`
                : 'TBD',
            value: item.case_description.case_name,
            id: item?.case_id,
          });
        });
        if (!props?.caseView && !props?.isLeadSpecific && !is_automated) {
          res?.data?.lead_list?.forEach((item) => {
            tempArray.push({
              label:
                item && item.case_description && item.case_description.case_name
                  ? `${item.unique_number ? `${item.unique_number} ` : ''}${item.case_description.case_name}`
                  : 'TBD',
              value: item.case_description.case_name,
              id: item?.case_id,
              is_lead: item?.is_lead,
            });
          });
        }
        setCaseFullList(tempArray);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getPageLimit() {
    if (isLeadSpecific && userContext) {
      return is_automated
        ? userContext?.firmDetails?.pagination_preference?.leads?.billings?.automated_time_entries
        : userContext?.firmDetails?.pagination_preference?.leads?.billings?.manual_time_entries;
    } else if (isFromCaseSpecificModule && userContext) {
      return is_automated
        ? userContext?.firmDetails?.pagination_preference?.case?.billings?.automated_time_entries
        : userContext?.firmDetails?.pagination_preference?.case?.billings?.manual_time_entries;
    } else if (onTimeManageMent && userContext) {
      return is_automated
        ? userContext?.firmDetails?.pagination_preference?.settings?.time_manager?.automated_time_manager
        : userContext?.firmDetails?.pagination_preference?.settings?.time_manager?.manual_time_manager;
    } else if (props.isFromBilling && userContext) {
      return is_automated
        ? userContext?.firmDetails?.pagination_preference?.billings?.automated_time_entries
        : userContext?.firmDetails?.pagination_preference?.billings?.manual_time_entries;
    }
  }

  function getFilePathForPagination() {
    if (isLeadSpecific && userContext) {
      return { module: 'leads', subModule: 'billings', listName: is_automated ? 'automated_time_entries' : 'manual_time_entries' };
    } else if (isFromCaseSpecificModule && userContext) {
      return { module: 'case', subModule: 'billings', listName: is_automated ? 'automated_time_entries' : 'manual_time_entries' };
    } else if (onTimeManageMent && userContext) {
      return { module: 'settings', subModule: 'time_manager', listName: is_automated ? 'automated_time_manager' : 'manual_time_manager' };
    } else if (props.isFromBilling && userContext) {
      return { module: 'billings', subModule: '', listName: is_automated ? 'automated_time_entries' : 'manual_time_entries' };
    }
  }

  const pickExtractedDate = (dateArray) => {
    return {
      time_entry_from: dateArray?.[0]
        ? convertToTimezoneFormat(moment(dateArray[0]).startOf('day'), userContext?.userDetails.timezone)
        : '',
      time_entry_to: dateArray?.[0]
        ? convertToTimezoneFormat(moment(dateArray[1] ? dateArray[1] : dateArray[0]).endOf('day'), userContext?.userDetails.timezone)
        : '',
    };
  };

  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown PrevPageLink NextPageLink',
    RowsPerPageDropdown: (options) => {
      let currentPage = getPageLimit();
      let path = getFilePathForPagination();
      if (options.value !== currentPage) {
        handlePageNumberInPagination(userContext, path?.module, path?.subModule, path?.listName, options?.value);
      }
      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Item Per Page
          </span>
          <Dropdown value={options.value} options={paginationDropdownOptions} onChange={onPageChange} />
        </React.Fragment>
      );
    },
    NextPageLink: ({ iconClassName }) => {
      return (
        <Button
          onClick={onNextPageClick}
          disabled={next_Token === null && isForward}
          className={'p-paginator-next p-paginator-element p-link'}
          icon={iconClassName}
        />
      );
    },
    PrevPageLink: ({ iconClassName }) => {
      return (
        <Button
          onClick={onPrevPageClick}
          disabled={transition_Token === null || (transition_Token && next_Token === null && !isForward)}
          className={'p-paginator-next p-paginator-element p-link'}
          icon={iconClassName}
        />
      );
    },
  };
  const onNextPageClick = () => {
    loadTimeEntries(
      {
        case_id: selectedCaseId?.id ? selectedCaseId?.id : selectedCaseId,
        firm_user_id: selectedUser,
        time_entry_status: selectedStatus || '',
        ...pickExtractedDate(selectedDate),
        time_entry_filter: 0,
        search_key: searchKey,
        billing_status: selectedBillingStatus || '',

        practice_area: selectedPractice,
      },
      pageLimit,
      !isForward ? transition_Token : next_Token,
      'forward'
    );

    setIsForward(true);
  };
  const onPrevPageClick = () => {
    loadTimeEntries(
      {
        case_id: selectedCaseId?.id ? selectedCaseId?.id : selectedCaseId,

        firm_user_id: selectedUser,
        time_entry_status: selectedStatus || '',
        ...pickExtractedDate(selectedDate),
        time_entry_filter: 0,
        search_key: searchKey,
        billing_status: selectedBillingStatus || '',

        practice_area: selectedPractice,
      },
      pageLimit,
      isForward ? transition_Token : next_Token,
      'reverse'
    );
    setIsForward(false);
  };
  const onPageChange = (event) => {
    setPageLimit(event.value);
    loadTimeEntries(
      {
        case_id: selectedCaseId?.id ? selectedCaseId?.id : selectedCaseId,
        firm_user_id: selectedUser,
        time_entry_status: selectedStatus || '',
        ...pickExtractedDate(selectedDate),
        time_entry_filter: 0,
        search_key: searchKey,
        practice_area: selectedPractice,
      },
      event.value,
      '',
      'forward'
    );
  };

  const changeUserData = () => {
    try {
      let dat =
        userList?.length &&
        userList?.map((val) => {
          val.fullName = generateFullName(val);
          if (val.access_level && val.access_level === 'firm-admin') {
            let a = { ...val };
            a.user_type = val.access_level;
            a.contact_id = val.user_id;
            return a;
          } else {
            return val;
          }
        });
      let newData =
        dat && dat.length ? dat.filter((v) => (v.access_level === 'firm-admin' || v.is_confirmed) && v.status === 'ACTIVE') : [];
      return newData;
    } catch (err) {
      return [];
    }
  };

  const onUpdateBillingStatus = (e, rowData) => {
    const data = { ...rowData, billable: e.target.checked };
    updateTimeEntry(data, isLeadSpecific ? isLeadSpecific : rowData?.is_lead, '', true, true)
      .then((response) => {
        dispatch(nofifyTimeManager(response.data));
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.UPDATE_TIME_ENTRY_SUCCESS);
      })
      .catch((err) => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.UPDATE_TIME_ENTRY_FAILED);
      });
  };

  useEffect(() => {
    getAllCaseList();
  }, []);

  useEffect(() => {
    if (!isFromCaseSpecificModule) {
      totalCountEvent('practice-area')
        .then((response) => {
          dispatch(getPracticeAreaAction(response.data));
        })
        .catch((err) => {});
    }
  }, []);
  useEffect(() => {
    if (practiceData?.length) {
      let practice_options = practiceData.map((val) => {
        return {
          label: val.practice_area_name,
          value: val.practice_area_name,
        };
      });
      setPracticeAreaOptions(practice_options);
    } else {
      setPracticeAreaOptions([]);
    }
  }, [practiceData]);

  useEffect(() => {
    if (showUserFilter) {
      // totalCountEvent('user')
      //   .then((response) => {
      //     dispatch(getUserList('ACTIVE', response.data, '', 'forward', '', '', false));
      //   })
      //   .catch((error) => {});
      getFirmUsersListData();
    }
  }, [showUserFilter]);

  useEffect(() => {
    let data = {
      case_id: selectedCaseId?.id ? selectedCaseId?.id : selectedCaseId,
      time_entry_status: selectedStatus || '',
      time_entry_from: '',
      time_entry_to: '',
      time_entry_filter: 0,
      search_key: searchKey,
      billing_status: selectedBillingStatus || '',

      practice_area: selectedPractice,
    };
    loadTimeEntries(data, pageLimit, '', 'forward');
  }, [timeEntryNotificationState]);

  // useEffect(() => {
  //   if (caseAndLeadsList?.case_list?.length) {
  //     let tempArray = [];
  //     caseAndLeadsList?.case_list?.forEach((item) => {
  //       let data = {
  //         label:
  //           item && item.case_description && item.case_description.case_name
  //             ? `${item.unique_number ? `${item.unique_number} ` : ''}${item.case_description.case_name}`
  //             : 'TBD',
  //         value: item?.case_id,
  //       };
  //       tempArray.push(data);
  //     });
  //     tempArray.unshift({ label: 'All Cases', value: '' });
  //     setCaseDataList([...tempArray]);
  //   }
  // }, [caseAndLeadsList]);

  function getFirmUsersListData() {
    totalCountEvent('user')
      .then((response) => {
        let obj = {
          status: 'ACTIVE',
          limit: response.data ?? 10,
          pagination_token: '',
          pagination_direction: 'forward',
          keyWord: '',
          next: '',
          is_include_contacts: true,
          is_detailed: false,
          is_confirmed_user: true,
          is_include_groups: true,
        };
        listUsers(obj)
          .then((response) => {
            // setFirmUserList(changeUserData(response.data?.firm_users));
            setFirmUserList(formatMultiSelectContactList(response.data?.firm_users));
          })
          .catch((err) => console.error(err));
      })
      .catch((error) => console.warn(error));
  }

  const durationTemplate = (rowData) => {
    let timeData = rowData?.total_times?.split(':') ?? 'No Data';
    if (timeData !== 'No Data') {
      let data = timeData[0] + 'h ' + timeData[1] + 'm ' + timeData[2] + 's ';
      timeData = data;
    }
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Duration</span>
        <span className="text-break" title={rowData && rowData.total_hours ? rowData.total_hours : 0}>
          {rowData && rowData.total_hours ? rowData.total_hours : 0}
        </span>
      </React.Fragment>
    );
  };

  const billableCheckBox = (rowData) => {
    return (
      <>
        <span className="p-column-title text-break">Billable</span>
        <label className={'switch ' + (isCaseClosed || (isLeadClosed && isLeadSpecific) ? 'pe-none' : '')}>
          <input type="checkbox" checked={rowData.billable} name="billable" onChange={(e) => onUpdateBillingStatus(e, rowData)} />{' '}
          <span className="slider round"></span>
        </label>
      </>
    );
  };

  const dateTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title text-break">Date</span>
        <span title={handleDateTimeOffset(userContext?.userDetails?.timezone, rowData.time_entry_date, constants.month_date_year_format)}>
          {handleDateTimeOffset(userContext?.userDetails?.timezone, rowData.time_entry_date, constants.month_date_year_format)}
        </span>
      </>
    );
  };

  const costTemplate = (rowData) => {
    const data = rowData?.hour_rate;
    return (
      <>
        <span className="p-column-title text-break">Cost</span>
        <span title={dollarFormat(data)}>{dollarFormat(data)}</span>
      </>
    );
  };
  const getTotalAmount = (data) => {
    try {
      return dollarFormat(data);
    } catch {
      return `$0.00`;
    }
  };
  const amountTemplate = (rowData) => {
    const data = rowData?.amount;
    return (
      <>
        <span className="p-column-title text-break">Amount</span>
        <span className="text-break" title={getTotalAmount(data)}>
          {getTotalAmount(data)}
        </span>
      </>
    );
  };

  const descriptionTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title text-break">Description</span>
        <span className="text-break" title={rowData?.notes ? rowData?.notes : undefined}>
          {' '}
          {rowData?.notes ? rowData?.notes : '-'}
        </span>
      </>
    );
  };

  const iconActionsTemplate = (rowData) => {
    const handleDeleteTimeEntry = (data) => {
      let formattedSk = data.sk ? data.sk.replaceAll('#', '%23') : '';
      setSkToDelete(formattedSk);
      setIsLead(rowData?.is_lead);
      setShowDeleteConfirmation(true);
    };

    const handleEditTimeEntry = (data) => {
      let entryDate = convertToTimezoneWithStartOfDayFormat(data.time_entry_date, userContext?.userDetails?.timezone);
      let dataObject = {
        ...data,
        time_entry_date: entryDate,
      };
      setEditDataObject(dataObject);
      setShowEditDialog(true);
    };

    return (
      <>
        <span className="p-column-title text-break">Action</span>
        <i
          className={'icon-edit cursor-pointer ' + (isCaseClosed || (isLeadClosed && isLeadSpecific) ? 'pe-none opacity-50' : '')}
          onClick={() => handleEditTimeEntry(rowData)}
          id="edit"
        ></i>
        <i
          className={
            'icon-delete ms-2 cursor-pointer ms-4 ' + (isCaseClosed || (isLeadClosed && isLeadSpecific) ? 'pe-none opacity-50' : '')
          }
          onClick={() => handleDeleteTimeEntry(rowData)}
          id="delete"
        ></i>
      </>
    );
  };

  const teamMemberTemplate = (rowData) => {
    const firmUserId = rowData?.created_by_details?.created_by_firm_user_id;

    let name = '';
    if (rowData?.created_by_details?.first_name) name += rowData?.created_by_details?.first_name;
    if (rowData?.created_by_details?.middle_name) name = name + ' ' + rowData?.created_by_details?.middle_name;
    if (rowData?.created_by_details?.last_name) name = name + ' ' + rowData?.created_by_details?.last_name;
    return (
      <React.Fragment>
        <div
          onClick={(e) => {
            const route = firmUserId ? `/settings/team-management/${firmUserId}` : '/settings/team-management';
            handleTimeEntryRedirection(e, rowData, route);
          }}
        >
          <span className="p-column-title text-break">Team Member</span>
          <div className="d-flex align-items-center">
            <ImageComponent
              filePath={`${tenantId}/${firmUserId}/profile_image`}
              fileName={rowData?.created_by_details?.first_name}
              fileSize="small.jpg"
              style={{ borderRadius: '50% !important' }}
            />
            {rowData?.created_by_details?.created_by_firm_user_id && <i className="fas fa-user-tie me-2"></i>}
            <span className="redirection-link">{name}</span>
          </div>
          {/* <Link
          to={`/settings/team-management/${rowData?.created_by_details?.user_id}`}
          className="text-break pointer F-size14 text-bold call-back text-decoration-none"
        >
          {name}
        </Link> */}
        </div>
      </React.Fragment>
    );
  };

  const statusBody = (rowData) => {
    let status = '';
    if (rowData?.time_entry_status === 'OPEN') {
      status = 'Uninvoiced';
    } else {
      status = 'Invoiced';
    }
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Status</span>
        <span className="text-break" title={status}>
          {status}
        </span>
      </React.Fragment>
    );
  };

  const onChangeCalendar = (e) => {
    setSelectedDate(e.value);
    let data = {
      case_id: selectedCaseId?.id,
      time_entry_status: selectedStatus || '',
      firm_user_id: selectedUser,
      ...pickExtractedDate(e.value),
      time_entry_filter: 0,
      search_key: searchKey,
      billing_status: selectedBillingStatus || '',

      practice_area: selectedPractice,
    };
    loadTimeEntries(data, pageLimit, '', 'forward');
  };

  const loadTimeEntries = (filters, pageLimit = 10, pagination_token = '', pagination_direction = 'forward') => {
    if (props.caseView) {
      filters.case_id = props?.caseDetails?.case_id;
    }
    setIsLoading(true);
    showSpinner();
    listTimeEntries(filters, is_automated, pageLimit, pagination_token, pagination_direction)
      .then((response) => {
        setTimeData(response.data);
        setIsLoading(false);
        hideSpinner();
      })
      .catch((err) => {
        hideSpinner();
      });
  };
  const onChangeCase = (caseId) => {
    setSelectedCaseId(caseId?.value);
    // setSelectedCaseId(caseId);

    let data = {
      case_id: caseId?.value?.id ? caseId?.value?.id : '',
      firm_user_id: selectedUser,
      time_entry_status: selectedStatus || '',
      ...pickExtractedDate(selectedDate),
      time_entry_filter: 0,
      search_key: searchKey,
      billing_status: selectedBillingStatus || '',
      practice_area: selectedPractice,
    };
    loadTimeEntries(data, pageLimit, '', 'forward');
  };
  const onChangeBillingStatus = (e) => {
    setSelectedBillingStatus(e?.value);

    let data = {
      case_id: selectedCaseId?.id,
      firm_user_id: selectedUser,
      time_entry_status: selectedStatus || '',
      ...pickExtractedDate(selectedDate),
      time_entry_filter: 0,
      search_key: searchKey,
      billing_status: e?.value ? e?.value : '',
      practice_area: selectedPractice,
    };
    loadTimeEntries(data, pageLimit, '', 'forward');
  };

  const searchCases = (e) => {
    // let query = e?.query.toLowerCase();
    let query = e?.query;

    setSearchValue(query);
  };

  const handleClear = () => {
    setSelectedCaseId('');
    setSearchValue('');
    onChangeCase('');
  };

  const clickHandler = (e) => {
    ref.current?.search(e, '', 'dropdown');
    setLoad(true);
  };

  const onChangeStatus = (e) => {
    setSelectedStatus(e.value);
    let data = {
      case_id: selectedCaseId?.id,
      firm_user_id: selectedUser,
      time_entry_status: e?.value ? e?.value : '',
      ...pickExtractedDate(selectedDate),
      time_entry_filter: 0,
      search_key: searchKey,
      billing_status: selectedBillingStatus || '',

      practice_area: selectedPractice,
    };
    loadTimeEntries(data, pageLimit, '', 'forward');
  };

  const onChangeUser = (e) => {
    setSelectedUser(e.value);
    let data = {
      case_id: selectedCaseId?.id,
      // firm_user_id: e?.value ? e?.value : '',
      firm_user_id: Array.isArray(e?.value) ? e.value : e?.value ? [e.value] : '',
      time_entry_status: selectedStatus || '',
      ...pickExtractedDate(selectedDate),
      time_entry_filter: 0,
      search_key: searchKey,
      billing_status: selectedBillingStatus || '',

      practice_area: selectedPractice,
    };
    loadTimeEntries(data, pageLimit, '', 'forward');
  };

  const onChangeSearch = (e) => {
    let value = e.target.value;
    setSearchKey(value);
    if (!value) {
      loadTimeEntries(
        {
          case_id: selectedCaseId?.id ? selectedCaseId?.id : selectedCaseId,
          firm_user_id: selectedUser,
          time_entry_status: selectedStatus || '',
          ...pickExtractedDate(selectedDate),
          time_entry_filter: 0,
          search_key: '',
          billing_status: selectedBillingStatus || '',

          practice_area: selectedPractice,
        },
        pageLimit,
        '',
        'forward'
      );
    }
  };

  const onSearchKeyDown = (e) => {
    let code = e.keyCode ? e.keyCode : e.which;
    if (code === 13) {
      loadTimeEntries(
        {
          case_id: selectedCaseId?.id ? selectedCaseId?.id : selectedCaseId,
          firm_user_id: selectedUser,
          time_entry_status: selectedStatus || '',
          ...pickExtractedDate(selectedDate),
          time_entry_filter: 0,
          search_key: searchKey,
          billing_status: selectedBillingStatus || '',

          practice_area: selectedPractice,
        },
        pageLimit,
        '',
        'forward'
      );
    }
  };

  const onChangePractice = (e) => {
    setSelectedPractice([...e.value]);
    loadTimeEntries(
      {
        case_id: selectedCaseId?.id ? selectedCaseId?.id : selectedCaseId,
        firm_user_id: selectedUser,
        time_entry_status: selectedStatus || '',
        ...pickExtractedDate(selectedDate),
        time_entry_filter: 0,
        search_key: searchKey,
        billing_status: selectedBillingStatus || '',

        practice_area: [...e.value],
      },
      pageLimit,
      '',
      'forward'
    );
  };

  const refreshCase = () => {
    setSelectedStatus();
    setSearchKey('');
    setSelectedDate('');
    setSelectedUser('');
    setSelectedPractice();
    setSelectedCaseId('');
    setSelectedBillingStatus();
    setSearchValue('');
    loadTimeEntries(
      {
        case_id: '',
        // time_entry_date: "",
        time_entry_status: 'all',
        time_entry_from: '',
        time_entry_to: '',
        time_entry_filter: 0,
        search_key: '',
        firm_user_id: '',
        billing_status: '',
        practice_area: '',
      },
      pageLimit,
      '',
      'forward'
    );
  };

  const tableCell = (rowData, ...rest) => {
    const { header, field, clickable, clickableKey } = rest?.[0];
    return (
      <React.Fragment>
        <span className="p-column-title text-break">{header}</span>
        {field === 'activity_type' && rowData?.activity_type_code ? (
          <span title={rowData?.[field] + ' - ' + rowData?.activity_type_code}>
            {rowData?.[field] + ' - ' + rowData?.activity_type_code}
          </span>
        ) : (
          <>
            <span
              className={clickable ? 'redirection-link-no-text-transform' : ''}
              onClick={(e) => {
                e.preventDefault();
                const { case_id, is_lead } = rowData;
                const mainModuleName = is_lead ? '/leads' : '/cases';
                let route = case_id ? `${mainModuleName}/${case_id}` : mainModuleName;
                clickable && handleTimeEntryRedirection(e, rowData, route);
              }}
              title={rowData?.[field]?.replace(/-/g, ' ')}
            >
              {rowData?.[field]?.replace(/-/g, ' ')}
            </span>
          </>
        )}
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (showCreateTimer || showEditDialog) {
      document.body.style.overflow = 'hidden';
    }
    // Clean up the effect when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showCreateTimer, showEditDialog]); // to remove the dropdown scroll issue in dialog dropdown

  const fetchData = useCallback((query) => {
    getAllCaseList(false, '', false, query, 15, true);
  }, []);

  const delayedSearch = useCallback(
    debounce((query) => {
      fetchData(query);
    }, 500),
    [fetchData]
  );

  const assignToItemTemplate = (option) => {
    return (
      <>
        {option?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
        {option?.assignee_name}
      </>
    );
  };

  useEffect(() => {
    if (searchValue !== '') {
      delayedSearch(searchValue);
      setLoad(false);
    } else if (searchValue === '' && load) {
      getAllCaseList(false, '', false, searchValue, 15, true);
      setLoad(false);
    }
  }, [searchValue, delayedSearch, load]);

  return (
    <div className="container-fluid mx-0 p-0">
      {!props.is_automated && (
        <div className="d-flex  align-items-center mb-3">
          <Button
            label="Add Time Entry"
            icon="pi pi-plus"
            className="p-button p-button-primary ms-auto"
            onClick={() => {
              setShowCreateTimer(true);
              setEditDataObject('');
            }}
            disabled={isCaseClosed || (isLeadClosed && isLeadSpecific)}
          />
        </div>
      )}
      {isLoading ? (
        <div>{spinner}</div>
      ) : (
        <div>
          <div className="d-flex flex-wrap align-items-center filter-wrap mb-3 p-lg-0 p-2 w-100 align-items-center justify-content-between time-entries-filter">
            <div className="p-2 search-wraper">
              <span className="p-input-icon-left input-shadow">
                <i className="icon-search F-size16"></i>
                <TextSnippetWrapper
                  type="text"
                  placeholder="Search"
                  onChange={(e) => {
                    onChangeSearch(e);
                  }}
                  value={searchKey}
                  onKeyDown={onSearchKeyDown}
                  className="p-inputtext p-component input-search"
                />
              </span>
            </div>
            <div className="d-flex flex-wrap align-items-center p-2">
              <label className="text-medium">Filters :</label>
              <div className="d-flex flex-column ms-2 r-pb-0 filter-wraper">
                <Calendar
                  className="input-shadow filter-calender"
                  selectionMode="range"
                  dateFormat="mm/dd/yy"
                  value={selectedDate}
                  onChange={(e) => {
                    onChangeCalendar(e);
                  }}
                  showIcon
                  placeholder="Date"
                />
              </div>
              <div className="d-flex flex-column ms-2 filter-wraper">
                <Dropdown
                  options={statusOptions}
                  onChange={onChangeStatus}
                  value={selectedStatus}
                  placeholder="Invoice Status"
                  className="searchable-dropdown"
                />
              </div>
              {!caseDetailsPage && (
                <div className="d-flex flex-column ms-2 filter-wraper">
                  {/* <Dropdown
                    value={selectedCaseId}
                    options={caseFullList}
                    onChange={(e) => onChangeCase(e?.value)}
                    optionValue="value"
                    placeholder="Case"
                    filter
                  /> */}
                  <AutoComplete
                    ref={ref}
                    value={selectedCaseId?.id ? selectedCaseId : searchValue}
                    suggestions={caseFullList}
                    completeMethod={searchCases}
                    onChange={(e) => {
                      if (e?.value !== null && (e?.originalEvent.type === 'click' || e?.originalEvent.type === 'keydown')) {
                        onChangeCase(e);
                      }
                    }}
                    onClick={(e) => clickHandler()}
                    placeholder={is_automated ? 'Case Name' : 'Case/Lead Name'}
                    field="label"
                    className="mx-1"
                    type="search"
                    dropdown
                    onDropdownClick={() => setLoad(true)}
                    onClear={handleClear}
                    filter
                  />
                  {/* <CaseListDropDown
                    value={selectedCaseId?.id ? selectedCaseId : searchValue}
                    onChange={(e) => {
                      if (e) {
                        onChangeCase(e);
                      }
                    }}
                    placeholder={is_automated ? 'Case Name' : 'Case/Lead Name'}
                    noOptionsMessage="No Cases/Leads Found"
                    loadOptions={searchCases}
                    defaultOptions={caseFullList}
                    onClick={() => clickHandler()}
                    onClear={handleClear}
                    className="mx-1"
                    isClearable
                  /> */}
                </div>
              )}

              {!isFromCaseSpecificModule && (
                <div className="d-flex flex-column ms-2 filter-wraper r-pb-0">
                  <MultiSelect
                    value={selectedPractice}
                    options={practiceAreaOptions}
                    onChange={onChangePractice}
                    placeholder="Practice Area"
                    maxSelectedLabels={1}
                    display="chip"
                    filter
                    className="input-shadow w-100 F-size14"
                    //style={{ height: "40px" }}
                  />
                </div>
              )}

              <div className="d-flex flex-column ms-2 filter-wraper">
                <Dropdown
                  options={billingOptions}
                  onChange={onChangeBillingStatus}
                  value={selectedBillingStatus}
                  placeholder="Billing Status"
                />
              </div>
              <div className="d-flex flex-column ms-2 filter-wraper r-pb-0">
                {!showUserFilter ? (
                  <Button
                    type="button"
                    label="Show User"
                    className="p-button me-2 button-text px-1 adv-filter text-bold text-primary-main"
                    onClick={() => setShowUserFilter(true)}
                  />
                ) : (
                  // <Dropdown
                  //   placeholder="Firm User"
                  //   options={userListData}
                  //   optionLabel="fullName"
                  //   optionValue="user_id"
                  //   onChange={onChangeUser}
                  //   value={selectedUser}
                  //   filter
                  //   className="searchable-dropdown"
                  // />
                  <Dropdown
                    placeholder="Firm User"
                    // options={userListData}
                    options={firmUserList}
                    // optionLabel="fullName"
                    // optionValue="user_id"
                    optionValue={'assignee_id'}
                    optionLabel="assignee_name"
                    optionGroupLabel="assignee_name"
                    optionGroupChildren="items"
                    onChange={onChangeUser}
                    value={selectedUser}
                    filter
                    className="searchable-dropdown"
                    itemTemplate={assignToItemTemplate}
                  />
                )}
              </div>

              <div className="d-flex flex-column mx-2">
                <div className="pointer ms-0">
                  <i className="fas fa-sync-alt" onClick={refreshCase}></i>
                  <Tooltip content="Clear Filter" position="top" target=".fa-sync-alt" showDelay={500} />
                </div>
              </div>
            </div>
          </div>

          <ConfirmDialog
            className="common-popup-style confirm-delete-popup"
            message="Are you sure you want to delete?"
            header="Confirm Delete"
            rejectClassName="p-button-secondary outline"
            icon="pi pi-exclamation-triangle"
            visible={showDeleteConfirmation}
            accept={() => {
              if (skToDelete) {
                deleteTimeEntry(skToDelete, props.isLeadSpecific ? props.isLeadSpecific : isLead).then(() => {
                  setShowDeleteConfirmation(false);
                  let data = {
                    case_id: props?.caseDetails?.case_id,
                    // time_entry_date: "",
                    time_entry_status: 'open',
                    search_key: searchKey,
                  };
                  setIsLoading(true);
                  showSpinner();
                  addToast(
                    false,
                    toastConstant.toasterType.SUCCESS,
                    toastConstant.api.SUCCESS,
                    toastConstant.message.DELETE_TIME_ENTRY_SUCCESS
                  );
                  listTimeEntries(data, is_automated, pageLimit).then((response) => {
                    setTimeData(response.data);
                    setIsLoading(false);
                    hideSpinner();
                  });
                  typeof getTimeEntrySummarty === 'function' && getTimeEntrySummarty();
                });
              }
            }}
            reject={() => {
              setShowDeleteConfirmation(false);
            }}
          />
          <div className="datatable-responsive overflow-auto">
            <DataTable
              responsiveLayout="scroll"
              className="p-datatable-responsive"
              emptyMessage="No Data Found."
              value={timeData.time_entries}
              paginator
              paginatorTemplate={paginatorTemplate}
              rows={pageLimit ?? 10}
              stripedRows
            >
              {is_automated && (
                <Column
                  header="Activity"
                  field="module_name"
                  sortable
                  body={tableCell}
                  className="text-capitalize"
                  bodyClassName="ellipsis-text"
                />
              )}
              <Column header="Billing Code" field="activity_type" sortable body={tableCell} bodyClassName="ellipsis-text" />
              <Column header="Team Member" body={teamMemberTemplate} sortable style={{ width: '200px' }} />
              <Column header="Description" body={descriptionTemplate} sortable bodyClassName="ellipsis-text" />
              <Column header="Status" body={statusBody} sortable bodyClassName="ellipsis-text" />
              {props?.isFromBilling && (
                <Column
                  header={is_automated ? 'Case' : 'Case/Lead'}
                  field="case_name"
                  body={tableCell}
                  clickable={true}
                  clickableKey={'case_id'}
                  sortable
                  bodyClassName="ellipsis-text"
                />
              )}
              <Column header="Duration" field="total_times" body={durationTemplate} sortable bodyClassName="ellipsis-text" />
              <Column header="Date" field="time_entry_date" body={dateTemplate} sortable bodyClassName="ellipsis-text" />
              <Column header="Rate" body={costTemplate} sortable bodyClassName="ellipsis-text" />
              <Column header="Amount" body={amountTemplate} sortable bodyClassName="ellipsis-text" />
              <Column header="Billable" field="billable" body={billableCheckBox} sortable />
              <Column body={iconActionsTemplate} />
            </DataTable>
            <Tooltip target={'#edit'} position={'top'} showDelay={500} className="case-tooltip mb-3">
              Edit
            </Tooltip>
            <Tooltip target={'#delete'} position={'top'} showDelay={500} className="case-tooltip mb-3">
              Delete
            </Tooltip>
          </div>
        </div>
      )}

      {showCreateTimer || showEditDialog ? (
        <Dialog
          className="add-contact-dialog create-msg-popup"
          visible={showCreateTimer || showEditDialog}
          onHide={() => {
            setShowCreateTimer(false);
            setShowEditDialog(false);
          }}
          style={{ width: '40vw' }}
          closable={false}
          header={ModalHeader}
        >
          <CreateTimer
            header={`${showEditDialog ? 'Edit ' : 'Add '}Time Entry`}
            isFromTimeManger={true}
            is_automated={is_automated}
            createTimerSuccess={() => {
              setShowEditDialog(false);
              setShowCreateTimer(false);
              let data = {
                case_id: '',
                // time_entry_date: "",
                time_entry_status: 'OPEN',
                search_key: searchKey,
              };
              setIsLoading(true);
              showSpinner();
              if (props.caseView) {
                data.case_id = props?.caseDetails?.case_id;
              }
              listTimeEntries(data, is_automated, pageLimit).then((response) => {
                setTimeData(response.data);
                setIsLoading(false);
                hideSpinner();
              });
              typeof getTimeEntrySummarty === 'function' && getTimeEntrySummarty();
            }}
            cancelCreateTimer={() => {
              setShowCreateTimer(false);
              setShowEditDialog(false);
            }}
            selectedTimer={editDataObject}
            caseView={props.caseView}
            caseDetails={props.caseDetails}
            isLeadSpecific={props.isLeadSpecific}
          />
        </Dialog>
      ) : null}
    </div>
  );
};

export default TimeEntries;
